import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React from 'react'
import { useApi } from '../../../context/apiContext';
import { BtnClose } from '../../../components/BtnClose';

export const ModalEstadoOrden = ({ showModal, setShowModal, statusOrder }) => {

    const { getTextByKey, getTextBigByKey } = useApi();


    const handleClose = () => {
        setShowModal(false);
    }


    return (
        <Modal show={showModal} onHide={handleClose} className='modal-estado-envio'>
            <Modal.Header>
                <button type="button" className="btn p-0 border-none ms-auto" onClick={() => setShowModal( false )}><BtnClose /></button>
            </Modal.Header>
            <Modal.Body>
                <div className='content mb-5 d-flex flex-column justify-content-center align-items-center'>
                    <h2 className='text-uppercase text-center mb-3 title-modal'>{getTextByKey("order-status-title-modal")}</h2>
                    <div className='wp my-3 text-uppercase text-center primary-color' dangerouslySetInnerHTML={{ __html: getTextBigByKey("desc-estado-orden-modal") }}>
                    </div>

                    <a className="btn-g bb py-2 px-3 text-uppercase mt-3" onClick={handleClose}>{getTextByKey("btn-close-modal")}</a>
                </div>
            </Modal.Body>
        </Modal>
    )
}
