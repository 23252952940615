import { async } from '@firebase/util';
import { Icon } from '@iconify/react';
import { getAllByText } from '@testing-library/react'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import { listAddressUser } from '../../../api/addressApi';
import { useAuth } from '../../../authContext';
import { useApi } from "../../../context/apiContext";
import { useCart } from '../../../context/cartContext';
import '../../../scss/custom_select.scss';

export default function SelectDireccionComponent({ step, update, addresSaveAlert }) {


  const { getTextByKey, currentLocale } = useApi();
  const [addressList, setAddressList] = useState([]);
  const { currentUser } = useAuth();
  const { updateDireccion, direccion } = useCart();
  const navigate = useNavigate();

  const selectAddress = (e) => {
    if(e.id) updateDireccion(e.id);
  }

  useEffect (() => {
    console.log(direccion !== null);
      async function getListAddress() {
          try {
              const listAddressResponse = await listAddressUser(currentUser.uid);
              setAddressList( listAddressResponse )
              if (!localStorage.getItem('cart_shido_direccion')) {
                navigate('/checkout-b');
              }
          } catch (error) {
              console.log('Error: ', error)
          }
      }
      getListAddress();
      console.log(update, "update");
      if(update>0) setTimeout(() => {updateDireccion(update);}, 500);
      if( update === 'guardada' ){
        goToCenter();
      }
  }, [direccion, update]);


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'black' : 'white',
    }),
    control: (provided) => ({
      ...provided,
    })
  };

  const goToCenter = () => {
    const filter = document.querySelector('.bolsa-checkout')
    filter.scrollIntoView({block: "start", behavior: "smooth"})
  }

  const boxSelectAddress = () => {
    return (
      <>
        <div className='select-direccion secundary-bg-color px-3 py-3 px-md-3 py-md-3 px-xl-5 py-xl-4 position-relative'>
          {step == 3 && <Icon icon="el:ok-sign" className='icon-ok' />} 
          <div className="text-center w-100 text-gray gotham mb-3" id='alerta-new-address'> <small>{addresSaveAlert}</small> </div>
          <div className='form-group mb-2 mb-md-3 mb-lg-4'>
            <Select styles={customStyles} className='react-select-container' classNamePrefix="react-select" options={addressList} getOptionLabel={(option) => option.address} getOptionValue={(value) => value.id} isDisabled={step==3} onChange={selectAddress} value={addressList.filter((x)=>x.id==direccion)} placeholder={currentLocale ==='es' ? 'Seleccionar' : 'Select'}/>
          </div>
          {step !== 3 ? (
          <div className="form-group">
            {direccion == null ? (
              // <div className='btn-g bb py-2 px-3 text-uppercase' role="button">{}</div>
              <div className='error-msg text-grey text-start bg-transparent mb-0'>*{getTextByKey('select-address')}</div>
            ) : (
              addressList.length > 0 && <NavLink to="/checkout-c#address-position" className="btn-g bb py-2 px-3 text-uppercase">{getTextByKey('enviar-direccion')}</NavLink>
            )}
          </div>
          ) : null }
      </div>
      </>
    )
  }

  return (
    currentUser?.isAnonymous ? (
      addressList.length > 0 && boxSelectAddress()
    ) : (
      boxSelectAddress()
    )
  )
}
