import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useApi } from '../context/apiContext';
import  '../scss/cookies.scss'

export default function DisclaimerCookiesComponent({ showModal, setShowModal }) {

    const { getTextByKey, getTextBigByKey } = useApi();



    const handleClose = () => {
        setShowModal(false);
        localStorage.setItem('cookies_shido', 'true')
        /* sessionStorage.setItem('path-to-favorite', location.pathname) */
    }

    const [contentCookies, setContentCookies] = useState(false);

    useEffect(() => {
        var iPhone = ( navigator.userAgent.match(/(iPhone|iPod)/i) ? true : false );
        if (iPhone) {
            setContentCookies(true);
        } else {
            setContentCookies(false);
        }
    }, [])
    

    return (
        <Fragment>
            {
                showModal  && 
                <div className={contentCookies ? "content-cookies d-flex flex-column justify-content-center align-items-center cookies-height-iphone" : "content-cookies d-flex flex-column justify-content-center align-items-center"}>
                    <h2 className='text-start fw-bold'>{getTextByKey('titulo-cookies')}</h2>
                    <div className='py-2 color-primary' dangerouslySetInnerHTML={{ __html: getTextBigByKey('disclaimer-cookies') }}></div>
                    <button className="btn-g py-2 px-3 text-uppercase me-2" onClick={handleClose}>{getTextByKey('i-agree')}</button>
                </div>
            }
        </Fragment>
    )
}
