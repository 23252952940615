import React, { Fragment } from 'react'
import { useApi } from "../../context/apiContext";
import '../../scss/checkout.scss'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { createOrder, getBanks, getTarjetas, pagarOrderPSE, pagarOrderWompi, get } from '../../api/productApi';
import { useAuth } from '../../authContext';
import { useCart } from '../../context/cartContext';
import Select from 'react-select';
import SelectDireccionComponent from './components/SelectDireccionComponent';
import { listAddressUser } from '../../api';
import TagManager from 'react-gtm-module';

const CheckoutFastPage = () => {

  const [tarjetas, setTarjetas] = useState([]);
  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [order, setOrder] = useState({
    uid: 5,
    method: 3,
    address: 66,
    currency: 1,
    store: 1,
    billing: 66,
    locale: 1,
    iva: 0,
    delivery_cost: 5000,
    taxes: 0,
    comments: '',
    subtotal: 50000,
    total: 55000,
    buyItems: [
      {
        productId: 'EV004S',
        price: 50000,
        quantity: 1,
        comments: '',
        discount: 0,
        toppings: '',
        promotionId: ''
      }
    ]
  });
  const { getTextByKey, address, cards, lastPayment, currentCurrency, getPropiertyByLocale } = useApi();
  const { currentUser } = useAuth();
  const { total, envio, coupon, direccion, currentCart, clearCart, creditBenefitType, hideCart } = useCart();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cuotas, setCuotas] = useState(1);
  const [addressList, setAddressList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (cards) setTarjetas(cards);
    if (address) setAddressList(address);
    if(currentCart){
      // GTM
      TagManager.dataLayer({
        ecommerce: null
      });
      TagManager.dataLayer({
        dataLayer: {
          event: "begin_checkout",
          type: "fast",
          ecommerce: {
            items: currentCart.map((x, i) => {
              return {
                item_name: getPropiertyByLocale(x.names, "nombre"),
                item_id: x.plu,
                price: x.price,
                color: x.color,
                talla: x.talla
              }
            })
          }
        }
      });
      // GTM
    }
  }, [lastPayment, address, cards]);

  const createCheckoutOrder = async () => {
    setError("");
    setLoading(true);
    if (!selectedAddress) setError("Please select a address")
    if (!selectedCard) setError("Please select a payment card")
    order.uid = currentUser.uid;
    order.delivery_cost = envio;
    order.subtotal = total - (coupon ? coupon.descuento : 0);
    order.total = total + envio - (coupon ? coupon.descuento : 0);
    order.address = parseInt(selectedAddress);
    order.billing = parseInt(selectedAddress);
    order.card = selectedCard;
    order.method = "tarjeta";
    order.buyItems = [];
    order.comments = "";
    order.currency = currentCurrency;
    order.creditBenefitType = creditBenefitType;
    console.log(currentCart);
    currentCart.forEach((item) => {
      if (item.type === "giftcard") {
        order.buyItems.push({
          productId: item.cartPlu,
          type: item.type,
          price: item.price,
          quantity: item.quantity,
          comments: '',
          discount: 0,
          toppings: '',
          promotionId: '',
        });
      } else {
        order.buyItems.push({
          productId: item.cartPlu ? item.cartPlu : item.plu,
          type: item.type,
          price: item.price.precio,
          quantity: item.quantity,
          comments: '',
          discount: 0,
          toppings: '',
          promotionId: '',
          products: item.products ? item.products : []
        });
      }

    });
    createOrder(order).then((response) => {
      
      console.log('response: ', response)
      if (!response.id) {
        setError("Error al crear la orden");
      } else {
        let jsonWompi = {
          "tarjeta": selectedCard,
          "user": currentUser.uid,
          "compra": response.id,
          "cuota": cuotas
        }
        pagarOrderWompi(jsonWompi).then((responseWompi) => {
          console.log('response: ', responseWompi)
          if (responseWompi.estado === "APPROVED" || responseWompi.estado === "PENDING" || responseWompi.estado === "VOIDED" || responseWompi.estado === "ERROR" || responseWompi.estado === "DECLINED") {
            clearCart();
            hideCart();
            setLoading(false);
            navigate("/payment-success", { replace: true, state: { order: response.id, msg: responseWompi.msg } });
            
          } else {
            setError("Error al pagar la orden :" + responseWompi.estado + responseWompi.msg);
          }
        });
      }
    }).catch((error) => {
      setLoading(false);
      console.log('error: ', error)
      setError(error.message);
    })
  }


  const { currentLocale } = useApi();

  const selectAddress = (e) => {
    if(e.id) setSelectedAddress(e.id);
  }
  const selectCard = (e) => {
    if(e.id) setSelectedCard(e.id);
  }
  const selectCuotas = (e) => {
    if(e.id) setCuotas(e.id);
  }

  const optionsCuotas = [
    { value: '1', label: '1'},
    { value: '2', label: '2'},
    { value: '3', label: '3'},
    { value: '4', label: '4'},
    { value: '5', label: '5'},
    { value: '6', label: '6'},
    { value: '7', label: '7'},
    { value: '8', label: '8'},
    { value: '9', label: '9'},
    { value: '10', label: '10'},
    { value: '11', label: '11'},
    { value: '12', label: '12'},
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
    { value: '32', label: '32' },
    { value: '33', label: '33' },
    { value: '34', label: '34' },
    { value: '35', label: '35' },
    { value: '36', label: '36' },
  ]

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'black' : 'white',
    }),
    control: (provided) => ({
      ...provided,
    })
  };

  return (
    <Fragment>
      <div className='d-flex mt-2 justify-content-between align-items-center'>
        <h3 className='text-uppercase'>Dirección:</h3>
        <div className='col-5'>
          {/* <select className={'py-1 px-2 rounded-none select-cart-fast'} value={selectedAddress} onChange={(e) => setSelectedAddress(e.target.value)}>
            <option value='0' disabled selected>Seleccionar</option>
            {addressList.map((address, index) => (
              <option key={index} value={address.id}>{address.address}</option>
            ))}
          </select> */}
          <Select styles={customStyles} className='react-select-container' classNamePrefix="react-select" options={addressList} getOptionLabel={(option) => option.address} getOptionValue={(value) => value.id} onChange={selectAddress} placeholder={currentLocale ==='es' ? 'Seleccionar' : 'Select'}/>
        </div>
      </div>
      <div className='d-flex mt-2 justify-content-between align-items-center'>
        <h3 className='text-uppercase'>Tarjeta:</h3>
        <div className='col-5'>
          {/* <select className={'py-1 px-2 rounded-none select-cart-fast'} value={selectedCard} onChange={(e) => setSelectedCard(e.target.value)}>
            <option value='0' disabled selected >Seleccionar</option>
            {tarjetas.map((card, index) => (
              <option key={index} value={card.id}>{card.brand} - {card.last_four_numbers}</option>
            ))}
          </select> */}
          <Select styles={customStyles} className='react-select-container' classNamePrefix="react-select" options={tarjetas} getOptionLabel={(option) => option.brand+' - '+option.last_four_numbers} getOptionValue={(value) => value.id} onChange={selectCard} placeholder={currentLocale ==='es' ? 'Seleccionar' : 'Select'}/>
        </div>
      </div>
      <div className='d-flex mt-2 justify-content-between align-items-center'>
        <h3 className='text-uppercase'>Cuotas:</h3>
        <div className='col-5'>
          {/* <select className={"py-1 px-2 rounded-none select-cart-fast"} onChange={(e) => setCuotas(e.target.value)}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((cuota, index) => (
              <option value={cuota} key={index}>{cuota}</option>
            ))}
          </select> */}
          <Select styles={customStyles} className='react-select-container' classNamePrefix="react-select" options={optionsCuotas} onChange={selectCuotas} placeholder={currentLocale ==='es' ? 'Seleccionar' : 'Select'}/>
        </div>
      </div>
      <div className='d-flex mt-2 justify-content-between align-items-center'>
        {!loading ? (
          <div className='btn-g bb p-2 w-100 text-center text-uppercase pointer' onClick={() => createCheckoutOrder()}>{getTextByKey('comprar-ahora-carrito')}</div>
        ) : (
          <div className='btn-g bb p-2 w-100 text-center text-uppercase pointer'><div className='spinner-border spinner-border-sm' role='status'></div></div>
        )}
      </div>
      {error.length > 0 && <p className='text-center'>{error}</p>}
    </Fragment>
  )
}

export default CheckoutFastPage