import React from 'react'
import { useApi } from '../context/apiContext'

export const BtnClose = () => {

    const { currentLocale } = useApi();

  return (
    <>
        <span className='button-close text-uppercase cursor-pointer'>{ currentLocale === 'es' ? 'Cerrar' : 'Close' }</span>
    </>
  )
}
