import React from "react";
import { useApi } from "../context/apiContext";

export function Idiomas(){
    const { updateLocale, currentCurrency, currentLocale, updateCurrency } = useApi();

    return(
        <div className="idiomas">
            <p className="mb-0">
                <span className={currentLocale == "es" ? "active" : ""} onClick={() => updateLocale("es")}>ES</span>
                /
                <span className={currentLocale == "en" ? "active" : ""} onClick={() => updateLocale("en")}>EN</span>
            </p>
            {/* <p className="mb-0">
                <a className={currentCurrency == "cop" ? "active" : ""} onClick={() => updateCurrency("cop")}>COP</a>
                /
                <a className={currentCurrency == "usd" ? "active" : ""} onClick={() => updateCurrency("usd")}>USD</a>
            </p> */}
        </div>
        
    );
}