import { Fragment, useState, useEffect } from "react";
import SlideBComponent from "./components/SliderBComponent";
import SliderComponent from "./components/SliderComponent";
import { useAuth } from "../../authContext";
import { getGallery } from "../../api/galleryApi";
import { images_products_route } from "../../api/productApi";

export default function FeedbacksliderPage() {

  const [slideProgress, setSlideProgress] = useState(0)
  const [gallery, setGallery] = useState([])

  useEffect(() => {
    //console.log(slideProgress);
  }, [slideProgress])

  const { currentUser } = useAuth();
  useEffect(() => {
    if (!currentUser || currentUser.isAnonymous) {
      //User guest redirect to login
      location.href = "/login";
    }

    getGallery('banner-feedback').then((data) => {
      setGallery( data );
    })
  }, []);

  return (
    <Fragment>
      <div className="banner user feedback position-relative">
        <div className="container pt-3">
          <div className="py-5">
            <SliderComponent slideChange={setSlideProgress} slideProgress={slideProgress} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
