import { Fragment } from "react";
import { Icon } from '@iconify/react';
import { NavComponent } from "./components/NavComponent";
import { NavsupComponent } from "./components/NavsupComponent";
import { FormularioDireccion } from "../../components/FormularioDireccion";
import { MapComponent } from "../../components/MapComponent";
import DireccionComponent from "./components/DireccionComponent";
import { useApi } from "../../context/apiContext";
import { useAuth } from "../../authContext";
import { useState } from "react";
import { listAddressUser } from "../../api/addressApi";
import { useEffect } from "react";

export default function DireccionesPage() {
    const { currentUser } = useAuth();
    const [addressList, setAddressList] = useState([]);
    const [addressFromMap, setAddressFromMap] = useState({});
    const { getTextByKey, getTextBigByKey, currentLocale } = useApi();
    const [locationMarker, setLocationMarker] = useState({});
    const [addingAddress, setAddingAddress] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [addresSaveAlert, setAddresSaveAlert] = useState('Tu dirección ha sido guardada.')

    const updateAddress = async (added) => {
        setAddingAddress(false);
        listAddressUser(currentUser.uid).then((addressResponse) => {
            setAddressList(addressResponse)
        })
        
        if(added == 'guardada') {
            setAlertMsg(true); 
            setTimeout(() => { 
                setAlertMsg(false); 
            }, 3000);
        }
    }

    const updateAddressFromMap = e => {
        setAddressFromMap(e);
    }

    useEffect(() => {
        updateAddress(false);
    }, [])

    const upToTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <Fragment>
            <div className="quaternary-bg-color head-perfil">
                <div className="container">
                    <div className="text-center">
                        <h1 className="text-uppercase active">{getTextByKey("text-account")}</h1>
                        <NavsupComponent />
                    </div>
                </div>
            </div>
            <div className="container-fluid px-4 py-5">
                <div className="d-lg-flex">
                    <div className="col-nav">
                        <NavComponent />
                    </div>

                    <div className="col-content-perfil">
                        {alertMsg &&
                            <div className="text-start w-100 text-gray gotham mb-3" id='alerta-new-address'> <small>{addresSaveAlert}</small> </div>
                        }
                        {addressList?.map((address, index) =>
                        (
                            <DireccionComponent
                                updateDirs={updateAddress}
                                currentUser={currentUser}
                                direccion={address}
                                key={index}
                            />
                        ))}
                        <a className="btn-g bb py-1 px-2 py-md-2 px-md-3 text-uppercase" id="btnActiveModal" onClick={() => setAddingAddress(true)}>{getTextByKey("btn-add-direccion")}</a>
                    </div>
                </div>
            </div>
            {addingAddress &&
                <div className="container animate__animated animate__fadeIn">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <h2>{getTextByKey("title-form-new-address")}</h2>
                            {
                                <FormularioDireccion
                                    user={currentUser}
                                    updateAddress={updateAddress}
                                    addresFromMap={addressFromMap}
                                    locationMarker={setLocationMarker}
                                    addressFromMap={addressFromMap}
                                />
                            }
                        </div>
                        {/* <div className="col-12 col-lg-7 offset-lg-1">
                            <div className="mapUbicacion">
                                <MapComponent locationMarker={locationMarker} updateAddressForm={updateAddressFromMap} />
                            </div>
                        </div> */}
                    </div>
                </div>
            }

        </Fragment>
    )
}