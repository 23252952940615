import sellyApi from './sellyApi'

export const createAddress = async (inputData) => {
  try {
    const { data } = await sellyApi.post('/address', inputData).catch((error) => {
      return false;
    });
    return data;
  } catch (error) {
    return false;
  }
}

export const listAddressUser = async (uidUser) => {
  try {
    const { data } = await sellyApi.get(`/address?uid=${uidUser}`);
    return data;
  } catch (error) {
    return [];
  }
}

export const deleteAddress = async (addressId) => {
  try {
    const { data } = await sellyApi.delete(`/address/${addressId}`).catch((error) => {
      return false;
    });
    return data;
  } catch (error) {
    return false;
  }
}

export const setDefaultAddress = async (uid, addressId) => {
  try {
    const { data } = await sellyApi.put(`/address/default?uid=${uid}&address=${addressId}`).catch((error) => {
      return false;
    });
    return data;
  } catch (error) {
    return false;
  }
}

export default { createAddress, deleteAddress, listAddressUser, setDefaultAddress }
