import React, { useState, useEffect, useContext, createContext } from "react";
import { json, useFetcher } from "react-router-dom";
import sellyApi from "../api/sellyApi";
import { useAuth } from "../authContext";
import axios from "axios"

const ApiContext = createContext();

export function useApi() {
  return useContext(ApiContext);
}

export function ApiProvider({ children }) {

  const EXPIRATION_TIME_CACHE = () => {
    //CALCULATE EXPIRATION TIME FROM MINUTES TO MILISECONDS
    var minutesCache = 30;
    return minutesCache * 60 * 1000;
  };

  //Set states

  const [currentTexts, setCurrentTexts] = useState(JSON.parse(localStorage.getItem("texts_shido_cache")) || []);
  const [currentTextsBig, setCurrentTextsBig] = useState([]);
  const [currentLocale, setCurrentLocale] = useState("es");
  const [currentCategories, setCurrentCategories] = useState([]);
  const [currentActivities, setCurrentActivities] = useState([]);
  const [currentCombination, setCurrentCombination] = useState([]);
  const [currentColors, setCurrentColors] = useState([]);
  const [currentSizes, setCurrentSizes] = useState([]);
  const [currentCollections, setCurrentCollections] = useState([]);
  const [currentLines, setCurrentLines] = useState([]);
  const [currentFav, setCurrentFav] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [currentCurrency, setCurrentCurrency] = useState("cop");
  const [cards, setCards] = useState([]);
  const [address, setAddress] = useState([]);
  const [lastPayment, setLastPayment] = useState(false);
  const [creditBenefitType, setCreditBenefitType] = useState({
    type: "general",
    code: ""
  });
  const [favCount, setFavCount] = useState(0);

  const { currentUser } = useAuth();


  //Api calls for general data

  const getTexts = async () => {
    try {
      const data = await sellyApi.get(`/text`);
      return data.data;
    } catch (error) {
      return error;
    }
  };

  const getTextsBig = async () => {
    try {
      const data = await sellyApi.get(`/textBig/`);
      return data.data;
    } catch (error) {
      return error;
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await sellyApi.get(`/store/categories`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getActivities = async () => {
    try {
      const { data } = await sellyApi.get(`/store/activity`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getCombinations = async () => {
    try {
      const { data } = await sellyApi.get(`/combinations`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getLines = async () => {
    try {
      const { data } = await sellyApi.get(`/store/line`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getCollections = async () => {
    try {
      const { data } = await sellyApi.get(`/collection/colecciones_nuevas`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getTallas = async () => {
    try {
      const { data } = await sellyApi.get(`/tallas`);
      if (data) {
        return data["hydra:member"].sort(((a, b) => a.orden - b.orden)).filter((talla) => talla.visible === true);;
      }
    } catch (error) {
      return error;
    }
  };

  const getColors = async () => {
    try {
      const { data } = await sellyApi.get(`/colors`);
      if (data) {
        return data["hydra:member"].filter((talla) => talla.visible === true);;
      }
    } catch (error) {
      return error;
    }
  };

  const getFavs = async (uid) => {
    try {
      const { data } = await sellyApi.get(`/product/favoritos?usuario=${uid}`).catch((error) => {
        return [];
      });
      return data;
    } catch (error) {
      return error;
    }
  };

  const getCurrencies = async () => {
    try {
      const { data } = await sellyApi.get(`/store/get-setting?llave=currency_convertion&formato=JSON`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getPropiertyByLocale = (array, propierty) => {
    if (!array) return "";
    if (array.length === 0) return "";
    let local_filter = array.find(e => e.locale == currentLocale);
    if (!local_filter) return "";
    return local_filter[propierty] ? local_filter[propierty] : "";
  }

  //Functions

  const getTextByKey = (key) => {
    let finded = currentTexts.find((text) => text.llave === key);
    if (finded) {
      let findedfinded = finded.names.find((text) => text.locale === currentLocale);
      if (findedfinded) {
        return findedfinded.valor;
      } else {
        localStorage.removeItem("texts_shido_cache_times");
        console.log("no se encontro el texto", key);
        detectIfTextsIsEmpty();
        return " ";
      }
    } else {
      localStorage.removeItem("texts_shido_cache_times");
      console.log("no se encontro el texto", key);
      detectIfTextsIsEmpty();
      return " ";
    }
  };

  let expirationTimeText = null;

  const detectIfTextsIsEmpty = () => {
    if (expirationTimeText) { clearTimeout(expirationTimeText); }
    expirationTimeText = setTimeout(() => {
      intentGetTexts();
    }, 2000);
  }

  const getTextBigByKey = (key) => {
    let finded = currentTextsBig.find((text) => text.llave === key);
    if (finded) {
      let findedfinded = finded.names.find((text) => text.locale === currentLocale);
      if (findedfinded) {
        return findedfinded.valor;
      } else {
        return " ";
      }
    } else {
      return " ";
    }
  };

  const extractNameFromLocate = (object) => {
    let finded = [];
    if (object.hasOwnProperty("names")) {
      finded = object.names.find((text) => text.locale === currentLocale);
    } else {
      finded = object.name.find((text) => text.locale === currentLocale);
    }
    if (finded) {
      return finded.nombre;
    } else {
      return " ";
    }
  };

  const updateLocale = (locale) => {
    setCurrentLocale(locale);
  };

  const updateCurrency = (currency) => {
    setCurrentCurrency(currency);
  };

  const updateFavs = () => {
    if (currentUser) {
      getFavs(currentUser.uid).then((data) => {
        setCurrentFav(data);
      });
    } else {
      setCurrentFav([]);
    }
  }

  const updateBenefitType = ({ type, code }) => {
    setCreditBenefitType({ type, code });
  };

  const convertCurrency = (amount) => {
    amount = (amount <= 0) ? 0 : amount;
    let priceLocale = Intl.NumberFormat('en-US');
    if (currentCurrency === "cop") {
      let priceOutput = priceLocale.format(amount)
      return `$ ${priceOutput}`;
    } else {
      return "$" + (amount * currencies[currentCurrency]).toLocaleString() + " USD";
    }
  };

  const convertCurrencyNoFormat = (amount) => {
    amount = (amount <= 0) ? 0 : amount;
    return amount * currencies["usd"];
  }

  const getTarjetas = async (uid) => {
    try {
      const { data } = await sellyApi.get(`/card?uid=${uid}`)
      return data;
    } catch (error) {
      return error;
    }
  }

  const getAddress = async (uid) => {
    try {
      const { data } = await sellyApi.get(`/address?uid=${uid}`);
      return data;
    } catch (error) {
      return [];
    }
  }

  const getLastPayment = async (uid) => {
    try {
      const { data } = await sellyApi.get(`/user/last-payment?usuario=${uid}`)
      return data;
    } catch (error) {
      return error;
    }
  }

  const getActualLocation = async (ip) => {
    try {
      const { data } = await sellyApi.get(`/ip?ip=${ip}`)
      return data;
    } catch (error) {
      return error;
    }
  }

  const [ip, setIP] = useState('');

  const getIp = async () => {
    try {
      const { data } = await axios.get(`https://geolocation-db.com/json/`)
      return data;
    } catch (error) {
      return error;
    }
  }

  const updateFavCount = () => {
    let count = favCount + 1;
    updateFavs();
    setFavCount(count);
  }

  const getAllApis = async () => {
      await getCategories().then((data) => {
        data.sort((a, b) => a.orden - b.orden);
        setCurrentCategories(data);
      });
      await getActivities().then((data) => {
        setCurrentActivities(data);
      });
      await getCombinations().then((data) => {
        setCurrentCombination(data);
      });
      await getLines().then((data) => {
        data = data.sort((a, b) => a.orden - b.orden);
        setCurrentLines(data);
      });
      await getCollections().then((data) => {
        setCurrentCollections(data);
      });
      await getTallas().then((data) => {
        setCurrentSizes(data);
      });
      await getColors().then((data) => {
        setCurrentColors(data);
      });
      await getCurrencies().then((data) => {
        setCurrencies(data);
      });
      await updateFavs();
      if (currentUser) {
        await getTarjetas(currentUser.uid).then((data) => {
          setCards(data);
        });
        await getAddress(currentUser.uid).then((data) => {
          setAddress(data);
        });
        await getLastPayment(currentUser.uid).then((data) => {
          setLastPayment(data);
        });
      }
  }

  const getSaldoTarjeta = async (codigoTarjeta) => {
    try {
      const { data } = await sellyApi.get(`/giftcard/balance?code=${codigoTarjeta}`)
      console.log(data);
      return data;
    } catch (error) {
      console.log('Ha ocurrido un error: \n', error.response.data);
      return error.response.data
    }
  }

  const analytics = (w, d, s, l, i) => {
    w.dataLayer = window.dataLayer || [];
    w.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    var dl = l != 'dataLayer' ? '&l=' + l : '';
    var scr = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;

    if (!scriptExists(scr)) {
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement("script")
      j.async = true;
      j.src = scr;
      f?.parentNode?.insertBefore(j, f);
    }
  }

  const scriptExists = (url) => {
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
      if (scripts[i].src == url) return true;
    }
    return false;
  }

  const getEssentials = async () => {
    let cacheTimes = localStorage.getItem("texts_shido_cache_times");
    if (cacheTimes) {
      let cacheTime = new Date(cacheTimes);
      let now = new Date();
      if (now.getTime() > cacheTime.getTime() + EXPIRATION_TIME_CACHE()) {
        intentGetTexts();
      } else {
        setCurrentTexts(JSON.parse(localStorage.getItem("texts_shido_cache")));
      }
    } else {
      intentGetTexts();
    }
    await getTextsBig().then((data) => {
      setCurrentTextsBig(data);
    });
    setTimeout(() => {
      getAllApis();
    }, 6000);
  }

  const intentGetTexts = async () => {
    getTexts().then((data) => {
      setCurrentTexts(data);
      localStorage.setItem("texts_shido_cache", JSON.stringify(data));
      localStorage.setItem("texts_shido_cache_times", new Date().getTime());
    }).catch((error) => {});
      
  }

  async function configInitial() {
    if (localStorage.getItem("actual_ip_shido")) {
      let data = JSON.parse(localStorage.getItem("actual_ip_shido"));
      data.currency = data.country === 'Colombia' ? 'cop' : data.currency;
      if (data.lang) setCurrentLocale(data.lang);
      if (data.currency) {
        if (data.currency && (data.currency).toLowerCase() == 'cop') { data.currency = 'cop'; } else { data.currency = "usd"; }
        setCurrentCurrency(data.currency);
      }
      getEssentials();
    } else {
      const responseIp = await getIp();
      if (responseIp.IPv4) {
        const currentLocation = await getActualLocation(responseIp.IPv4);
        console.log('Actual IP: ', currentLocation);
        currentLocation['country'] = responseIp.country_name;
        if (responseIp.country_name && responseIp.country_name.toLowerCase() === 'colombia') {
          document.querySelector('#list-idiomas').remove();
        }
        localStorage.setItem("actual_ip_shido", JSON.stringify(currentLocation))
        if (currentLocation.lang) setCurrentLocale(currentLocation.lang);
        if (currentLocation.currency) {
          if (currentLocation.currency && (currentLocation.currency).toLowerCase() == 'cop') { currentLocation.currency = 'cop'; } else { currentLocation.currency = "usd"; }
          setCurrentCurrency(currentLocation.currency);
        }
        getEssentials();
      } else {
        setCurrentLocale('es');
        setCurrentCurrency('cop');
        getEssentials();
      }
    }
  }


  useEffect(() => {
    configInitial();
  }, []);

  const value = {
    currentTexts,
    currentLocale,
    currentCategories,
    currentActivities,
    currentCombination,
    currentColors,
    currentSizes,
    currentCollections,
    currentLines,
    currentFav,
    getTextByKey,
    getTextBigByKey,
    updateLocale,
    extractNameFromLocate,
    updateFavs,
    getPropiertyByLocale,
    convertCurrency,
    convertCurrencyNoFormat,
    loading,
    currencies,
    updateCurrency,
    currentCurrency,
    cards,
    address,
    lastPayment,
    updateBenefitType,
    creditBenefitType,
    updateFavCount,
    favCount,
    getSaldoTarjeta,
    getAllApis
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}
