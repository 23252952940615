import React, { Fragment } from 'react'
import '../scss/drop-menu.scss';
import { NavLink } from "react-router-dom";
import { useApi } from "../context/apiContext";
import { useEffect } from 'react';


export default function DropMenuComponent() {

    const { getTextByKey, currentCategories, extractNameFromLocate, currentLines } = useApi();
    
    const closeDrop = event => {
        const drop = document.getElementById('drop-menu');
        drop.classList.remove('show');
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('over-hidden');
        //window.scroll(0, 0)
    };

    const handleResize = () => {
        if( window.innerWidth <= 420 ) {
            console.log('Widt: ', window.innerWidth)
            closeDrop();
        }
    }
    
    window.addEventListener('resize', handleResize);
    

    
  return (
    <Fragment>
        <div className="drop-menu py-4" id="drop-menu">
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-2 offset-1 ps-0'>
                        <h3 className='gotham-Bold upper text-uppercase'>{getTextByKey("categorias")}</h3>
                        <ul>
                            {currentCategories.map((category, index) => <li key={index}><NavLink to={"/shop/"+category.id} onClick={closeDrop} className="mb-1 text-uppercase">{extractNameFromLocate(category)}</NavLink></li> )}
                        </ul>
                    </div>
                    <div className='col-2 ps-0'>
                        <h3 className='gotham-Bold text-uppercase'>{getTextByKey("destacados-title")}</h3>
                        <ul>
                            <li><NavLink to="/collection" onClick={closeDrop} className="mb-1 text-uppercase">{getTextByKey("nueva-collecion")}</NavLink></li>
                            <li><NavLink to="/conscious" onClick={closeDrop} className="mb-1 text-uppercase">{getTextByKey("moda-consciente")}</NavLink></li>
                            <li><NavLink to="/tarjeta-a" onClick={closeDrop} className="mb-1 text-uppercase">{getTextByKey("card-gift")}</NavLink></li>
                            {
                                currentLines.map((line, index) => <li key={index}><NavLink to={"/shop/0/0/0/0/"+line.id+"/normal"} onClick={closeDrop} className="mb-1 text-uppercase">{extractNameFromLocate(line)}</NavLink></li> )
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <a onClick={closeDrop} className='btn-g py-1 px-3 text-uppercase'>{getTextByKey("ocultar")}</a>
        </div>
    </Fragment>
  )
}
