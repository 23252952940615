import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Fragment } from 'react'
import { getGallery } from '../../../api/galleryApi';
import { images_products_route } from '../../../api/productApi';
import '../../../scss/nft.scss';
import { useApi } from "../../../context/apiContext";


export default function ModalNeftComponent() {

  const { currentLocale } = useApi();

  const [imgNFT, setLogo] =  useState([]);

  const [img, setImg] =  useState('');

  useEffect(() => {
    getGallery("nft").then((data) => {
        console.log('img', data);
        if(data.length > 0){
          if( currentLocale == 'es' ){
            /* console.log('español'); */
            setImg (images_products_route+data[0].image);
          }else{
            /* console.log('ingles'); */
            setImg (images_products_route+data[1].image);
          }
        }
    });
  }, [currentLocale]);

  return (
    <Fragment>
        <div className="modal modalNft fade" id="roadmapModal" tabindex="-1" aria-labelledby="roadmapModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content position-relative">
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">x</button>
                    <img className="w-100" src={img} alt={"NFT"} />
                </div>
            </div>
        </div>
    </Fragment>
  )
}
