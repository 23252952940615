import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

export default function ProductPreOrderComponent() {
    var products =[
        {
            id: 1,
            nameProduct: 'GABÁN EVA',
            price: '4.300.000',
            img: 'img/image-30.jpg'
        },
        {
            id: 2,
            nameProduct: 'HOODIE DUALITY',
            price: '800.000',
            img: 'img/image-28.jpg'
        },
        {
            id: 3,
            nameProduct: 'OUTFIT NÉBULA',
            price: '1.130.000',
            img: 'img/image-27.jpg'
        },
        {
            id: 4,
            nameProduct: 'OUTFIT MARS',
            price: '1.230.000',
            img: 'img/image-30.jpg'
        },
    ]
    return (
        <Fragment>
            {
                products && products.map( product => {
                    return(
                        <div className='col-6 col-md-4 col-lg-3'>
                            <div className='item-preorder' key={ product.id }>
                                <img className='w-100 mb-4' src={ product.img }/>
                                <h3 className='fw-bold text-uppercase'>{ product.nameProduct }</h3>
                                <h3 className='text-uppercase'>${ product.price }</h3>
                                {/* <div className='text-center py-4'>
                                    <Link to="/" className='btn-g bb py-2 px-3 text-uppercase'>WAITLIST</Link>
                                </div> */}
                            </div>
                        </div>
                    )
                } )
            }
        </Fragment>
    )
}
