import React, { Fragment } from 'react'
import { useState } from 'react';
import { images_products_route } from '../../../api/productApi';
import { useApi } from '../../../context/apiContext';
import '../../../scss/pedido_component.scss';
import { ModalEstadoOrden } from './ModalEstadoOrden';

export default function PedidoComponent({ dataOrder, nameUser }) {
    const [showModal, setShowModal] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const { getTextByKey, convertCurrency } = useApi();

    const formatOrderDate = (fecha) => {
        const date = new Date(fecha);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    const handleShowModal = () => {
        setShowModal(true)
    }
    return (
        <Fragment>
            <div className='pedido p-2 p-md-4 p-xl-4 secundary-bg-color mb-4'>
                <div className='row'>
                    <div className='col-12 col-md-6 mb-4 mb-lg-0'>
                        <h3 className='mb-2 mb-xl-3 fw-bold text-uppercase'>{nameUser}</h3>
                        <p className="mb-0 text-uppercase">país: {dataOrder.pais}</p>
                        <p className="mb-0 text-uppercase">ciudad: {dataOrder.city}</p>
                        <p className="mb-0 text-uppercase">dirección: {dataOrder.address}</p>
                        <p className="mb-0 text-uppercase">{dataOrder.address1}</p>
                        <p className="mb-0 text-uppercase">código postal: {dataOrder.zipcode}</p>
                        <p className="mb-2 mb-xl-3 text-uppercase">número de teléfono: {dataOrder.phone}</p>
                        <h4 className='mb-0 fw-bold text-uppercase'>FECHA DE COMPRA: {formatOrderDate(dataOrder.orderDate.date)}</h4>
                        <h4 className='mb-2 mb-xl-3 fw-bold text-uppercase'>NÚMERO DE PEDIDO: {dataOrder.id}</h4>
                        <a className='btn-g bb py-2 px-4 text-uppercase' onClick={handleShowModal}>{getTextByKey("btn-my-order-status-contact-page")}</a>
                        <ModalEstadoOrden showModal={showModal} setShowModal={setShowModal} statusOrder={dataOrder.estado} />
                        {/* 
                        */}
                    </div>
                    {
                        (dataOrder && dataOrder.product_items) && (
                            <div className='col-12 col-md-6'>
                                <div className='producto'>
                                    {
                                        !showMore && (
                                            <div className='row my-2'>
                                                <div className='col-6'>
                                                    <img 
                                                        className='w-100' 
                                                        src={images_products_route + (
                                                            dataOrder.product_items[0].parent_images.length > 0 ? 
                                                            dataOrder.product_items[0].parent_images[0].image+'?format=webp' : (
                                                                dataOrder.product_items[0].images.length > 0 ? dataOrder.product_items[0].images[0].image+'?format=webp' : ''
                                                            ) 
                                                            
                                                        )} />
                                                </div>
                                                <div className='col-6'>
                                                    <h3 className='mb-2 mb-xl-3 fw-bold text-uppercase'>{dataOrder.product_items[0].parent_nombre}</h3>
                                                    <p className="mb-0 text-uppercase">{convertCurrency(dataOrder.total)}</p>
                                                    <p className="mb-0 text-uppercase">Color: {dataOrder.product_items[0].color}</p>
                                                    <p className="mb-0 text-uppercase">TALLA: {dataOrder.product_items[0].talla}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {showMore && dataOrder.product_items.map((item, index) => (
                                        <div className='row my-2' key={item + '-' + index}>
                                            <div className='col-6'>
                                                <img className='w-100' src={images_products_route + item.parent_images[0].image+'?format=webp'} />
                                            </div>
                                            <div className='col-6'>
                                                <h3 className='mb-2 mb-xl-3 fw-bold text-uppercase'>{item.parent_nombre}</h3>
                                                <p className="mb-0 text-uppercase">${item.precio}</p>
                                                <p className="mb-0 text-uppercase">Color: {item.color}</p>
                                                <p className="mb-0 text-uppercase">TALLA: {item.talla}</p>
                                            </div>
                                        </div>
                                    ))}
                                    {
                                        dataOrder.product_items.length > 1 &&
                                        <div className="w-100 d-flex justify-content-center my-4">
                                            <a className='btn-g bb py-2 px-4 text-uppercase' onClick={() => { setShowMore(!showMore) }}>
                                                {showMore ? getTextByKey("ver-menos-pedidos-page") : getTextByKey("ver-mas-pedidos-page")}
                                            </a>
                                        </div>
                                    }
                                </div>
                            </div>

                        )
                    }
                </div>
            </div>
        </Fragment>
    )
}
