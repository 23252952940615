import { Fragment } from "react";
import { ModaleliminarcuentaComponent } from "./components/ModaleliminarcuentaComponent";
import { NavComponent } from "./components/NavComponent";
import { NavsupComponent } from "./components/NavsupComponent";
import '../../scss/perfilPage.scss'
import PedidoComponent from "./components/PedidoComponent";
import { useEffect } from "react";
import { listOrdersUser } from "../../api/ordersApi";
import { useAuth } from "../../authContext";
import { useApi } from "../../context/apiContext";
import { useState } from "react";

export default function PedidosPage() {

    const { currentUser } = useAuth();
    const { currentLocale, getTextByKey } = useApi();
    const [listOrders, setlistOrders] = useState([])

    useEffect(() => {
        async function getListOrderUsers() {
            try {
                console.log(currentUser)
                const listOrderss = await listOrdersUser(currentUser.uid, currentLocale);
                console.log( 'List Orders: ', listOrders );
                if (listOrderss) {
                    setlistOrders(listOrderss)
                }
            } catch (error) {
                console.log('Error: ', error)
            }
        }

        getListOrderUsers();
    }, [])
    

  return (
    <Fragment>
        <div className="quaternary-bg-color head-perfil">
            <div className="container">
                <div className="text-center">
                    <h1 className="text-uppercase active">{getTextByKey("account-title")}</h1>
                    <NavsupComponent/>
                </div>
            </div>
        </div>
        <div className="container-fluid px-4 px-lg-0 py-5">
            <div className="d-lg-flex">
                <div className="col-nav mb-4 mb-lg-0">
                    <NavComponent/>
                </div>
                <div className="col-content-perfil">
                    <h2 className="text-uppercase">Pedidos</h2>
                    <div className="py-2 py-md-3 py-xl-4"> 
                        <div className='row'>
                            <div className='col-md-10 offset-md-1 col-lg-11 offset-lg-0 col-xl-9 offset-xl-0'>
                                { listOrders.map( order => (
                                    !!order && <PedidoComponent key={order.id} dataOrder={order} nameUser={currentUser.displayName} />
                                    // order && <pre>{ JSON.stringify( order )}</pre>
                                ) ) }
                                {listOrders.length === 0 && <p className='text-center'>No hay pedidos</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModaleliminarcuentaComponent/>
    </Fragment>
  )
}
