import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getGallery } from "../../api/galleryApi";
import { images_products_route } from "../../api/productApi";
import { useApi } from "../../context/apiContext";

export default function TarjetacPage() {

  const { getTextByKey, getSaldoTarjeta } = useApi();
  const [codigoTarjeta, setCodigoTarjeta] = useState('')
  const [error, setError] = useState('')

  const navigate = useNavigate();

  const handleGetSaldoTargeta = async (e) => {
    e.preventDefault();
    const response = await getSaldoTarjeta( codigoTarjeta );
    if( response.code && response.code === 404 ) {
      setError( response.message );
      setTimeout(() => {
        setError()
      }, 2000);
    } else {
      localStorage.setItem('infoGiftCard', JSON.stringify(response))
      navigate('/tarjeta-d')
    }
  }

  const [gallery, setGallery] = useState([]);

  useEffect(() => {
      getGallery('banner-tarjeta-regalo')
          .then((response) => {
              setGallery(response);
          })
  }, [])

  return (
    <Fragment>
        <div className="banner tarjeta-regalo position-relative">
            {gallery.length > 0 && <img className="w-100 d-none d-md-block" src={images_products_route+gallery[0].image+'?format=webp'} alt={"Banner"} />}
            {gallery.length > 0 && <img className="w-100 d-block d-md-none" src={images_products_route+gallery[1].image+'?format=webp'} alt={"Banner"} />}
          <div className="content-banner p-3 p-md-0" /* style={{ backgroundImage: `url("img/Group-138.jpg")`}} */>
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-lg-6">
                          <div className="pb-2 pb-lg-5">
                              <h2 className="text-uppercase fw-bold">{getTextByKey('title-saldo-gitfcard')}</h2>
                          </div>
                          <div className="pb-2 pb-lg-4">
                              <form className="pe-lg-5">
                                <div className="form-group mb-2 mb-lg-4">
                                  <label className="fw-bold">{getTextByKey('code-giftcard')}</label>
                                  <input type="text" className="p-2" value={codigoTarjeta} onChange={ (e) => { setCodigoTarjeta( e.target.value ) } }/>
                                </div>
                                {/* <div className="form-group mb-2 mb-lg-4">
                                  <label>código</label>
                                  <input type="text" className="p-2"/>
                                </div> */}
                                {error ? <div className='error-msg text-start bg-transparent'>*{error}</div> : null}
                                <div className="w-100 d-flex justify-content-start align-items-center gap-3 ps-md-4">
                                  <Link to="/tarjeta-a" className="btn-g bb py-2 px-4 text-uppercase border-0">Regresar</Link>
                                  <div className="form-group text-center">
                                    <button onClick={handleGetSaldoTargeta} className="btn-g bb py-2 px-4 text-uppercase">{getTextByKey('checkt-balance-giftcard-btn')}</button>
                                  </div>
                                </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div> 
    </Fragment>
  )
}
