import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyAVs0i151BqZSwhyPZlcAqYAq6VMJTL7u0",
    authDomain: "shido-35c42.firebaseapp.com",
    projectId: "shido-35c42",
    storageBucket: "shido-35c42.appspot.com",
    messagingSenderId: "56638101864",
    appId: "1:56638101864:web:4eeaf9c25a8c7752791edd"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };