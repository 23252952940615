import React, { Fragment, useEffect } from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import '../../../scss/categorias_slider.scss';

import { getProductBestSellers } from "../../../api/productApi";
import ProductsmallComponent from '../../../components/productsmallComponent';
import { Icon } from '@iconify/react';

export default function MasvendidosSliderComponent() {

    const [products, setProducts] = React.useState([]);
    const swiper = useSwiper    ();

    useEffect(() => {
        getProductBestSellers().then((response) => {
            setProducts(response);
        });
    }, []);

    return (
        <Fragment>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={40}
                slidesPerView={5}
                navigation={{
                    nextEl: '.review-swiper-button-next',
                    prevEl: '.review-swiper-button-prev',
                }}
                simulateTouch={false}
                // loop
                onSwiper={(swiper) => null}
                onSlideChange={() => null}
                breakpoints={{
                    320: {
                        spaceBetween: 1,
                        slidesPerView: 2,
                    },
                    768: {
                        spaceBetween: 40,
                        slidesPerView: 3,
                    },
                    1200: {
                        spaceBetween: 1,
                        slidesPerView: 5,
                    },
                }}
            >
                <button className='btn border-0 btn-outline-dark btn-swiper-navigation review-swiper-button-prev' >
                    <Icon icon="material-symbols:arrow-back-ios-rounded" />
                </button>
                {products.map((product, index) => (
                    <SwiperSlide key={index}>
                        <ProductsmallComponent product={product}/>
                    </SwiperSlide>
                ))}
                <button className='btn border-0 btn-outline-dark btn-swiper-navigation review-swiper-button-next'>
                    <Icon icon="material-symbols:arrow-forward-ios-rounded" />
                </button>
            </Swiper>
        </Fragment>
    )
}
