import React, { Component, Fragmentm, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import { Icon } from '@iconify/react';
import FavoriteClick from "./FavoriteClick";
import '../scss/product.scss';
import { Navigation, Pagination, Scrollbar, A11y, FreeMode, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PrecioSinDcto } from './PrecioSinDcto';
import { useApi } from '../context/apiContext';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { images_products_route } from '../api/productApi';
import { useEffect } from 'react';


export function ProductComponent(product){

  const location = useLocation();

  const { getTextByKey } = useApi();

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  let params = {
    modules: [Navigation, Pagination, Scrollbar, A11y, Thumbs, FreeMode],
    spaceBetween: 30,
    slidesPerView: 3,
    simulateTouch: false,
    lazy: true,
    loop: true,
    freeMode: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slidesPerView: 1,
  }

  let paramsThumbs = {
    modules: [Navigation, Pagination, Scrollbar, A11y, Thumbs, FreeMode],
    spaceBetween: 10,
    slidesPerView: 3,
    lazy: true,
    freeMode: true,
    loop: true,
    navigation: true,
  }

  const clickSwiper = (e) => {
    e.preventDefault();
    console.log(e);
  }

  function findPrincipal(images){
    return(
      images.find( e => e.principal)
    )
  }

    return (
      <div className={product.className}>
        <div className='product position-relative animate__animated animate__bounceIn'>
          <picture className='position-relative'>
            <Link to={"/product/" + product.product.plu} className="d-block d-md-none">
                <img
                  className='w-100'
                  src={findPrincipal(product.product.images) && images_products_route + findPrincipal(product.product.images).image }
                />
              </Link>
            <a className='d-none d-md-block'>
              <img
                className='w-100'
                src={findPrincipal(product.product.images) && images_products_route + findPrincipal(product.product.images).image }
              />
            </a>
            <FavoriteClick product={product.product} />
          </picture>
          <div className='pb-2 pb-md-4 pt-2 pt-md-2 px-2'>
            <div className='d-lg-flex justify-content-between align-items-center mb-1'>
              <h3 className='gotham mb-0'>{product.product.name.nombre}</h3>
              <h3 className='gotham border rounded buttom-nuevo mt-2 mt-md-0 d-none d-md-block'>{getTextByKey('new')}</h3>
            </div>
            {
              location.pathname.split('/')[1] == 'shop'  && (
                <div className="precio-item">
                  <PrecioSinDcto precio={product.product.price} />
                </div>
              )
            }
          </div>

          <div className='options p-2 d-none d-md-block'>
            {/* <picture className='position-relative'>
              <Link to={"/product/" + product.product.plu}>
                <img
                  className='w-100'
                  src={product.product.images.length > 0 && images_products_route + product.product.images[0].image}
                />
              </Link>
            </picture> */}
            <div className="principal-picture">
                <picture className='position-relative'>
                  <Link to={"/product/" + product.product.plu} className="">
                      <img
                        className='w-100 d-block d-md-none'
                        src={findPrincipal(product.product.images) && images_products_route + findPrincipal(product.product.images).image }
                      />
                      <img
                      className='w-100 d-none d-md-block'
                      src={findPrincipal(product.product.images) && images_products_route + findPrincipal(product.product.images).image }
                    />
                    </Link>
                </picture>
            </div>

            {/* <div className='slider py-4'>
              <Swiper {...paramsThumbs} watchSlidesProgress onSwiper={setThumbsSwiper}>
                {product.product.images.length > 1 &&
                  product.product.images.map((image, index) => {
                    return (
                      <SwiperSlide key={index} onClick={clickSwiper}>
                        <img className='w-100' src={images_products_route + image.image} alt={product.product.name.nombre} />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div> */}
            <div className='d-lg-flex justify-content-between align-items-center py-2'>
              <h3 className='gotham mb-0'>{product.product.name.nombre}</h3>
              <h3 className='gotham border rounded buttom-nuevo mt-2 mt-md-0 mb-0 d-none d-md-block'>{getTextByKey('new')}</h3>
            </div>
            {/* <PrecioSinDcto precio={product.product.price} /> */}
            {
              location.pathname.split('/')[1] == 'shop'  && (
                <PrecioSinDcto precio={product.product.price} />
              )
            }
          </div>
        </div>
      </div>
    );
}
