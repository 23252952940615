import { render } from '@testing-library/react';
import React from 'react';
import { useApi } from '../context/apiContext';
import '../scss/product.scss';

export function PrecioSinDcto(precio) {

  const { convertCurrency } = useApi();

  const dct = Math.round(((precio.precio.precio_sin_descuento - precio.precio.precio) / precio.precio.precio_sin_descuento) * 100);

  let cad_dct = "";
  let cad_dct_caja = "";

  if (dct > 0) {
    cad_dct = <span className='me-2 gray-1-color'>
      ${convertCurrency(precio.precio.precio_sin_descuento)}
    </span>
    cad_dct_caja = <p className="gotham-Bold d-inline-block alert-color alert-border-color py-1 px-2 mb-0">
      -{dct}%
    </p>
  }
  return (
    <div className='d-lg-flex justify-content-between align-items-center'>
      <p className='mb-0'>
        {cad_dct}
        <strong className='primary-color'>
          {convertCurrency(precio.precio.precio)}
        </strong>
      </p>
      {cad_dct_caja}
    </div>
  )

}