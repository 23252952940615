import React, { Fragment } from 'react'
import { Icon } from '@iconify/react';
import '../../../scss/filtros.scss';
import CheckDropdownComponent from './CheckDropdownComponent';
import { useApi } from "../../../context/apiContext";

export default function FiltrosComponent() {

    const { getTextByKey } = useApi();

    const openFilter = event => {
        const filter = document.getElementById('filter');
        filter.classList.toggle('view');
    };

    const closeFilter = event => {
        const filter = document.getElementById('filter');
        filter.classList.remove('view');  
    };

    return (
        <Fragment>
            <div>
                <div className='text-md-end opcion-mobile'>
                    <a className='btn-g py-1 px-3 py-md-2 px-md-4' onClick={openFilter}>
                        <span className='me-2'>{getTextByKey("filter")}</span>
                        <Icon icon="akar-icons:filter" />
                    </a>
                </div>
                <form className="product-filter d-xl-flex justify-content-between align-items-center" id="filter">
                    <div className='container-fluid mt-1'>
                        <div className='row'>
                            <CheckDropdownComponent/>
                        </div>
                    </div>

                    <div className='form-group text-center opcion-mobile mt-1 mt-md-0'>
                        <a className='btn-g py-1 px-3 py-md-2 px-md-4' onClick={closeFilter}>
                            <span>{getTextByKey("ok_button")}</span>
                        </a>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}
