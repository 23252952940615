import { sellyApi } from '../api'

export const getQuestions = async (lang) => {
    try {

        const { data } = await sellyApi.get(`/survey/questions?lang=${lang}`)

        return data;
    } catch (error) {
        return error;
    }
}

export const getAnswersByQuestion = async (questionId, lang) => {
    try {
        const { data } = await sellyApi.get(`/survey/answers?question=${questionId}&lang=${lang}`)
        return data;
    } catch (error) {
        return error;
    }
}

export const surveyUser = async (dataSend) => {
    try {
        const { data } = await sellyApi.post(`/survey/user`, dataSend)
        return data
    } catch (error) {
        return error;
    }
}

// export const sendSurvey = async (data) => {
//     try {
//         const response 
//     } catch (error) {
        
//     }
// }


export default {
    getQuestions,
    getAnswersByQuestion,
    surveyUser
}
