import GifLoader from "react-gif-loader";
import React, { Component } from "react";
import { useApi } from "../context/apiContext";
import { useEffect } from "react";

export default function ReactGifLoader() {

    const { loading } = useApi();

    useEffect (() => {
    }, [loading])

    if(!loading) 
        return null;

    return (
        <div className="gif-loader">
            <img src="../img/loader.gif" alt="" />
        </div>
    )
}