import { Fragment, useState, useEffect } from "react";
import { ProductComponent } from "../../components/productComponent";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { getRecommendedHome, getRecommendedHomeFav, getNewCollections, images_products_route } from "../../api/productApi";
import { getGallery } from "../../api/galleryApi";
import { useApi } from "../../context/apiContext";

import "../../scss/footer.scss";
import { useAuth } from './../../authContext';
import ModalLogUserComponent from "../../components/ModalLogUserComponent";

export default function HomePage() {
    const [products, setProducts] = useState([]);
    const [collecion, setCollecion] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [favicon, setFavicon] = useState('')
    const [faviconGris, setFaviconGris] = useState('')
    const [bannerReadyToWear, setBannerReadyToWear] = useState('')
    const navigate = useNavigate();

    const { getTextByKey, getTextBigByKey, extractNameFromLocate, currentFav, getPropiertyByLocale } = useApi();
    const { currentUser } = useAuth();


    useEffect(() => {
        getRecommendedHome().then((data) => {
            setProducts(data);
        });

        getGallery("main_banner_home").then((data) => {
            setGallery(data);

            data.forEach( img => {
                if( img.link === 'favicon_blanco') {
                    setFavicon(img.image)
                }
                if( img.link === 'favicon_gris') {
                    setFaviconGris(img.image)
                }
                if( img.link === 'banner-ready-to-wear') {
                    setBannerReadyToWear(img.image)
                }
            })
        });
        getNewCollections().then((data) => {
            setCollecion(data);
        });
    }, []);

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = (currentUser) => {
        if (currentUser) {
            sessionStorage.removeItem('to-checkout');
            sessionStorage.setItem('something-to-tell', 'feedback-a')
            navigate('/login');
        } else {
            console.log('no user');
        }
    }


    return (
        <Fragment>
            {/* se oculta por peticion del cliente */}
            {/* <div className='p-2 px-4 note-publi'>
                <Link to='/news-letter'>{getTextByKey("news-letter")}</Link>
            </div> */}
            <div className='banner position-relative mb-1'>
                <div className="position-absolute titulo-banner-collecion">
                    <h2 className="color-primary d-none d-md-block" dangerouslySetInnerHTML={{__html: getTextBigByKey('home-banner-nombre-coleccion')}}></h2>
                    <h2 className="color-primary d-block d-md-none" dangerouslySetInnerHTML={{__html: getTextBigByKey('home-banner-nombre-coleccion-mobile')}}></h2>
                </div>
                <picture className="d-none d-md-block">
                    {gallery.length > 0 && <img className='w-100' src={images_products_route + gallery[0].image+'?format=webp'} alt={"SHIDO"} />}
                </picture>
                <picture className="d-block d-md-none">
                    {gallery.length > 0 && <img className='w-100' src={images_products_route + gallery[4].image+'?format=webp'} alt={"SHIDO"} />}
                </picture>

                <Link className='btn-big bb py-2 py-md-2 px-3 px-md-3' to={"/collection"}>
                    {getTextByKey("comprar-ahora")}
                </Link>
                { gallery.length > 0 && <img src={images_products_route +favicon+'?format=webp'} alt="Favicon Shido" className="favicon-banner d-none d-md-block"/> }
                

                <div className={currentUser ? 'd-none d-md-block c-special' : 'd-none'}>
                    <Link to={"/feedback-a"} className="special-link">
                        {getTextByKey("something-to-tell")}
                    </Link>
                </div>
                <div className={currentUser ? 'd-none' : 'd-none d-md-block c-special'}>
                    <button onClick={handleShowModal} className='special-link'>
                        {getTextByKey("something-to-tell")}
                    </button>
                </div>
            </div>
            <ModalLogUserComponent showModal={showModal} setShowModal={setShowModal} />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-md-6 mb-1 mb-md-1 px-05'>
                        <Link to='/shop/0/0/0/0/6/normal' onClick={() => { window.scroll(0, 0) }}>
                            <div className='special-item special-item-hover img-blur'>
                                <div className='img' to="/shop">
                                    <picture className="d-none d-md-block">
                                        {gallery.length > 0 && <img className='w-100' src={images_products_route + gallery[1].image+'?format=webp'} alt={"SHIDO"} />}
                                    </picture>
                                    <picture className="d-block d-md-none">
                                    {gallery.length > 0 && <img className='w-100' src={images_products_route + gallery[5].image+'?format=webp'} alt={"SHIDO"} />}
                                    </picture>
                                </div>
                                <div className='txt'>
                                    <h2 className='mb-2 mb-md-1'>{gallery.length > 1 && getPropiertyByLocale(gallery[1].names, "titulo")}</h2>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-12 col-md-6 mb-1 mb-md-1 px-05'>
                        <Link to="/shop/0/0/0/0/8/normal" onClick={() => { window.scroll(0, 0) }}>
                            <div className='special-item special-item-hover img-blur'>
                                <div className='img' to="/shop">
                                    <picture className="d-none d-md-block">
                                        {gallery.length > 0 && <img className='w-100' src={images_products_route + gallery[2].image+'?format=webp'} alt={"SHIDO"} />}
                                    </picture>
                                    <picture className="d-block d-md-none">
                                        {gallery.length > 0 && <img className='w-100' src={images_products_route + gallery[6].image+'?format=webp'} alt={"SHIDO"} />}
                                    </picture>
                                </div>
                                <div className='txt'>
                                    <h2 className='mb-2 mb-md-4'>{gallery.length > 1 && getPropiertyByLocale(gallery[2].names, "titulo")}</h2>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-12 col-md-12 px-1'>
                        <Link to="/shop/0/0/0/0/9/normal" onClick={() => { window.scroll(0, 0) }}>
                            <div className='special-item'>
                                <div className='img' to={"/shop"}>
                                    <picture className="d-none d-md-block">
                                        {gallery.length > 0 && <img className='w-100' src={images_products_route + gallery[3].image+'?format=webp'} alt={"SHIDO"} />}
                                    </picture>
                                    <picture className="d-block d-md-none">
                                    {gallery.length > 0 && <img className='w-100' src={images_products_route + gallery[7].image+'?format=webp'} alt={"SHIDO"} />}
                                    </picture>
                                </div>
                                <div className='txt col-12 col-md-7'>
                                    <h2 className='mb-2 mb-md-4 text-center text-md-start text-gray hover-underline'>{gallery.length > 1 && getPropiertyByLocale(gallery[3].names, "titulo")}</h2>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='container py-5 py-md-5'>
                <div className="banner-ready-to-wear d-flex flex-column justify-content-center align-items-center">
                    <h2 className='text-center m-0 mb-3'>{getTextByKey("title-products-home")}</h2>
                    { gallery.length > 0 && <img src={images_products_route +bannerReadyToWear+'?format=webp'} alt="Favicon Shido" className="col-11 col-13 col-lg-35"/> }
                    <h2 className='text-center mt-3'>{getTextByKey("footer-ready-to-wear")}</h2>
                    { gallery.length > 0 && <img src={images_products_route +faviconGris+'?format=webp'} alt="Favicon Shido" className="favicon-gris-banner"/> }
                    <Link className='btn-big bb py-2 py-md-2 px-3 px-md-3' to={"/collection"}> {getTextByKey("show-now-ready-to-wear")} </Link>
                </div>
            </div>
            <div className='container-fluid home-product d-none d-md-block'>
                <div className='row'>
                    {products.map((product, index) => (
                        <ProductComponent key={index} product={product} className='col-6 col-md-3 p-0' />
                    ))}
                </div>
            </div>
            <div className='container-fluid py-md-0 mb-0'><header></header>
                <div className='row'>
                    {collecion.map((collection, index) => (

                        <div className={'col-12 col-md-6 '+ ( !collection.menu ? 'd-none d-md-block px-0' : 'px-0' )} key={index}>
                            <Link className='img' to={"/collection"} onClick={() => { window.scroll(0, 0) }}>
                            <div className='item-nueva-coleccion'>

                                {collection.images.length > 0 && (
                                    <img className='w-100' src={images_products_route + collection.images[0].image+'?format=webp'} alt={"SHIDO New Collections"} />
                                )}
                                <div className='btns-item-eva-coleccion'>
                                        <h3 className="m-0 hover-underline primary-color">{extractNameFromLocate(collection)}</h3>
                                    {/* <Link className='btn-g bb primary-bg-color white-color text-uppercase p-2 py-lg-2 px-lg-4' to={"/collection"} onClick={ (e) => { window.scroll(0, 0) } }>
                                        {getTextByKey("bt-nueva-coleccion")}
                                    </Link> */}
                                </div>
                            </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <div className="line-delgada d-none d-md-block"></div>
            {/* se oculta por peticion del cliente */}
            {/* <div className='py-2 py-md-3 py-xl-5 head-feed-intagram'>
                <div className='text-center'>
                    <h3 className='mb-2 mb-md-3 mb-xl-4'>{getTextByKey("instagram-text")}</h3>
                    <a href={"https://instagram.com/" + getTextByKey("instagram-user")} target='_blank' className='text-uppercase'>
                        @{getTextByKey("instagram-user")} {getTextByKey("on-instagram")}{" "}
                    </a>
                </div>
            </div>
            
            <div className='pt-2 pt-md-3 pt-xl-5 feed-instagram'>
                <script src='https://cdn.lightwidget.com/widgets/lightwidget.js'></script>
                <iframe
                    src='//lightwidget.com/widgets/460f9b0b73dc57b79ba7ae30a045bbdd.html'
                    scrolling='no'
                    allowtransparency='true'
                    className='lightwidget-widget'
                ></iframe>
            </div> */}
        </Fragment>
    );
}
