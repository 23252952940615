import React from 'react'
import { Icon } from '@iconify/react'
import '../scss/carrito.scss'
import ItemCarrito from './ItemCarrito'
import { Link } from 'react-router-dom'
import { useCart } from "../context/cartContext";
import { useEffect, useState } from 'react';
import { useApi } from "../context/apiContext";
import { useAuth } from '../authContext'
import CheckoutFastPage from '../pages/CheckoutPage/CheckoutFastPage'
import { BtnClose } from './BtnClose'

export default function CarritoComponent() {

    const { getTextByKey, convertCurrency, address, cards } = useApi();
    const { currentCart, cartIsShowing, hideCart, total, envio, calculateTotal } = useCart();
    const { currentUser } = useAuth();

    const [oneClick, setOneClick] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [cardss, setCardss] = useState([]);

    const closeCart = event => {
        hideCart();
        window.scroll(0, 0)
    };

    useEffect(() => {
        calculateTotal();
    }, [calculateTotal, address, cards]);

    return (
        cartIsShowing && (
            <div className='carrito-rapido p-2 p-md-3 show animate__animated animate__fadeInDown' id="carrito-rapido">
                <h3 className='text-uppercase py-2'>{getTextByKey('cart-title')}</h3>
                <a className='close' onClick={closeCart}><BtnClose /></a>
                <div className={!oneClick ? 'content-carrito' : 'content-carrito min'}>
                    {currentCart.map((item, index) => (
                        <ItemCarrito key={index} index={index} item={item} min={oneClick} />
                    ))}
                    {currentCart.length === 0 && <p className='text-center-c'>{getTextByKey('notice-cart-without-products')}</p>}
                </div>
                {
                    currentCart.length > 0 && (
                        <div className='container py-3 px-0'>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-between align-items-center'>
                                    <h3 className='text-uppercase'>{getTextByKey('shipping-cost')}</h3>
                                    <h3 className='text-uppercase'>{convertCurrency(envio)}</h3>
                                </div>
                                <div className='col-12 d-flex justify-content-between align-items-center'>
                                    <h3 className='text-uppercase fw-bold'>{getTextByKey('total-cart')}</h3>
                                    <h3 className='text-uppercase fw-bold'>{convertCurrency(total + envio)}</h3>
                                </div>
                            </div>
                        </div>
                    )
                }
                {!oneClick && (
                    <>
                        <div className='d-flex justify-content-between align-items-center'>
                            <Link to="/shop" className='btn-g bb p-2 me-3 text-uppercase' onClick={closeCart}>{getTextByKey('keep-buying')}</Link>
                            {
                                currentCart.length > 0 && (
                                    currentUser && !currentUser.isAnonymous ? <Link to="/checkout-b" onClick={closeCart} className='btn-g bb p-2 text-uppercase'>{getTextByKey('go-to-checkout')}</Link> : <Link to="/checkout-a" onClick={closeCart} className='btn-g bb p-2'>{getTextByKey('go-to-checkout')}</Link>
                                )
                            }
                        </div>
                        {
                            currentCart.length > 0 && (
                                <>
                                    {(currentUser && address.length > 0 && cards.length > 0) &&
                                        <div className='d-flex mt-2 justify-content-between align-items-center'>
                                            <div className='btn-g bb p-2 w-100 text-center text-uppercase pointer' onClick={() => setOneClick(true)}>{getTextByKey('compra-a-un-click')}</div>
                                        </div>
                                    }
                                </>
                            )
                        }
                    </>
                )}
                {oneClick && (
                    <>
                        <CheckoutFastPage></CheckoutFastPage>
                        <div className='d-flex mt-2 justify-content-between align-items-center'>
                            <div className='btn-g bb p-2 w-100 text-center text-uppercase pointer' onClick={() => setOneClick(false)}>Cancelar</div>
                        </div>
                    </>
                )}
            </div>
        )
    )
}
