import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { auth } from '../../../firebaseConfig';
import { useApi } from '../../../context/apiContext';
import { useCart } from '../../../context/cartContext';
export function ModaleliminarcuentaComponent({ profileInfo, deleteUser }) {

    const { getTextByKey, getTextBigByKey } = useApi();
    const {clearCart} = useCart();

    const [email, setEmail] = React.useState('');

    const onClickDelete = () => {
        deleteUser(profileInfo).then((response) => {
            handleLogout();
        });
    }

    //Logout Event
    const handleLogout = async () => {
        try {
            await auth.signOut();
            clearCart();
            location.href = "/";
        } catch {
        }
    };

    useEffect (() => {
        if(profileInfo){
            setEmail(profileInfo.email);
        }
    }, [profileInfo]);
        

    return (
        <Fragment>
            <div className="modal fade" id="eliminarModal" tabIndex="-1" aria-labelledby="eliminarModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h3 className="w-100 modal-title text-center mb-0" id="eliminarModal">ELIMINAR TU CUENTA</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-5 text-center">

                            {/* <p>You will no longer have access to Your account information, such as order history or favorites.</p>

                            <p className='mb-4'>If you choose to delete Your account, a confirmation email will be sent to:</p> */}

                            <div className="text-center" dangerouslySetInnerHTML={{ __html: getTextBigByKey("message-delete-account") }}></div>

                            <p>{email}</p>

                            <div>
                                <button type="button" className="btn-g py-2 px-3 text-uppercase me-3" data-bs-dismiss="modal">CANCELAR</button>
                                <button type="button" className="btn-g py-2 px-3 text-uppercase" onClick={onClickDelete}>eLIMINAR MI CUENTA</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
