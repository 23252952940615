import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { getGallery } from '../../api/galleryApi';
import { images_products_route } from '../../api/productApi';
import { useApi } from '../../context/apiContext'
import '../../scss/womenPage.scss'

export default function WomenPage() {

  const { getTextBigByKey, currentLocale } = useApi();
  const [gallery, setGallery] = useState([]);
  const [imagenMobile, setImagenMobile] = useState()

  useEffect(() => {
    getGallery("banner_shido_women").then((data) => {
      setGallery(data);

      data.forEach(element => {
        if( element.link == 'banner_shido_women_mobile' ) {
          setImagenMobile( element.image )
        } 
      });

    });
  }, [])
  

  return (
    <Fragment>
        <div className='banner banner-women position-relative'>
            { gallery.length > 0 && <img className='w-100 d-none d-md-block' src={images_products_route+gallery[0].image+'?format=webp'}/> }
            { imagenMobile && <img className='w-100 d-block d-md-none' src={images_products_route+imagenMobile+'?format=webp'}/>}
            <div className='container text-center'>
                {/* <h2>SHIDO WOMEN IN ALL LATAM ARE<br/> COMING TO STAND TOGETHER</h2> */}
                {/* <div className='text-center text-uppercase' dangerouslySetInnerHTML={{ __html: getTextBigByKey('titulo-women-shido') }}></div> */}
                <p className='w-100 text-uppercase'>{ currentLocale === 'es' ? 'Proximamente' : 'Coming Soon'}</p>
            </div>
        </div>
    </Fragment>
  )
}
