import axios from "axios";
import { getEnvVariables } from "../helpers";

const { GOOGLE_API_KEY } = getEnvVariables()

export const getInfoMap = async (infoAddress) => {
    try {
        const URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}&address=${infoAddress}`
        const response = await axios.get(URL);
        return response;
    } catch (error) {
        console.log("Error al consultar informacion de direccion", error);
    }
}

export default { getInfoMap };