import React from 'react'
import { useEffect } from 'react';
import { Fragment } from 'react'
import { useApi } from "../../../context/apiContext";

export default function TallaComponent({ producto, handleTalla }) {

    const { currentSizes } = useApi();
    const [sizesEnables, setSizesEnables] = React.useState([]);

    const handleChange = (e) => {
        let productselected = currentSizes.find((item) => item.id == e.target.value);
        let newPlu = "";

        productselected.productos.forEach((item) => {
                    if(item.includes(producto.plu)){
                        newPlu = item;
                    }
                });

        console.log("newPlu",newPlu);
        newPlu = newPlu.split("/");
        newPlu = newPlu[newPlu.length - 1];
        productselected.plu = newPlu;
        console.log("talla component",productselected);
        handleTalla(productselected);
    }

    useEffect (() => {
        if(producto){
            let sizesEnablesArray = [];
            if(currentSizes){
                currentSizes.forEach((size) => {
                    size.productos.forEach((item) => {
                        if(item.includes(producto.plu)){
                            sizesEnablesArray.push(size.id);
                        }
                    });
                });
            }
            setSizesEnables(sizesEnablesArray);
        }
    }, [currentSizes, producto.plu]);


    return (
        <Fragment>
            {producto && (
                <>
                {producto.hijos.map((size, index) => (
                    <div className={sizesEnables.includes(size.size) ? 'talla me-2' : 'talla me-2 disabled'} key={index}>
                        <input type="radio" className="css-checkbox checkbox-talla" name="talla" id={`talla-${producto.plu}-${index}`} value={size.size} onChange={handleChange} disabled={size.inventario == 0}/>
                        <label htmlFor={`talla-${producto.plu}-${index}`}  name="checkbox1_lbl" className={[size.inventario == 0 ? 'label-talla-disabled':'', 'css-label lite-green-check checkbox-talla' ].join(' ')} label-disabled={ size.inventario == 0  }>{size.sizeName}</label>
                    </div>
                ))}
                </>
            )}
        </Fragment>
    )
}
