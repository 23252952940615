import { Fragment } from "react";
import { NavpoliticasComponent } from "./components/NavpoliticasComponent";
import { useApi } from "../../context/apiContext";

export default function AvisoprivacidadPage() {
    const { getTextByKey, getTextBigByKey } = useApi();
  return (
    <Fragment>
        <div className="container pb-5">
            <div className="row">
                <div className="row">
                    <div className="col-12 col-lg-8 ms-auto">
                        <div className="py-5 px-2">
                            <h2 className="primary-color fw-bold text-start text-uppercase">{getTextByKey("privacy-notice")}</h2>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <NavpoliticasComponent/>
                </div>
                <div className="col-12 col-lg-8">
                    <p dangerouslySetInnerHTML={{ __html: getTextBigByKey("privacy-notice-text") }}></p>
                </div>
            </div>
        </div>
    </Fragment>
  )
}
