import axios from 'axios'
import { getEnvVariables } from '../helpers'

const { API_URL, TokenAPI } = getEnvVariables()

const sellyApi = axios.create({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': '*',
  'Access-Control-Allow-Headers': 'Content-Type',
  baseURL: API_URL,
  withCredentials: true,
  TokenAPI: TokenAPI
})

sellyApi.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
  }
  return config
})

export default sellyApi
