import React, { Fragment, useEffect } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom';
import { ProductComponent } from '../../components/productComponent'
import CategoriasSliderComponent from './components/CategoriasSliderComponent'
import FiltrosComponent from './components/FiltrosComponent'
import MasvendidosSliderComponent from './components/MasvendidosSliderComponent'
import { getProducts, images_products_route } from "../../api/productApi";
import { useApi } from "../../context/apiContext";
import { Icon } from '@iconify/react'
import { getGallery } from '../../api/galleryApi';
import TagManager from 'react-gtm-module';

export default function ShopPage() {

  const { getTextByKey, currentCategories, currentSizes, currentColors, currentLines, currentActivities } = useApi();

  const [gallery, setGallery] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [bannerImages, setBannerImages] = React.useState({
    imagenMobile:'',
    imagenDesktop:''
  });
  const [loading, setLoading] = React.useState(true);
  const [isFilter, setIsFilter] = React.useState(false);

  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    getGallery("banner-nueva-coleccion").then((data) => {
      console.log(data);
        setGallery(data);
    });
    window.scroll(0, 0);
}, []);

  const getNameFilter = (key, id) => {
    let name = "";
    switch (key) {
      case "categoria":
        name = currentCategories.find(x => x.id == id)
        if (name) name = name.names[0].nombre;
        break;
      case "actividad":
        name = currentActivities.find(x => x.id == id);
        if (name) name = name.name[0].nombre;
        break;
      case "colores":
        name = currentColors.find(x => x.id == id)
        if (name) name = name.nombre;
        break;
      case "talla":
        name = currentSizes.find(x => x.id == id);
        if (name) name = name.nombre;
        break;
      case "linea":
        name = currentLines.find(x => x.id == id);
        if (name) name = name.name[0].nombre;
        break;
      default:
        break;
    }
    return name;
  }

  const generateQueryLink = (key) => {
    let query = "";
    switch (key) {
      case "categoria":
        query = `./${0}/${params.actividad | 0}/${params.colores | 0}/${params.talla | 0}/${params.linea | 0}/normal`;
        break;
      case "actividad":
        query = `./${params.categoria | 0}/${0}/${params.colores | 0}/${params.talla | 0}/${params.linea | 0}/normal`;
        break;
      case "colores":
        query = `./${params.categoria | 0}/${params.actividad | 0}/${0}/${params.talla | 0}/${params.linea | 0}/normal`;
        break;
      case "talla":
        query = `./${params.categoria | 0}/${params.actividad | 0}/${params.colores | 0}/${0}/${params.linea | 0}/normal`;
        break;
      case "linea":
        query = `./${params.categoria | 0}/${params.actividad | 0}/${params.colores | 0}/${params.talla | 0}/${0}/normal`;
        break;
      case "all":
        query = `./${0}/${0}/${0}/${0}/${0}/normal`;
        break;
      default:
        break;
    }
    return query;
  }

  useEffect(() => {
    setLoading(true);
    if (Object.keys(params).length > 0) {
      if (params.categoria == 0 && params.actividad == 0 && params.colores == 0 && params.talla == 0 && params.linea == 0) {
        setIsFilter(false);
      } else {
        setIsFilter(true);
      }
    } else {
      setIsFilter(false);
    }
    getProducts(params.categoria, params.activity, params.colores, params.tallas, params.linea).then((data) => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      if (data) {
        //Show only new products
        if(location.pathname == "/collection") {
          data.filter(x => x.nuevo == true);
        } 
        //Show only products with discount
        if(location.pathname == "/offers") {
          data.filter(x => x.price.precio_sin_descuento != null);
        }
        //Order by filter
        if (params.order == "price_asc") {
          data.sort((a, b) => a.price.precio - b.price.precio);
        } else if (params.order == "price_desc") {
          data.sort((a, b) => b.price.precio - a.price.precio);
        }
        // GTM
        TagManager.dataLayer({
          dataLayer: {
            event: "view_item_list",
              ecommerce: null
            }
        });
        TagManager.dataLayer({
          dataLayer: {
            event: "view_item_list",
            ecommerce: {
              items: data.map((x, i) => {
                return {
                  item_name: x.name.nombre,
                  item_id: x.plu,
                  price: x.price.precio,
                }
              })
            }
          }
        });
        // GTM
        setProducts(data);
      } else {
        setProducts([]);
      }
    });
  }, [params.categoria, params.activity, params.colores, params.tallas, params.linea, params.linea, params.order]);

  return (
    <Fragment>
      {location.pathname == "/offers" && (
        <div className="banner position-relative mb-2 mb-md-5">
          <img className="w-100" src="/img/image-20.jpg" />
        </div>
      )}
      {location.pathname == "/collection" && (
        <div className="banner position-relative mb-2 mb-md-4" style={{minHeight: 'auto'}}>
          {gallery.length > 0 && (
            <div className="row d-none d-md-flex flex-wrap">
              <div className="col-6 p-0 pe-1"><img className="w-100" src={images_products_route + gallery[0].image+'?format=webp'} /></div>
              <div className="col-6 p-0 "><img className="w-100" src={images_products_route + gallery[2].image+'?format=webp'} /></div>
            </div>
          )}
          {gallery.length > 0 && <img className="w-100 d-block d-md-none" src={images_products_route + gallery[1].image+'?format=webp'} />}
        </div>
      )}
      <div className="container">
        {/* <div className='py-4 py-xl-5'>
          <CategoriasSliderComponent />
        </div> */}
        <div className='text-center py-2 py-md-3 py-lg-4 py-lg-5'>
          <h2 className='upper'>
            {location.pathname.includes("/shop") && getTextByKey("categorias")}
            {location.pathname.includes("/offers") && getTextByKey("ofertas")}
            {/* {location.pathname.includes("/collection") && getTextByKey("nueva-collecion")} */}
          </h2>
        </div>
        {isFilter > 0 &&
          <div className='py-4 filtros-activos'>
            <div className='container-fluid'>
              <div className="col-xl-12 px-0 d-flex flex-wrap justify-content-start align-items-center">
                <div className='form-group mb-3 mb-xl-0 me-xl-2'>
                  <Link to={`${generateQueryLink("all")}`} className="btn btn-outline-dark btn-sm">
                    Borrar Filtros
                  </Link>
                </div>
                <div className='form-group mb-3 d-flex flex-wrap mb-xl-0 me-xl-2'>
                  {Object.keys(params).map((keyName, index) => {
                    return params[keyName] > 0 ? (
                      <div className="chip" key={index}>
                        {getNameFilter(keyName, params[keyName])}
                        <Link to={`${generateQueryLink(keyName)}`} className="pointer" onClick={() => { window.scroll(0,0) }}>
                          <Icon icon="ep:close-bold" />
                        </Link>
                      </div>
                    ) : null
                  })}
                </div>
              </div>
            </div>
          </div>
        }
        <div className='py-4'>
          <FiltrosComponent />
        </div>
        
      </div>
      <hr />
      <div className='py-4'>
        <div className='container productos'>
          <div className='row'>
            {!loading ?
              <>
                {products.length > 0 ?
                  products.map((product, index) => <ProductComponent key={index} product={product} className="col-6 col-md-4 col-lg-3 p-0" />)
                  : (
                    <div className="col-12 text-center-c">
                      <br /><br />
                      {getTextByKey("no_products")}
                      <br /><br />
                      <hr />
                    </div>
                  )
                }
              </>
              : (
                <div className='container'>
                  <div className="text-center-c">
                    <div className="loader-box">
                      <img src="/img/SVG/loader.svg" alt="" />
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className='col-12 px-2 px-md-5'>
        <div className="row">
          <div className='col-12'>
            <div className='text-center py-2 py-md-3 py-lg-4 py-lg-5'>
              <h2 className='upper'>{getTextByKey("best-sellers")}</h2>
            </div>
          </div>
          <div className="col-11 mx-auto py-lg-5">
            <MasvendidosSliderComponent />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
