import React from 'react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from "../../authContext";
import { useEffect, useState } from 'react';
import { getPaises } from "../../api/productApi";
import { createUser } from '../../helpers/userHelper';
import { useApi } from '../../context/apiContext';
import { useForm } from '../../hooks/useForm';
import { Icon } from '@iconify/react';
import { getGallery } from '../../api/galleryApi';
import { images_products_route } from '../../api/productApi';
import TagManager from 'react-gtm-module';
import Select from 'react-select';

const registerFormFields = {
    email: "",
    email_confirm: "",
    password: "",
    nombre: "",
    apellido: "",
    celular: "",
    uid: "unset",
    pais: 48, //Colombia id
    phonecode: 48,
    fechaNacimiento: "",
    //documento: "",
    providerData: "unset",
    sexo: "M",
    autoriza: true,
    marketing: false,
    tratamiento: "",
    newsletter: false,
    politicas: false
}

export default function RegisterPage() {
    const [paises, setPaises] = useState([]);
    const [paisesPhoneCode, setPaisesPhoneCode] = useState([]);
    const { getTextByKey, currentLocale } = useApi();
    const {
        onInputChange: onRegisterInputChange,
        formState,
        emailRegex,
        passwordRegex,
        phoneRegex,
        setFormState
    } = useForm(registerFormFields);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'black' : 'white',
        }),
        control: (provided) => ({
            ...provided,
        })
    };


    const [loading, setloading] = useState(false)
    const [errors, setErrors] = useState()
    const [classEmailConfirm, setclassEmailConfirm] = useState('')
    const [listTratamiento, setListTratamiento] = useState([])
    const [typePassword, settypePassword] = useState('password')

    const { email, email_confirm, password, celular, nombre, tratamiento, apellido, newsletter, politicas, fechaNacimiento, pais, uid, providerData, phonecode } = formState;

    //Check the Auth Session
    const { currentUser } = useAuth();
    useEffect(() => {
        if (currentUser && !currentUser.isAnonymous) {
            location.href = "/";
        }
        getPaises(currentLocale).then((data) => {
            data.sort((a, b) => a.nombre.localeCompare(b.city));
            setPaises(data);
            setPaisesPhoneCode(data);
        });
    }, []);

    const [imageRegister, setImageRegister] = useState('')
    useEffect(() => {
        async function getGalleryInterna() {
            const response = await getGallery('register-background');
            setImageRegister(response[0].image);
        }

        getGalleryInterna();
    }, [])

    useEffect(() => {
        getGallery("title-form-register").then((data) => {
            const listadoAux = [];
            data.forEach(element => {
                const item = {
                    id: element.id,
                    name: ''
                }
                element.names.forEach(content => {
                    if (content.locale === currentLocale) {
                        item.name = content.titulo;
                        listadoAux.push(item);
                    }
                })
            });
            setListTratamiento(listadoAux);
        });
    }, [currentLocale])

    const onValidateEmailConfirm = (e) => {
        if (email !== email_confirm) {
            setclassEmailConfirm('border-danger');
        } else {
            setclassEmailConfirm('');
        }
    }

    const showPass = () => {
        if (typePassword === 'text') {
            settypePassword('password')
        } else {
            settypePassword('text')
        }
    }

    const onRegisterSubmit = async (event) => {
        event.preventDefault();
        setloading(true)

        const validate = await validateForm(formState)

        //If empty errors, should create a new user through firebase and database
        if (!validate) {
            setloading(true)
            setErrors()
            const newUser = await createUser(formState, setErrors, setloading).then(() => {
                // GTM
                TagManager.dataLayer({
                    dataLayer: {
                        event: "sign_up",
                        userId: formState.email,
                    }
                });
                // GTM
            });
        }
    }

    const validateForm = async (formData) => {
        let errorFound = false;


        // if( email === "" ||
        //     email_confirm === "" ||
        //     password === "" ||
        //     celular === "" ||
        //     nombre === "" ||
        //     apellido === "" ||
        //     newsletter === "" ||
        //     politicas === "" ||
        //     fechaNacimiento === "" ||
        //     pais === "" ) {
        //         errorFound = true;
        //         setErrors("Todos los campos son obligatorios");
        //     }


        //Validate the password format
        if (!password.match(passwordRegex)) {
            errorFound = true;
            setErrors("La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número");
            setTimeout(() => {
                setErrors("")
            }, 3000)
        }

        //Validate the email format
        if (
            !email.match(emailRegex) ||
            !email_confirm.match(emailRegex)
        ) {
            errorFound = true;
            setErrors("El email no tiene un formato válido");
            setTimeout(() => {
                setErrors("")
            }, 3000)
        }


        if (email_confirm !== email) {
            errorFound = true;
            setErrors("El email no coincide");
            setTimeout(() => {
                setErrors("")
            }, 3000)
        }

        //Validate the phone format
        if (!celular.match(phoneRegex)) {
            errorFound = true;
            setErrors("El teléfono no tiene un formato válido");
            setTimeout(() => {
                setErrors("")
            }, 3000)
        }


        //Validate the pais no empty
        if (pais === "0") {
            errorFound = true;
            setErrors("Debe seleccionar un país");
            setTimeout(() => {
                setErrors("")
            }, 3000)
        }

        if (!politicas) {
            errorFound = true;
            setErrors("Debe aceptar las politicas")
            setTimeout(() => {
                setErrors("")
            }, 3000)
            console.log(politicas)
        }


        //validate empty values
        for await (const [key, value] of Object.entries(formData)) {
            //console.log('key: ', key, ' value: ', value)
            let clearValue = typeof value === 'string' && value.trim();
            if (clearValue === "") {
                errorFound = true;
                setErrors("Todos los campos son obligatorios");
            }
        }

        setloading(false)
        return errorFound;
    }

    const handleChangeTratamiento = (event) => {
        setFormState({
            ...formState,
            tratamiento: event.name
          })
    }

    const handleChangeCountry = (event) => {          
        setFormState({
            ...formState,
            pais: event.id
        })
    }

    const handlePoliticas = (event) => {
        setFormState({
            ...formState,
            politicas: event.target.checked
        })
    }

    const handleNewsletter = (event) => {
        setFormState({
            ...formState,
            newsletter: event.target.checked
        })
    }

    return (
        <Fragment>
            <div className='content-user register-pager'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-7 col-lg-5 mx-auto p-0'>
                            <form className='user register my-0 my-md-5 p-3 p-md-5' onSubmit={onRegisterSubmit}>
                                <div className='form-group mb-3 mb-md-5'>
                                    <h2 className='text-uppercase text-center mb-0'>{getTextByKey('register')}</h2>
                                </div>
                                <div className="form-group mb-2">
                                    <label className='text-uppercase'>{getTextByKey("tratamiento-form")}*</label>
                                    <Select
                                        name='tratamiento'
                                        styles={customStyles}
                                        className='react-select-container py-2 mb-3'
                                        classNamePrefix="react-select"
                                        options={listTratamiento}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(value) => value}
                                        onChange={handleChangeTratamiento}
                                        placeholder={currentLocale === 'es' ? 'Seleccione una opcion' : 'Select option'}
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='text-uppercase'>{getTextByKey("names-form")}*</label>
                                    <input
                                        type="text"
                                        className='p-1 p-md-2'
                                        name="nombre"
                                        onChange={onRegisterInputChange}
                                        value={nombre}
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='text-uppercase'>{getTextByKey("last-names-form")}*</label>
                                    <input
                                        type="text"
                                        className='p-1 p-md-2'
                                        name="apellido"
                                        onChange={onRegisterInputChange}
                                        value={apellido}
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='text-uppercase'>{getTextByKey("email-form")}*</label>
                                    <input
                                        type="email"
                                        className='p-1 p-md-2'
                                        name="email"
                                        onChange={onRegisterInputChange}
                                        value={email}
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='text-uppercase'>{getTextByKey("email-form-confirm")}*</label>
                                    <input
                                        type="email"
                                        className={`${classEmailConfirm} p-2`}
                                        name="email_confirm"
                                        onChange={onRegisterInputChange}
                                        onKeyUp={onValidateEmailConfirm}
                                        value={email_confirm}
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='text-uppercase'>{getTextByKey("country-form")}*</label>
                                    <Select
                                        name='pais'
                                        styles={customStyles}
                                        className='react-select-container'
                                        classNamePrefix="react-select"
                                        options={paises}
                                        getOptionLabel={(option) => option.nombre}
                                        getOptionValue={(value) => value}
                                        onChange={handleChangeCountry}
                                        placeholder={getTextByKey("country-form")}
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='text-uppercase'>{getTextByKey("phone-form")}*</label>
                                    <div className="d-flex w-100">
                                        <select className='p-2 w-25' name="phonecode" onChange={onRegisterInputChange} value={pais} disabled>
                                            {paises.map((pais, index) => (
                                                <option key={index} value={pais.id}>{pais.phonecode} - {pais.iso3}</option>
                                            ))}
                                        </select>
                                        <input
                                            type="tel"
                                            className='p-1 p-md-2 w-75'
                                            name="celular"
                                            onChange={onRegisterInputChange}
                                            value={celular}
                                        />
                                    </div>
                                </div>
                                {/* <div className='form-group mb-2'>
                                    <label className='text-uppercase mb-2'>{getTextByKey("genre-form")}*</label>
                                    <select className='p-2' name="sexo" onChange={onRegisterInputChange} value={sexo}>
                                        <option value="M">{getTextByKey("genre-form-m")}</option>
                                        <option value="F">{getTextByKey("genre-form-f")}</option>
                                    </select>
                                </div> */}
                                <div className='form-group mb-2'>
                                    <label className='text-uppercase'>{getTextByKey("date-form")}*</label>
                                    <input
                                        type="date"
                                        className='p-1 p-md-2 inputdate'
                                        name="fechaNacimiento"
                                        onChange={onRegisterInputChange}
                                        value={fechaNacimiento}
                                    />
                                </div>
                                {/* <div className='form-group mb-2'>
                                    <label className='text-uppercase mb-2'>{getTextByKey("document-form")}</label>
                                    <input
                                        type="number"
                                        className='p-1 p-md-2'
                                        name="documento"
                                        onChange={onRegisterInputChange}
                                        value={documento}
                                    />
                                </div> */}
                                <div className='form-group mb-2 position-relative'>
                                    <label className='text-uppercase'>{getTextByKey("password-form")}*</label>
                                    <div className="w-100 position-relative">
                                        <input
                                            type={typePassword}
                                            className='p-1 p-md-2'
                                            name="password"
                                            onChange={onRegisterInputChange}
                                            value={password}
                                        />
                                        {/* ic:outline-remove-red-eye */}
                                        <a className="btn-show-pass" onClick={showPass}>
                                            <Icon icon="ic:outline-remove-red-eye" />
                                        </a>
                                    </div>
                                    <small className="mt-1 text-uppercase text-dark link-reset-password w-100 text-end">{getTextByKey("help-password-conditions")}</small>
                                </div>
                                <div className='form-group mb-2'>
                                    <label className="container-check-s">
                                        <small>{getTextByKey("newsletter-form")}</small>
                                        <input
                                            type="checkbox"
                                            name="newsletter"
                                            onChange={handleNewsletter}
                                            value={newsletter}
                                        />
                                        <span className="checkmark-s">
                                            <img src="/img/SVG/check.svg" />
                                        </span>
                                    </label>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className="container-check-s">
                                        <small>{getTextByKey("register-accept-part1")}</small>
                                        <input
                                            type="checkbox"
                                            name="politicas"
                                            onChange={handlePoliticas}
                                            value={politicas}
                                        />
                                        <span className="checkmark-s">
                                            <img src="/img/SVG/check.svg" />
                                        </span>
                                    </label>
                                </div>
                                {/* <div className='form-group mb-4 text-center'>
                                    <p>{getTextByKey("register-accept-part1")}</p>
                                </div> */}
                                {errors ? <div className='error-msg'>{errors}</div> : null}
                                <div className='form-group text-center mb-3'>
                                    <button className='btn-g bb py-2 px-3 text-uppercase' disabled={loading}>{loading ? `${getTextByKey('registering')}` : `${getTextByKey('register')}`}</button>
                                </div>
                                <div className='form-group text-center mb-3'>
                                    <label > <Link to="/login" className='text-uppercase return-login'>{currentLocale === 'es' ? 'O vuelve a' : 'Return to'} <span className="text-decoration-underline" onClick={() => { window.scroll(0, 0) }}>{getTextByKey("login-button")}</span> </Link> </label>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>

        </Fragment>
    )
}
