import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { toggleFav, favoritouser, misFavoriteUser } from "../api/productApi";
import "../scss/product.scss";
import { useAuth } from "../authContext";
import { useApi } from "../context/apiContext";
import ModalLogUserComponent from "./ModalLogUserComponent";
import { useNavigate } from 'react-router'



const FavoriteClick = ({ product }) => {

  const [isFav, setIsFav] = useState(false);

  const { currentUser, getTextByKey } = useAuth();

  const { updateFavs, currentFav, updateFavCount, favCount } = useApi([]);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  
  useEffect(() => {
    if (product) {
      setLoading( true );
      setIsFav( false );
      if (currentFav.length > 0) {
        if (currentFav.length > 0) {
          let findited = currentFav?.find((x) => x.plu === product.plu);
          if (findited) {
            setIsFav(true);
            setLoading( false );
          } else {
            setIsFav(false);
            setLoading( false );
          }
        }
      } else {
        setLoading(false);
        setIsFav(false)
      }
    }
    let favorites = [];
    favorites = JSON.parse(localStorage.getItem('favoritosList'));
    const params = new URLSearchParams(location.search);
    if( params.keys.length > 0) {
      if (favorites.length > 0) {
        const productFind = favorites.find(prod => prod.plu == params.plu)
        productFind ? setIsFav(true) : setIsFav(false)
      }
    }
  }, [product]);


  const press = (product) => {
    if (product) {
      if (currentUser) {
        setLoading(true);
        toggleFav(currentUser.uid, product.plu).then((data) => {
          setLoading(false);
          updateFavCount();
          if (data.code === 201) {
            setIsFav(true);
          } else {
            setIsFav(false)
          }
          updateFavs();
          // navigate(0)
        }).catch((error) => {
          updateFavCount();
          setLoading(false);
        });
      } else {
        handleShowModal();
      }
    }
  };

  const [showModal, setShowModal] = useState(false)

  const handleShowModal = () => {
    setShowModal(true)
  }

  return (
    <>
      <a
        className='Fav position-absolute primary-color'
        onClick={() => {
          press(product);
        }}
      >
        {!loading ? (
          <>
            {!isFav ? <Icon icon='akar-icons:heart' className="favorito-icon" /> : <Icon icon='ant-design:heart-filled' className="favorito-icon" style={{ color: "black" }} />}
          </>
        ) : (
          <div className='spinner-border spinner-border-sm' role='status'></div>
        )}
      </a>
      <ModalLogUserComponent showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default FavoriteClick;