import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import ReactGifLoader from "./components/ReactGifLoader";

import { Header } from "./components/header";
import { Footer } from "./components/footer";
import './scss/var.scss';
import './scss/mixin.scss';
import './App.scss';
import './scss/breackpoint_only_mobile.scss';
import './scss/breackpoint_sm.scss';
import './scss/breackpoint_md.scss';
import './scss/breackpoint_lg.scss';
import './scss/breackpoint_only_tablet.scss';
import './scss/breackpoint_xl.scss';
import './scss/breackpoint_only_hd.scss';
import './scss/breackpoint_huge.scss';

import HomePage from "./pages/HomePage/HomePage";
import BasePage from "./pages/HomePage/BasePage";
import ShopPage from "./pages/ShopPage/ShopPage";
import ProductPage from "./pages/ProductPage/ProductPage";
import {CheckoutaPage} from "./pages/CheckoutPage/CheckoutPage";
import CheckoutbPage from "./pages/CheckoutPage/CheckoutbPage";
import CheckoutcPage from "./pages/CheckoutPage/CheckoutcPage";
import CheckoutFastPage from "./pages/CheckoutPage/CheckoutFastPage";
import ModaconscientePage from "./pages/ModaconscientePage/ModaconscientePage";
import AcercaPage from "./pages/AcercaPage/AcercaPage";
import DetallescuentaPage from "./pages/PerfilPage/DetallescuentaPage";
import MetodospagoPage from "./pages/PerfilPage/MetodospagoPage";
import DireccionesPage from "./pages/PerfilPage/DireccionesPage";
import FeedbackaPage from "./pages/FeedbackPage/FeedbackaPage";
import TarjetaaPage from "./pages/TarjetaregaloPage/TarjetaaPage";
import TarjetabPage from "./pages/TarjetaregaloPage/TarjetabPage";
import TarjetacPage from "./pages/TarjetaregaloPage/TarjetacPage";
import TarjetadPage from "./pages/TarjetaregaloPage/TarjetadPage";
import ContactoPage from "./pages/ContactoPage/ContactoPage";
import PreguntasPage from "./pages/PqrsPage/PreguntasPage";
import EnviosPage from "./pages/PqrsPage/EnviosPage";
import PoliticacomprasPage from "./pages/PqrsPage/PoliticacomprasPage";
import AvisoprivacidadPage from "./pages/PqrsPage/AvisoprivacidadPage";
import PoliticadatosPage from "./pages/PqrsPage/PoliticadatosPage";
import PoliticacambiosPage from "./pages/PqrsPage/PoliticacambiosPage";
import FormulariocontactoPage from "./pages/PqrsPage/FormulariocontactoPage";
import FeedbacksliderPage from "./pages/FeedbackPage/FeedbacksliderPage";
import ColeccionPage from "./pages/ShopPage/ColeccionPage";
import CombinacionesPage from "./pages/ShopPage/CombinacionesPage";
import CombinacionPage from "./pages/ShopPage/CombinacionPage";
import OfertasPage from "./pages/ShopPage/OfertasPage";
import FindsizePage from "./pages/FindsizePage/FindsizePage";
import PedidosPage from "./pages/PerfilPage/PedidosPage";
import FavoritosPage from "./pages/PerfilPage/FavoritosPage";
import NftPage from "./pages/NftPage/NftPage";
import NewsletterPage from "./pages/NewsPage/NewsletterPage";
import CompraPage from "./pages/FeedbackPage/CompraPage";
import BuscadorComponent from "./components/BuscadorComponent";
import WomenPage from "./pages/ShidoWomenPage/WomenPage";
import PagoExitosoPage from "./pages/CheckoutPage/PagoExitosoPage";
import LoginPage from "./pages/UserPage/LoginPage";
import RegisterPage from "./pages/UserPage/RegisterPage";
import { AuthProvider } from "./authContext";
import { CartProvider } from "./context/cartContext";
import { ApiProvider } from "./context/apiContext";
import PreorderPage from "./pages/HomePage/PreorderPage";
import ResetPassPage from "./pages/UserPage/ResetPassPage";
import { ManifiestoPage } from "./pages/ModaconscientePage/ManifiestoPage";
import { EstrategiaPage } from "./pages/ModaconscientePage/EstrategiaPage";

import TagManager from 'react-gtm-module';
import { getEnvVariables } from "./helpers";
import DisclaimerCookiesComponent from "./components/DisclaimerCookiesComponent";


export function App(){

    const isPreorder = window.location.pathname.includes('/preorder');

    const { GTM } = getEnvVariables();

    const paddinTop = event => {
        if (isPreorder) {
            const element = document.getElementById('main');
            element.classList.add('no-padding');
        }
    };

    const scrollTop = () => { window.scroll(0, 0); }

    const [showModal, setShowModal] = useState(false);

    const eventTagManager = (top = true) => {
        TagManager.dataLayer({
            dataLayer: {
                event: "pageview",
                page: location.pathname,
            }
        });
        if(!top) scrollTop();
    }

    useEffect(() => {
        if(localStorage.getItem('cookies_shido') === 'true'){
            setShowModal(false)
        }else{
        setShowModal(true)
        }
        TagManager.initialize({ gtmId: GTM });
    }, [])


    return (
        <Fragment>
            <AuthProvider>
            <CartProvider>
            <ApiProvider>
            <BrowserRouter>
                {!isPreorder && <Header/>}
                <BuscadorComponent/>
                <DisclaimerCookiesComponent showModal={showModal} setShowModal={setShowModal} />
                <div className="main" id="main" onLoad={paddinTop}>
                <Routes>
                    <Route onClick={() => eventTagManager()} path="/" element={<HomePage />} />
                    <Route onClick={() => eventTagManager()} path="/login" element={<LoginPage />} />
                    <Route onClick={() => eventTagManager()} path="/register" element={<RegisterPage />} />
                    <Route onClick={() => eventTagManager()} path="/reset-password" element={<ResetPassPage />} />
                    <Route onClick={() => eventTagManager()} path="/base" element={<BasePage />} />
                    <Route onClick={() => eventTagManager()} path="/preorder" element={<PreorderPage />} />
                    <Route onClick={() => eventTagManager()} path="/shop" element={<ShopPage />} >
                        <Route onClick={() => eventTagManager()} path=":categoria" element={<ShopPage />} >
                            <Route onClick={() => eventTagManager()} path=":actividad/:colores/:talla/:linea/:order" element={<ShopPage />} />
                        </Route>
                    </Route>
                    <Route onClick={() => eventTagManager()} path="/collection" element={<ShopPage />} >
                        <Route onClick={() => eventTagManager()} path=":categoria" element={<ShopPage />} >
                            <Route onClick={() => eventTagManager()} path=":actividad/:colores/:talla/:linea/:order" element={<ShopPage />} />
                        </Route>
                    </Route>
                    <Route onClick={() => eventTagManager()} path="/offers" element={<ShopPage />} >
                        <Route onClick={() => eventTagManager()} path=":categoria" element={<ShopPage />} >
                            <Route onClick={() => eventTagManager()} path=":actividad/:colores/:talla/:linea/:order" element={<ShopPage />} />
                        </Route>
                    </Route>
                    <Route onClick={() => eventTagManager()} path="/combinations" element={<CombinacionesPage />} />
                    <Route onClick={() => eventTagManager()} path="/combination/:id" element={<CombinacionPage />} />
                    <Route onClick={() => eventTagManager()} path="/product/:plu" element={<ProductPage />} />
                    <Route onClick={() => eventTagManager()} path="/find-my-size" element={<FindsizePage />} />
                    <Route onClick={() => eventTagManager()} path="/checkout-a" element={<CheckoutaPage />} />
                    <Route onClick={() => eventTagManager(false)} path="/checkout-b" element={<CheckoutbPage />} />
                    <Route onClick={() => eventTagManager(false)} path="/checkout-c" element={<CheckoutcPage />} />
                    <Route onClick={() => eventTagManager()} path="/checkout-d" element={<CheckoutFastPage />} />
                    <Route onClick={() => eventTagManager()} path="/payment-success" element={<PagoExitosoPage />} />
                    <Route onClick={() => eventTagManager()} path="/conscious" element={<ModaconscientePage />} />
                    <Route onClick={() => eventTagManager()} path="/manifiesto" element={<ManifiestoPage />} />
                    <Route onClick={() => eventTagManager()} path="/estrategia" element={<EstrategiaPage />} />
                    <Route onClick={() => eventTagManager()} path="/about-shido" element={<AcercaPage />} />
                    <Route onClick={() => eventTagManager()} path="/profile-details" element={<DetallescuentaPage />} />
                    <Route onClick={() => eventTagManager()} path="/profile-methods" element={<MetodospagoPage />} />
                    <Route onClick={() => eventTagManager()} path="/profile-address" element={<DireccionesPage />} />
                    <Route onClick={() => eventTagManager()} path="/profile-orders" element={<PedidosPage />}/>
                    <Route onClick={() => eventTagManager()} path="/profile-favs" element={<FavoritosPage />}/>
                    <Route onClick={() => eventTagManager()} path="/feedback-a" element={<FeedbackaPage />} />
                    <Route onClick={() => eventTagManager()} path="/feedback-slider" element={<FeedbacksliderPage />} />
                    <Route onClick={() => eventTagManager()} path="/tarjeta-a" element={<TarjetaaPage />} />
                    <Route onClick={() => eventTagManager()} path="/tarjeta-b" element={<TarjetabPage />} />
                    <Route onClick={() => eventTagManager()} path="/tarjeta-c" element={<TarjetacPage />} />
                    <Route onClick={() => eventTagManager()} path="/tarjeta-d" element={<TarjetadPage />} />
                    <Route onClick={() => eventTagManager()} path="/compra-news" element={<CompraPage />} />
                    <Route onClick={() => eventTagManager()} path="/contacto" element={<ContactoPage />} />
                    <Route onClick={() => eventTagManager()} path="/preguntas-frecuentes" element={<PreguntasPage />} />
                    <Route onClick={() => eventTagManager()} path="/envios" element={<EnviosPage />} />
                    <Route onClick={() => eventTagManager()} path="/politicas-de-compras" element={<PoliticacomprasPage />} />
                    <Route onClick={() => eventTagManager()} path="/aviso-de-privacidad" element={<AvisoprivacidadPage />} />
                    <Route onClick={() => eventTagManager()} path="/politica-de-datos" element={<PoliticadatosPage />} />
                    <Route onClick={() => eventTagManager()} path="/politica-de-cambios-devoluciones" element={<PoliticacambiosPage />} />
                    <Route onClick={() => eventTagManager()} path="/contactanos" element={<FormulariocontactoPage />} />
                    <Route onClick={() => eventTagManager()} path="/nft" element={<NftPage />} />
                    <Route onClick={() => eventTagManager()} path="/news-letter" element={<NewsletterPage />} />
                    <Route onClick={() => eventTagManager()} path="/shido-society" element={<WomenPage />} />
                </Routes>
                </div>
                <ReactGifLoader />
                {!isPreorder &&<Footer/>}
            </BrowserRouter>
            </ApiProvider>
            </CartProvider>
            </AuthProvider>
        </Fragment>
    );
}