import { Fragment } from "react";
import { useApi } from "../../context/apiContext";
import { getGallery } from "../../api/galleryApi";
import { images_products_route } from "../../api/productApi";

import React, { useState, useEffect } from 'react'
import MasvendidosSliderComponent from "../ShopPage/components/MasvendidosSliderComponent";

export default function ModaconscientePage() {

  const [publication, setPublication] = useState([]);

  const [imagenBannerMobile1, setImagenBannerMobile1] = useState()
  const [imagenBannerMobile2, setImagenBannerMobile2] = useState()


  useEffect(() => {
    getGallery("moda-consciente-contenido").then((data) => {
      setPublication(data);

      data.forEach(element => {
        if( element.link == 'banner1-moda-consciente-mobile' ) {
          setImagenBannerMobile1( element.image )
        } 
        if( element.link == 'banner2-moda-consciente-mobile' ) {
          setImagenBannerMobile2( element.image )
        } 
      });
    });
  }, []);

  const { getPropiertyByLocale } = useApi();

  return (
    <Fragment>
      <div className="banner position-relative mb-2 mb-md-5">
        {publication.length > 0 && <img className="w-100 d-none d-md-block" src={images_products_route + publication[0].image+'?format=webp'} alt={"SHIDO"} />}
        { imagenBannerMobile1 && <img className='w-100 d-block d-md-none' src={images_products_route+imagenBannerMobile1+'?format=webp'}/>}
        <div className="content-banner position-absolute">
          <h2 className="secundary-color titulo-big mb-0 text-center text-uppercase">
            {publication.length > 1 && getPropiertyByLocale(publication[0].names, "titulo")}
          </h2>
        </div>
      </div>
      <div className="container moda-consciente">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center px-3 py-4">
              {/* <p>{}</p> */}
              {publication.length > 1 && <div dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(publication[0].names, "descripcion") }}></div>}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            {publication.length > 0 && <img className="w-100 my-4" src={images_products_route + publication[1].image+'?format=webp'} alt={"SHIDO"} />}
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="p-4">
              <h3 className="quaternary-color mb-4 text-uppercase" >
                {publication.length > 1 && <div dangerouslySetInnerHTML={{ __html:  getPropiertyByLocale(publication[1].names, "titulo") }}></div>}
              </h3>
              {publication.length > 1 && <div dangerouslySetInnerHTML={{ __html:  getPropiertyByLocale(publication[1].names, "descripcion") }}></div>}
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="p-4">
              <h3 className="quaternary-color mb-4 text-uppercase" >
              {publication.length > 1 &&  <div dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(publication[2].names, "titulo") }}></div>}
              </h3>
              {publication.length > 1 &&  <div dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(publication[2].names, "descripcion") }}></div>}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            {publication.length > 0 && <img className="w-100 my-4" src={images_products_route + publication[2].image+'?format=webp'} alt={"SHIDO"} />}
          </div>
          <div className="col-12 col-lg-6">
            {publication.length > 0 && <img className="w-100 my-4" src={images_products_route + publication[3].image+'?format=webp'} alt={"SHIDO"} />}
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="p-4">
              <h3 className="quaternary-color mb-4 text-uppercase" >
                {publication.length > 1 && <div dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(publication[3].names, "titulo") }}></div>}
              </h3>
              {publication.length > 1 && <div dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(publication[3].names, "descripcion") }}></div>}
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="p-4">
              <h3 className="quaternary-color mb-4 text-uppercase" >
                {publication.length > 1 && <div dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(publication[4].names, "titulo") }}></div>}
              </h3>
              {publication.length > 1 && <div dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(publication[4].names, "descripcion") }}></div>}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            {publication.length > 0 && <img className="w-100 my-4" src={images_products_route + publication[4].image+'?format=webp'} alt={"SHIDO"} />}
          </div>
        </div>
        {/* <div className="row">
            <div className="col-12 py-lg-5">
              <MasvendidosSliderComponent/>
            </div>
          </div> */}
      </div>

      <div className="banner position-relative">
        {publication.length > 0 && <img className="w-100 d-none d-md-block" src={images_products_route + publication[5].image} alt={"SHIDO"} />}
        { imagenBannerMobile2 && <img className='w-100 d-block d-md-none' src={images_products_route+imagenBannerMobile2+'?format=webp'}/>}
        <div className="content-banner position-absolute">
          <h4 className="secundary-color titulo-big mb-0 text-center text-uppercase w-75 mx-auto">
            {publication.length > 1 && <div dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(publication[5].names, "titulo") }}></div>}
          </h4>
        </div>
      </div>
    </Fragment>
  )
}



