import React, { Component, Fragment, useState, useEffect } from 'react'
import { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { NavLink } from 'react-router-dom';
import { getGallery } from '../../../api/galleryApi';
import { images_products_route } from '../../../api/productApi';

const SliderBComponent = ( {slideProgress} ) => {

    // const [swiper, setSwiper] = useState( useSwiper() )
    const swiper = useSwiper();
    const [swiperProgress, setSwiperProgress] = useState(null)
    useEffect(() => {
        if( swiperProgress ) {
            swiperProgress.slideTo(slideProgress)
        }
    }, [slideProgress])


    const [imagen, setImagen] = useState([])
    useEffect(() => {
        async function gallery() {
            const response = await getGallery('encuesta-image');
            setImagen( response );
        }
        gallery();
    }, [])
    
    


    return (
        <Fragment>
            <form className='slide-process'>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation={{
                        nextEl: ".next"
                    }}
                    simulateTouch={false}
                    onSwiper={(swiper) => { setSwiperProgress(swiper) }}>
                        {
                            imagen.length > 0 && imagen.map((imagen, index) => (
                                <SwiperSlide>
                                    <div className='py-2' key={index}>
                                        <img className='w-100 rounded-full' src={ imagen && images_products_route + imagen.image }/>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                </Swiper>
            </form>
        </Fragment>
    )
}

export default SliderBComponent