import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DropMenuComponent from "./DropMenuComponent";
import { Icon } from '@iconify/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import OpctionsHeaderComponent from "./OpctionsHeaderComponent";
import CarritoComponent from "./CarritoComponent";
import { Idiomas } from "./IdiomasComponent";
import { useApi } from "../context/apiContext";
import { getGallery } from "../api/galleryApi";
import { images_products_route } from "../api/productApi";
import { useCart } from "../context/cartContext";
import { BtnClose } from "./BtnClose";

export function Header(){
    const { getTextByKey,currentCategories,extractNameFromLocate, currentLines } = useApi();

    const [isColombia, setIsColombia] = React.useState(false);

    useEffect(() => {
        // if( localStorage.getItem('actual_ip_shido') ) {
        //     const { country } = JSON.parse(localStorage.getItem('actual_ip_shido'))
        //     country.toLowerCase() === 'colombia' ? setIsColombia( true ) : setIsColombia( false );
        //     console.log( isColombia );
        // }
    }, [])

    const openDrop = event => {
        const drop = document.getElementById('drop-menu');
        drop.classList.toggle('show');        
        const body = document.getElementsByTagName('body')[0];
        if( !body.classList.contains('over-hidden') ) {
            body.classList.add('over-hidden');
        }
        const carrito = document.getElementById('carrito-rapido');
        if( carrito.classList.contains('show') ) {
            hideCart();
        }

        console.log(body);
    };

    const { hideCart } = useCart();

    const closeDrop = event => {
        window.scroll(0, 0);
        const drop = document.getElementById('drop-menu');
        
        drop.classList.remove('show');
        const body = document.getElementsByTagName('body')[0];
        hideCart();
        closeMenu();
        window.scroll(0, 0);
    };

    const showOpt = event => {
        const opt = document.querySelectorAll('.opt');
        const optp = document.querySelectorAll('.opt-p');
        opt.forEach((element) => {
            element.classList.toggle('show');
        });
        optp.forEach((element) => {
            element.classList.remove('show');
        });
    }

    const BackOptp = event => { 
        const opt = document.querySelectorAll('.opt');
        const optp = document.querySelectorAll('.opt-p');
        optp.forEach((element) => {
            element.classList.add('show');
        });
        opt.forEach((element) => {
            element.classList.remove('show');
        });
    }

    const closeMenu = event => {
        window.scroll(0, 0);
        const menu = document.getElementById('menu-mobile');
        menu.classList.remove('show');
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('over-hidden');
        BackOptp();
    }

    const [logo, setLogo] =  useState([]);

    useEffect(() => {
        getGallery("logo-img").then((data) => {
            setLogo(data);
        });
    }, []);

    return(
        <header id="header">
            <div className="text-center d-none d-md-block border-bottom">
                <div className="container py-2 link-header">
                    {/* <p className="mb-0 text-uppercase">{getTextByKey("top-text")}</p> */}
                </div>
            </div>
            <div className="container-fluid panel-header d-md-flex justify-content-between justify-content-lg-around align-items-center">
                <NavLink to="/" className="d-flex justify-content-center py-2 py-lg-0" onClick={closeDrop}>
                    {logo.length > 0 && <img className="logo" src={images_products_route+logo[0].image+'?format=webp'} alt={"Logo"} />}
                </NavLink>
                <ul className="menu desk d-md-flex justify-content-center align-items-center p-0 m-0 py-md-3 py-lg-0">
                    <li>
                       <a onClick={openDrop} className="py-1 px-2 py-md-2 px-md-2 px-xl-4 primary-color">{getTextByKey("tienda")}</a>
                    </li>
                    <li><NavLink to="/combinations" onClick={closeDrop} className="py-1 px-2 py-md-2 px-md-2 px-xl-4 primary-color">{getTextByKey("combinaciones")}</NavLink></li>
                    <li><NavLink to="/collection" onClick={closeDrop} className="py-1 px-2 py-md-2 px-md-2 px-xl-4 primary-color">{getTextByKey("nueva-collecion")}</NavLink></li>
                    <li><NavLink to="/conscious" onClick={closeDrop} className="py-1 px-2 py-md-2 px-md-2 px-xl-4 primary-color">{getTextByKey("moda-consciente")}</NavLink></li>
                    {/* se oculta por peticion del cliente */}
                    {/* <li><NavLink to="/offers" onClick={closeDrop} className="py-1 px-2 py-md-2 px-md-2 px-xl-4 primary-color">{getTextByKey("ofertas")}</NavLink></li> */}
                </ul>

                <OpctionsHeaderComponent/>
                {/* <div className="d-none d-md-block">
                    { !isColombia && <Idiomas/>}
                </div>  */}
                <DropMenuComponent/>
                <CarritoComponent/>

                {/* <a className="p-2 primary-color call-menu" onClick={showMenu}>
                    <Icon icon="dashicons:menu" />
                </a> */}

                <div className="menu-mobile p-4 d-md-none" id="menu-mobile">
                    
                    <div className="option d-flex align-items-center justify-content-between py-3 position-relative">
                        <a className="back opt" onClick={BackOptp}><Icon icon="grommet-icons:form-previous"/></a>
                        <a className="close" onClick={closeMenu}> <BtnClose /> </a>
                        <div className="line-option position-absolute"></div>
                    </div>
                    <div className="content-opt">
                        <div className="scroll-content pe-2">
                            <div className="py-2 opt-p show">
                                <ul className="ps-2">
                                    <li className="d-flex justify-content-between align-items-center">
                                        <NavLink to="/shop" onClick={closeMenu} className="py-2 px-2 py-md-2 px-md-2 px-xl-4 primary-color">{getTextByKey("tienda")}</NavLink>
                                        <button onClick={showOpt}><Icon icon="grommet-icons:form-next"/></button>
                                    </li>
                                    <li><NavLink to="/combinations" onClick={closeMenu} className="py-2 px-2 py-md-2 px-md-2 px-xl-4 primary-color">{getTextByKey("combinaciones")}</NavLink></li>
                                    <li><NavLink to="/collection" onClick={closeMenu} className="py-2 px-2 py-md-2 px-md-2 px-xl-4 primary-color">{getTextByKey("nueva-collecion")}</NavLink></li>
                                    <li><NavLink to="/conscious" onClick={closeMenu} className="py-2 px-2 py-md-2 px-md-2 px-xl-4 primary-color">{getTextByKey("moda-consciente")}</NavLink></li>
                                    {/* se oculta por peticion del cliente */}
                                    {/* <li><NavLink to="/offers" onClick={closeMenu} className="py-2 px-2 py-md-2 px-md-2 px-xl-4 primary-color">{getTextByKey("ofertas")}</NavLink></li> */}
                                </ul>
                            </div>
                            <div className='p-2 opt' id="sub-menu">
                                <h3 className='gotham-Bold upper'>{getTextByKey("categorias")}</h3>
                                <ul className="ps-2">
                                    {currentCategories.map((category, index) => <li key={index}><NavLink to={"/shop/"+category.id} onClick={closeMenu} className="mb-1">{extractNameFromLocate(category)}</NavLink></li> )}
                                </ul>
                                <h3 className='gotham-Bold'>DESTACADOS</h3>
                                <ul className="ps-2">
                                <li><NavLink to="/collection" onClick={closeDrop} className="mb-1 text-uppercase">{getTextByKey("nueva-collecion")}</NavLink></li>
                                <li><NavLink to="/conscious" onClick={closeDrop} className="mb-1 text-uppercase">{getTextByKey("moda-consciente")}</NavLink></li>
                                <li><NavLink to="/tarjeta-a" onClick={closeDrop} className="mb-1 text-uppercase">{getTextByKey("card-gift")}</NavLink></li>
                                {
                                    currentLines.map((line, index) => <li key={index}><NavLink to={"/shop/0/0/0/0/"+line.id+"/normal"} onClick={closeDrop} className="mb-1 text-uppercase">{extractNameFromLocate(line)}</NavLink></li> )
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </header>
    );
}