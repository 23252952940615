import { Fragment, useState, useEffect } from "react";
import { ModaleliminarcuentaComponent } from "./components/ModaleliminarcuentaComponent";
import { NavComponent } from "./components/NavComponent";
import { NavsupComponent } from "./components/NavsupComponent";
import '../../scss/perfilPage.scss'
import RecomendadosSliderComponent from "../../components/RecomendadosSliderComponent";
import { useApi } from "../../context/apiContext";
import { misFavoriteUser } from "../../api/productApi";
import ProductsmallComponent from "../../components/productsmallComponent";
import { useAuth } from "../../authContext";


export default function FavoritosPage() {
  const { currentUser } = useAuth();
  const { favCount, getTextByKey, currentFav } = useApi();
  const [favoritesNew, setfavoritesNew] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    async function callListFavorites() {
      setLoading( true );
      let favorites = [];
      if( localStorage.getItem('fvaoritosList') ) {
        favorites = JSON.parse(localStorage.getItem('fvaoritosList'))
      } else {
        favorites = await misFavoriteUser(currentUser.uid);
        localStorage.setItem( 'favoritosList', JSON.stringify(favorites) )
      }
      if (favorites.length > 0) {
        setfavoritesNew(favorites)
      } else {
        setfavoritesNew([]);
      }
      setLoading( false );
    }
    callListFavorites();
  }, [])

  useEffect(() => {
    console.log('Actualizar lista favoritos');
    async function callListFavorites() {
      setLoading( true );
      let favorites = [];
      if( localStorage.getItem('fvaoritosList') ) {
        favorites = JSON.parse(localStorage.getItem('fvaoritosList'))
      } else {
        favorites = await misFavoriteUser(currentUser.uid);
        localStorage.setItem( 'favoritosList', JSON.stringify(favorites) )
      }
      if (favorites.length > 0) {
        setfavoritesNew(favorites)
      } else {
        setfavoritesNew([]);
      }
      setLoading( false );
    }
    callListFavorites();
  }, [currentFav])


  return (
    <Fragment>
      <div className='quaternary-bg-color head-perfil'>
        <div className='container'>
          <div className='text-center'>
            <h1 className='text-uppercase active'>{getTextByKey("account-title")}</h1>
            <NavsupComponent />
          </div>
        </div>
      </div>
      <div className='container-fluid px-4 px-lg-0 py-5'>
        <div className='d-lg-flex'>
          <div className='col-nav mb-4 mb-lg-0'>
            { currentUser && <NavComponent />}
          </div>
          <div className='col-content-perfil'>
            <h2 className='text-uppercase mb-2'>Favoritos</h2>
            <h6 className='fw-bold'>{favoritesNew.length} items</h6>
            <div className='py-2 py-md-3 py-xl-4 content-favoritos'>
              {
                !loading ? (
                  <div className='row'>
                    {favoritesNew.length > 0 ? (
                      favoritesNew.map((fav, index) => (
                        <div className='col-6 col-md-2 p-0' key={index}>
                          <ProductsmallComponent key={index} product={fav}/>
                        </div>
                      ))
                    ) : (
                      <h6 className='fw-bold'> NO TENEMOS FAVORITOS </h6>
                    )}
                  </div>
                ) : <div className='spinner-border spinner-border-sm' role='status'></div>
              }
            </div>
            <h2 className='text-uppercase'>Recomendados</h2>
            <div className='py-2 py-md-3 py-xl-4'>
              <RecomendadosSliderComponent favoritosCount={favCount} />
            </div>
          </div>
        </div>
      </div>
      <ModaleliminarcuentaComponent />
    </Fragment>
  );
}
