export const getEnvVariables = () => {
  return {
    API_URL: 'https://er1gm7ybba.execute-api.us-east-1.amazonaws.com/api/',
    API_URL_ALT: 'https://dtq7mchtk7eu.cloudfront.net/api/',
    API_URL_TEST: 'https://127.0.0.1:8000/api',
    TokenAPI: '123456fabian',
    IMAGES_URL_PRODUCTS: "https://dtq7mchtk7eu.cloudfront.net/products/",
    IMAGES_URL_COLLECTIONS: "https://dtq7mchtk7eu.cloudfront.net/collections/",
    IMAGES_URL_CATEGORIES: "https://dtq7mchtk7eu.cloudfront.net/category/",
    GOOGLE_API_KEY: 'AIzaSyDBuBfixcvfxmRdACJEiyb3tqw8PeCjM8I',
    GTM: 'GTM-W6VT4XC'
  } 
}
