import { Icon } from "@iconify/react";
import React from "react";

export const Collapse = ({ index, ask }) => {

  return (
    <>
      <div className='item-collapse border-bottom primary-border-color mb-2'>
        <a
          className='d-flex justify-content-between align-items-center py-3 gotham-Bold'
          data-bs-toggle='collapse'
          href={"#collapseExample" + index}
          role='button'
          aria-expanded='false'
          aria-controls='collapseExample'
        >
          <span>{index}.</span>
          <span>{ask?.titulo}</span>
          <Icon icon='akar-icons:chevron-down' />
        </a>
        <div className='collapse' id={"collapseExample" + index}>
          <div className='py-3'>
            <p>{ask?.descripcion}</p>
          </div>
        </div>
      </div>
    </>
  );
};
