import React, { Fragment } from 'react'
import { Icon } from '@iconify/react';
//import ReactImageMagnify from 'react-image-magnify';
import '../../scss/product_page.scss';

import FindmysizeComponent from '../../components/FindmysizeComponent';
import MasvendidosSliderComponent from '../ShopPage/components/MasvendidosSliderComponent';
import TallaComponent from './components/TallaComponent';
import ColorComponent from './components/ColorComponent';
import { useCart } from "../../context/cartContext";

import { images_products_route, getProductDetail, toggleFav, misFavoriteUser } from "../../api/productApi";
import { useApi } from "../../context/apiContext";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProductImage } from './components/ProductImage';
import { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import { BtnClose } from '../../components/BtnClose';
import { ModalImagenes } from '../../components/ModalImagenes';
import { useAuth } from '../../authContext';
import Select from 'react-select';
import TagManager from 'react-gtm-module';
import ModalLogUserComponent from '../../components/ModalLogUserComponent';

export default function ProductPage() {

  const params = useParams();

  const { getTextByKey, getPropiertyByLocale, convertCurrency, currentLocale } = useApi();

  const [productData, setProductData] = React.useState(false);

  const { currentUser } = useAuth();

  const [cant, setCant] = React.useState(0);
  const [imagenModal, setImagenModal] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [favoritesNew, setfavoritesNew] = useState([])
  const [modalImagenesConfig, setModalImagenesConfig] = useState({
    index: '',
    imagenes: [],
  })
  const [isFav, setIsfav] = useState(false)
  const [loadingFav, setLoadingFav] = useState(false)

  const { addToCart, showCart, checkIfExistInCart } = useCart();

  const [widthheight, setWidthheight] = React.useState({ width: 0, height: 0 });


  const [showModal, setShowModal] = useState(false)
  const [showModalFavorite, setShowModalFavorite] = useState(false)
  const [showMore, setShowMore] = useState(false)

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const addCart = () => {
    //check if size or color is selected
    console.log("addCart", productData);

    if (!productData.talla || productData.color === '' || cant === 0) {
      /* alert("Por favor seleccione una talla y un color"); */
      // handleShowModal();
      console.log('ERRORRRR')
      setErrorMsg( getTextByKey('missing-size-color') )
      setTimeout(() => {
        setErrorMsg('')
      }, 3000);
      return;
    }
    console.log("addCart camt", cant);
    if (cant > 0){ } else {
      /* alert("Por favor seleccione una talla y un color"); */
      // handleShowModal();
      // setErrorMsg( getTextByKey('missing-size-color') )
      setErrorMsg( "Por favor seleccione una cantidad" )
      setTimeout(() => {
        setErrorMsg('')
      }, 3000);
      return;
    }


    productData.type = "product";
    addToCart(productData, cant);
    showCart();

  }

  const checkIfExist = (actualItemId) => {
    return checkIfExistInCart(actualItemId);
  }

  const changeCantidad = (e) => {
    console.log("changeCantidad", e);
    setCant(e.value);
  }

  const handleTalla = (talla) => {
    setProductData({
      ...productData,
      cartPlu: talla.plu,
      talla: talla.id,
      size: talla.id
    });
  }

  const handleColor = (color) => {
    setProductData({
      ...productData,
      color: color
    });
  }

  useEffect(() => {
    window.scroll(0, 0);
    setProductData(false);
    getProductDetail(params.plu).then((data) => {
      console.log("getProductDetail", data);
      data.size = '';
      data.color = '';
      setProductData(data);
      //Tag Manager
      TagManager.dataLayer({
        dataLayer: {
          event: "view_item",
            ecommerce: null
          }
      });
      TagManager.dataLayer({
        dataLayer: {
        event: "view_item",
          ecommerce: {
            items: [{
              item_name: getPropiertyByLocale(data.names, "nombre"),
              item_id:  data.plu,
              price:  data.price,
              quantity: 1
            }]
          }
        }
      });
      //Tag Manager
      if(data){
        let newImg = new Image();
        newImg.onload = function() {
          var height = newImg.height;
          var width = newImg.width;
          setWidthheight({ width: width, height: height });
        }
        newImg.src = images_products_route + data.images[0].image;
      }
    });
    async function callListFavorites() {
      const favorites = await misFavoriteUser(currentUser.uid);
      console.log("Favoritos: ", favorites);
      if (favorites.length > 0) {
        const productFind = favorites.find( prod => prod.plu == params.plu )
        productFind ? setIsfav( true ) : setIsfav( false )
      }
    }
    callListFavorites();
  }, [params.plu]);


  function findPrincipal(images){
    return(
      images.find( e => e.principal)
    )
  }

  if(!productData)
    return (
      <div className='container'>
        <div className="text-center-c">
          <div className="loader-box">
            <img src="/img/SVG/loader.svg" alt="" />
          </div>
        </div>
      </div>
    );

    const favoriteClick = async (e, product) => {
      e.preventDefault();
      if( currentUser == null ) {
        setShowModalFavorite(true);
        return
      }



      setLoadingFav(true)
      setIsfav(false)
      setfavoritesNew([])
      const toggleFavResponse = await toggleFav(currentUser.uid, product.plu);
      const favorites = await misFavoriteUser(currentUser.uid);
      const productFind = favorites.find( prod => prod.plu == params.plu )
      productFind ? setIsfav( true ) : setIsfav( false )
      setLoadingFav(false)
    }

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? 'black' : 'white',
      }),
      control: (provided) => ({
        ...provided,
      })
    };
  

    const handleModalImagenes = (image, index) => {
      setImagenModal( images_products_route + image )
      const config = {
        index,
        imagenes: productData.images
      }
      setModalImagenesConfig( config )
    }

    const optionsCantidad = [
      {
        value: 1,
        label: 1
      },
      {
        value: 2,
        label: 2
      }
    ]

    const orderImages = (images) => {
      console.log('Imagenes si orden', images)
      console.log(images.sort( (a, b) => a.orden - b.orden))
      return images.sort( (a, b) => a.orden - b.orden)
    }

  return (
    <Fragment>

      <Modal show={showModal} onHide={handleClose} className='ModalObligatorio' centered>
        <Modal.Body>
            <div className='content mb-5 d-flex flex-column justify-content-center align-items-center'>
                <p className='text-uppercase text-center my-5'>{getTextByKey('missing-size-color')}</p>
                <a className="btn-g bb py-2 px-3 text-uppercase" onClick={handleClose}>{getTextByKey("btn-close-modal")}</a>
            </div>
        </Modal.Body>
      </Modal>      

      <div className='product-page'>
        {productData ? (
          <div className='container'>
            <div className='d-lg-flex'>
              <div className='images'>
              <ProductImage imageSrc={ findPrincipal(productData.images) && images_products_route + findPrincipal(productData.images).image } />
                <div className='d-flex justify-content-around align-items-center'>
                  {productData.images && orderImages(productData.images).map((image, index) => {
                    if(!image.principal){
                      return(
                        <picture className={ index+1 < productData.images.length && 'pe-2'  } key={index} data-bs-toggle="modal" data-bs-target="#ModalImagen" onClick={() => { handleModalImagenes( image.image, index) }} >
                          <img className="w-100 img-product" src={images_products_route + image.image+'?format=webp'} />
                        </picture>
                      )
                    }
                  })}
                </div>
              </div>
              <div className='product-description'>
                <form className='px-lg-4'>
                  <div className='form-group pb-4'>
                    <h3 className='mb-2 mb-md-4 primary-color gotham-Bold nombre-producto mt-3 mt-0'>{getPropiertyByLocale(productData.names, "nombre")}</h3>
                    <h3 className='mb-4 primary-color precio'>
                      {productData.price.sindescuento && <span className='price-before me-3'> {convertCurrency(productData.price.sindescuento)}</span>}
                      {convertCurrency(productData.price.precio)}
                    </h3>
                    {productData.price.sindescuento && (
                      <p className="gotham-Bold d-inline-block alert-color alert-border-color py-1 px-2 mb-0">
                        -{(100 - (productData.price.precio * 100) / productData.price.sindescuento).toFixed(0)}%
                      </p>
                    )}
                  </div>
                  <div className='form-group pb-4'>
                    <label className="mb-2">{getTextByKey('size')}</label>
                    <TallaComponent producto={productData} handleTalla={handleTalla} />
                  </div>
                  <div className='form-group pb-4'>
                    <a className='border-bottom primary-color text-transform-none find-my-size' data-bs-toggle="modal" data-bs-target="#FindmySizeModal">{getTextByKey("find-my-size")}</a>
                  </div>
                  <div className='form-group pb-4'>
                    <label className="mb-2">Color</label>
                    <ColorComponent producto={productData} handleColor={handleColor} />
                  </div>
                  <div className='form-group pb-4'>
                    <label className="mb-2">{getTextByKey("product-cantidad")}</label>
                    {/* <select className='w-auto' value={cant} onChange={changeCantidad} >
                      {[...Array(productData.cantidad).keys()].map((i, index) => (
                        <option key={index} value={i + 1}>{i + 1}</option>
                      ))}
                    </select> */}
                    <div className='content-cantidad'>
                      <Select styles={customStyles} className='react-select-container' classNamePrefix="react-select" options={optionsCantidad} onChange={changeCantidad} placeholder={currentLocale ==='es' ? 'Seleccionar' : 'Select'}/>
                    </div>
                  </div>
                  {errorMsg ? <div className='error-msg text-start'>*{errorMsg}</div> : null}
                  <div className='form-group pb-4 d-flex'>
                    <div onClick={addCart} className={!checkIfExist(productData.plu) ? 'btn-g bb py-2 px-4 me-2 d-flex align-items-center btn-add-car pointer' : 'btn-g bb py-2 px-4 me-2 d-flex align-items-center btn-add-car pointer animate__animated animate__bounce'}>
                      <Icon className={!checkIfExist(productData.plu) ? 'icon-add-car me-2 me-md-3' : 'icon-add-car me-2 me-md-3 animationIconCart'} icon="grommet-icons:shop" />
                      {!checkIfExist(productData.plu) ? `${getTextByKey('add-bag')}` : `${getTextByKey('added-bag')}`}
                    </div>
                    <ModalLogUserComponent showModal={showModalFavorite} setShowModal={setShowModalFavorite} />
                    <button onClick={(e) => { favoriteClick(e, productData) }} className={`btn-g btn-favorite-product py-2 px-3 ${isFav && 'favorite'}`}>
                      {/* <Icon className='icon-fav-b' icon="akar-icons:heart" /> */}
                      {!loadingFav ? (
                          <>
                            {!isFav ? <Icon icon='akar-icons:heart' /> : <Icon icon='ant-design:heart-filled'/>}
                          </>
                        ) : (
                          <div className='spinner-border spinner-border-sm' role='status'></div>
                        )}
                    </button>
                  </div>
                  <div className='form-group'>
                    <label className='mb-2'>{getTextByKey('description')}</label>
                    <p dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(productData.resumens, "resumen") }}></p>
                  </div>
                  <hr />
                  <div className='form-group'>
                    <label className='d-flex justify-content-between align-items-center btn-collapse' data-bs-toggle="collapse" href="#multiCollapseExample2" role="button" aria-expanded="false" aria-controls="multiCollapseExample2">{getTextByKey('fabrication')} <Icon icon='material-symbols:add-rounded' style={{color: '#8d8d8d', fontSize: '2rem'}}/></label>
                    <div className='collapse multi-collapse pt-2' id="multiCollapseExample2">
                      <p dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(productData.fabrications, "fabricacion") }}></p>
                    </div>
                  </div>
                  <hr />
                  <div className='form-group'>
                    <label className='d-flex justify-content-between align-items-center btn-collapse' data-bs-toggle="collapse" href="#multiCollapseExample6" role="button" aria-expanded="false" aria-controls="multiCollapseExample2">{getTextByKey('capsule-inspiration')} <Icon icon='material-symbols:add-rounded' style={{color: '#8d8d8d', fontSize: '2rem'}}/></label>
                    <div className='collapse multi-collapse pt-2' id="multiCollapseExample6">
                    <p dangerouslySetInnerHTML={{ __html:  getPropiertyByLocale(productData.inspirations, "inspiracion") }}></p>  
                    </div>
                  </div>

                  {/* <hr />
                  <div className='form-group'>
                    <label className='d-flex justify-content-between align-items-center btn-collapse' data-bs-toggle="collapse" href="#multiCollapseExample4" role="button" aria-expanded="false" aria-controls="multiCollapseExample4">{getTextByKey('shipping-returns')}<Icon icon='material-symbols:add-rounded' style={{color: '#8d8d8d', fontSize: '2rem'}}/></label>
                    <div className='collapse multi-collapse' id="multiCollapseExample4">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and </p>
                    </div>
                  </div>
                  <hr /> */}

                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className='container'>
            <div className="text-center-c">
              <div className="loader-box">
                <img src="/img/SVG/loader.svg" alt="" />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='container'>
        <div className='text-center py-2 py-md-3 py-lg-5'>
          <h2 className='text-uppercase'>{getTextByKey('best-sellers')}</h2>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-10 offset-1 px-0 mb-4'>
            <MasvendidosSliderComponent />
          </div>
        </div>
      </div>

      <div className="modal fade" id="FindmySizeModal" tabIndex="-1" aria-labelledby="FindmySizeModal" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content py-4">
            <a className='close-modal primary-color position-absolute' data-bs-dismiss="modal" aria-label="Close"> <BtnClose /> </a>
            <div className="modal-body">
              <FindmysizeComponent/>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade modal-imagen-min" id="ModalImagen" tabIndex="-1" aria-labelledby="ModalImagen" aria-hidden="true">
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <a className='close-modal primary-color' data-bs-dismiss="modal" aria-label="Close"> <BtnClose /> </a>
            <div className="modal-body">
              <ModalImagenes imagen={imagenModal} imagenesProducto={modalImagenesConfig.imagenes} index={modalImagenesConfig.index}/>
            </div>
          </div>
        </div>
      </div>

      

    </Fragment>
  )
}
