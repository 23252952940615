import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getGallery } from "../../api/galleryApi";
import { images_products_route } from "../../api/productApi";
import { useApi } from "../../context/apiContext";

export default function TarjetadPage() {

  const { getTextByKey, convertCurrency, currentLocale, getTextBigByKey } = useApi();
  const [infoTarjeta, setinfoTarjeta] = useState(null)

  useEffect(() => {
    if( localStorage.getItem('infoGiftCard') ) {
      const informacionTarjeta = JSON.parse(localStorage.getItem('infoGiftCard'));
      setinfoTarjeta( informacionTarjeta );
    }
  }, [])

  const [gallery, setGallery] = useState([]);

  useEffect(() => {
      getGallery('banner-tarjeta-regalo')
          .then((response) => {
              setGallery(response);
          })
  }, [])


  return (
    <Fragment>
        <div className="banner tarjeta-regalo position-relative">
            {gallery.length > 0 && <img className="w-100 d-none d-md-block" src={images_products_route+gallery[0].image+'?format=webp'} alt={"Banner"} />}
            {gallery.length > 0 && <img className="w-100 d-block d-md-none" src={images_products_route+gallery[1].image+'?format=webp'} alt={"Banner"} />}
          <div className="content-banner saldo p-3 p-md-0" /* style={{ backgroundImage: `url("img/Group-138.jpg")`}} */>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 offset-lg-3">
                    <div className="content-saldo p-3 p-lg-4 p-xl-5 text-center">
                      { infoTarjeta && (
                        <>
                          <h2 className="text-uppercase gotham-Bold mt-0 mb-4">{getTextByKey('title-saldo-gift-card')}</h2>
                          <p className="gotham-Bold text-uppercase mb-0">{getTextByKey('saldo-gift-card')} {convertCurrency(infoTarjeta.saldo)}</p>
                          <p className="mb-4 text-uppercase" dangerouslySetInnerHTML={{__html: getTextBigByKey('descripcion-saldo-gift-card')}}></p>
                          <Link to="/collection" className="btn-g py-2 px-4 text-uppercase">{ currentLocale === 'es' ? 'REGRESAR A TIENDA' : 'RETURN TO SALE' }</Link>
                        </>
                      )}
                      
                    </div>
                  </div>
                </div>
              </div>
              
          </div>
        </div> 
    </Fragment>
  )
}
