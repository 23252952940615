import { Icon } from '@iconify/react'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import ProductPreOrderComponent from '../../components/ProductPreOrderComponent'
import CollapseComponent from './components/CollapseComponent'
import '../../scss/preorder.scss'

export default function PreorderPage() {
    
    /* const hideElement = event => {
        const element = document.getElementById('header');
        element.classList.toggle('hideE');
    }; */

  return (
    <Fragment>
        {/* { hideElement() } */}
        <div className="banner position-relative mb-2 mb-md-5">
            <img className="w-100" src="https://iridian.co/imagenes/shido-preorder/Group-155-altas.jpg"/>
        </div>
        <div className='text-center py-4'>
            {/* <h2 className='text-uppercase stretch-pro'>Meet eva soon</h2>
            <h3 className='text-uppercase stretch-pro'>nov 18</h3> */}
            {/* <img className='soon mb-2 mb-lg-5' src="img/meet-eva-soon.png"/> */}
            <img className='soon mb-2 mb-lg-5' src="https://iridian.co/imagenes/shido-preorder/meet-eva-soon-new.png"/>
            <h2>Delight yourself with the designs arriving to Colombia.</h2>
        </div>
        <div className='container-fluid'>
            <div className='row'>
                <ProductPreOrderComponent/>
            </div>
        </div>
        <div className='container'>
            <div className='text-center py-4'>
                <Link to="/" className='btn-g bb py-2 px-3 text-uppercase'>WAITLIST</Link>
            </div>    
        </div>
        <div className='container text-center py-5'>
            <h2 className='text-uppercase stretch-pro mb-2 mb-md-3 mb-lg-5'>EVA CAPSULE</h2>
            <p>No stereotypes</p>
            <p>Inspired by the casualness and comfort outfits brought by pandemic times, from Tokyo to L.A., this capsule declares the athleisure practicality.</p>
            <p>The sporty, but always exclusive line combined with luxury accessories, bring birth to these creations.
Workout outfits with dynamic colors, and high-fashion garments, 
is how an authentic EVA is brought to life.
</p>
            <p>She [EVA] is who she wants to be, and she will choose whatever life she wants to choose.</p>
        </div>

        <div className='container text-center'>
            <div className='d-flex justify-content-center align-items-center instagram'>
                <h3 className="mb-0 me-2">FEEL INSPIRED IN:</h3>
                <a href="#"><img src="img/logo.png"/></a>
            </div>
        </div>
        
        <div className='container py-5'>
            
            <div className='row'>
                <div className='col-10 offset-1 col-lg-6 offset-lg-3 mb-5'>
                    <h3 className='fw-bold text-uppercase mb-4 text-center'>Feel Like pre ordering?</h3>
                    <h3 className='text-uppercase mb-5 text-center'>Get access to PRE ORDER privileges:</h3>
                    <div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <p>It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                    </div>
                    <div className='text-center py-4'>
                        <Link to="/" className='btn-g bb py-2 px-3 text-uppercase'>PRE ORDER NOW</Link>
                    </div>
                </div>
                <div className='col-lg-10 offset-lg-1 mb-4'>
                    <CollapseComponent/>
                </div>
                <div className='text-center py-4'>
                    <img className='logo-soon mb-2 mb-lg-5' src="img/logo-shio-2-big.png"/>
                </div>
            </div>
        </div>
    </Fragment>
  )
}
