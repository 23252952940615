import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../../../context/apiContext";

export function NavpoliticasComponent(){
    const { getTextByKey } = useApi();

    useEffect(() => {
    window.scroll(0, 0)
    }, [])
    

    return(
        <ul className="NavPoliticas mb-4 mb-md-0">
            <li><Link to="/politicas-de-compras" className="pb-1 pb-md-2 text-uppercase d-inline-block">{getTextByKey("purchase-policy")}</Link></li>
            <li><Link to="/aviso-de-privacidad" className="pb-1 pb-md-2 text-uppercase d-inline-block">{getTextByKey("privacy-notice")}</Link></li>
            <li><Link to="/politica-de-datos" className="pb-1 pb-md-2 text-uppercase d-inline-block">{getTextByKey("data-policy")}</Link></li>
            <li><Link to="/politica-de-cambios-devoluciones" className="pb-1 pb-md-2 text-uppercase d-inline-block">{getTextByKey("exchange-policy")}</Link></li>
        </ul>
    );
}