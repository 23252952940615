import sellyApi from './sellyApi'
import Product from '../classes/product'
import { getEnvVariables } from '../helpers'
import { useState } from 'react';

export const images_products_route = getEnvVariables().IMAGES_URL_PRODUCTS;
export const images_collections_route = getEnvVariables().IMAGES_URL_COLLECTIONS;
export const images_categories_route = getEnvVariables().IMAGES_URL_CATEGORIES;

export const getRecommendedHome = async () => {
  try {
    const { data } = await sellyApi.get(`/product/recomendados_home`).catch((error) => []);
    return data;
  } catch (error) {
    return error;
  }
}

export const misFavoriteUser = async (uidUser) => {
  try {
    const { data } = await sellyApi.get(`/product/favoritos?usuario=${uidUser}`);
    return data;
  } catch (error) {
    console.log("Error listando dirección", error);
    return [];
  }
};

export const toggleFav = async (user, product) => {
  try {
    const { data } = await sellyApi.post(`/product/tooglefavorito`, JSON.stringify({
      "usuario": user,
      "producto": product,
    }));

    return data;
  } catch (error) {
    return error;
  }
};

export const favoritouser = async (uidUser, productId) => {
  try {
    const { data } = await sellyApi.get(`/product/favoritouser?usuario=${uidUser}&producto=${productId}`);
    return data;
  } catch (error) {
    return [];
  }
};

export const getNewCollections = async () => {
  try {
    const { data } = await sellyApi.get(`/collection/colecciones_nuevas`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getProducts= async (categorias = "", activity = "", colores = "", tallas = "", linea = "") => {
  try {
    let query = "";
    if(categorias.length > 0) { query += `categorias=${categorias}` }
    if(activity.length > 0) { query += `&activity=${activity}` }
    if(colores.length > 0) { query += `&colores=${colores}` }
    if(tallas.length > 0) { query += `&tallas=${tallas}` }
    if(linea.length > 0) { query += `&lineas=${linea}` }
    const { data } = await sellyApi.get(`/product/products?${query}`).catch((error) => {
      return [];
    })
    return data;
  } catch (error) {
    return error;
  }
}

export const getProductsSearch= async (query = "") => {
  try {
    query = encodeURIComponent(query);
    const { data } = await sellyApi.get(`/product/products?search=${query}`).catch((error) => {
      return [];
    })
    return data;
  } catch (error) {
    return error;
  }
}

export const getProductBestSellers= async () => {
  try {
    const { data } = await sellyApi.get(`product/mas_vendidos`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getProductDetail = async (id) => {
  try {
    const { data } = await sellyApi.get(`/product/detail/${id}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getCombinationsById = async (id) => {
  try {
    const { data } = await sellyApi.get(`/combinations/${id}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getTallasById = async (id) => {
  try {
    const { data } = await sellyApi.get(`/tallas/${id}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const createUser = async (body) => {
  try {
    const { data } = await sellyApi.post(`/user/create`, body)
    return data;
  } catch (error) {
    return error;
  }
}

export const getPaises = async (lang) => {
  try {
    const { data } = await sellyApi.get(`/user/paises?lang=${lang}`)
    return data;
  } catch (error) {
    return error;
  }
}

//Tarjetas
export const getTarjetas = async (uid) => {
  try {
    const { data } = await sellyApi.get(`/card?uid=${uid}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const createTarjeta = async (uid,body) => {
  try {
    const { data } = await sellyApi.post(`/card/create?uid=${uid}`, body)
    return data;
  } catch (error) {
    return error;
  }
}

export const deleteTarjeta = async (id) => {
  try {
    const { data } = await sellyApi.delete(`/card/${id}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const validateCoupon = async (coupon) => {
  try {
    const { data } = await sellyApi.get(`/cart/discount/${coupon}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const createOrder = async (json) => {
  try {
    //change baseurl sellyapi
    // sellyApi.defaults.baseURL = getEnvVariables().API_URL_TEST;
    const { data } = await sellyApi.post(`cart/general/create`, json);
    return data;
  } catch (error) {
    return error;
  }
}

export const pagarOrderWompi = async (json) => {
  try {
    const { data } = await sellyApi.post(`card/pagar`, json);
    return data;
  } catch (error) {
    return error;
  }
}

export const getBanks = async () => {
  try {
    const { data } = await sellyApi.get(`pagos/bancos`).catch((error) => {
      return [];
    })
    return data;
  } catch (error) {
    return error;
  }
}

export const pagarOrderPSE = async (json) => {
  try {
    // sellyApi.defaults.baseURL = getEnvVariables().API_URL_TEST;
    const { data } = await sellyApi.post(`pagos`, json);
    return data;
  } catch (error) {
    return error;
  }
}

export const getOrderPSE = async (id) => {
  try {
    const { data } = await sellyApi.get(`pagos/buyStatus?transaction_id=${id}`);
    return data;
  } catch (error) {
    return error;
  }
}

//paypal/approved
export const paypalApproved = async (json) => {
  try {
    const { data } = await sellyApi.post(`paypal/approved`, json);
    return data;
  } catch (error) {
    return error;
  }
}


export default {
  getRecommendedHome,
  getNewCollections,
  getProducts,
  getProductBestSellers,
  getProductDetail,
  getCombinationsById,
  getTallasById,
  createUser,
  getPaises,
  getTarjetas,
  createTarjeta,
  deleteTarjeta,
  toggleFav,
  validateCoupon,
  images_products_route,
  paypalApproved
}
