import React, {Component, Fragment} from 'react';
import '../../../scss/nav_perfil.scss'

import { Icon } from '@iconify/react';
import { NavLink } from 'react-router-dom';

export class NavsupComponent extends Component{
    render(){
      return(
        <Fragment>
            <ul className="nav-perfil-sup px-0 py-lg-4 m-0 text-center d-flex justify-content-center align-items-center">
                <li><NavLink to="/profile-details">PERFIL</NavLink></li>
                <li><NavLink to="/profile-orders">PEDIDOS</NavLink></li>
                <li><NavLink to="/profile-favs">FAVORITOS</NavLink></li>
            </ul>
        </Fragment>
      )
    }
  }