import { Fragment } from "react";
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";
import { useApi } from "../../context/apiContext";
import { images_products_route } from "../../api/productApi";
import { getGallery } from "../../api/galleryApi";

import React, { useState, useEffect } from 'react'

export default function AcercaPage() {

  const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
  const FadeUp = batch(Fade(), Move(), Sticky());
  const { getTextByKey, getPropiertyByLocale } = useApi();
  const [bannersMobile, setBannersMobile] = useState()

  const [imagenMobile, setImagenMobile] = useState()

  const [content, setContent] = useState([]);

  useEffect(() => {
    getGallery("about-shido-content").then((data) => {
      setContent(data);
      const objAux = {}
      data.forEach((img) => {
        if (img.link != null) {
          console.log(img.link)
          objAux[img.link] = img.image;
          setBannersMobile(objAux)
        }
      })
    });

    console.log(bannersMobile);
  }, []);

  return (
    <Fragment>
      <div className="banner position-relative mb-2 mb-md-5" animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
        {
          content.length > 0 && (
            <>
              <img className="w-100 d-none d-lg-block" src={images_products_route + content[0].image + '?format=webp'} alt={"SHIDO"} />
              <img className="w-100 d-block d-lg-none" src={images_products_route + bannersMobile['banner1_about_mobile'] + '?format=webp'} alt={"SHIDO"} />
            </>
          )
        }
        {/* <div className="content-banner position-absolute">
              <h2 className="secundary-color mb-0 text-center text-uppercase">{getTextByKey("about-page-title")}</h2>
            </div> */}
      </div>

      <div className="conainer mb-5">
        <div className="row">
          <div className="col-12 col-lg-4">
            {content.length > 0 && <img className="w-100 my-4" src={images_products_route + content[1].image + '?format=webp'} alt={"SHIDO"} />}
          </div>
          <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center">
            <div className="p-4 text-center">
              <h3 className="quaternary-color">{content.length > 1 && getPropiertyByLocale(content[1].names, "titulo")}</h3>
              {content.length > 1 && <p dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(content[1].names, "descripcion") }}></p>}
            </div>
          </div>
          <div className="col-12 col-lg-4">
            {content.length > 0 && <img className="w-100 my-4" src={images_products_route + content[2].image + '?format=webp'} alt={"SHIDO"} />}
          </div>
        </div>
      </div>

      <div className="banner position-relative mb-2 mb-md-5">
        {
          content.length > 0 && (
            <>
              <img className="w-100 d-none d-lg-block" src={images_products_route + content[3].image + '?format=webp'} alt={"SHIDO"} />
              <img className="w-100 d-block d-lg-none" src={images_products_route + bannersMobile['banner2_about_mobile'] + '?format=webp'} alt={"SHIDO"} />
            </>
          )
        }
        {/* <div className="content-banner position-absolute">
                <h2 className="secundary-color mb-0 text-center text-uppercase">{getTextByKey("about-page-second-title")}</h2>
            </div> */}
      </div>
      <div className="container">
        <div className="text-center mb-5 px-5">
          <h3 className="quaternary-color text-uppercase">{content.length > 1 && getPropiertyByLocale(content[3].names, "titulo")}</h3>
          {content.length > 1 && <p dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(content[3].names, "descripcion") }}></p>}
        </div>
      </div>

      <div className="container">
        <div className="row">

          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="p-4 text-center">
              <h3 className="quaternary-color mb-4 text-uppercase">{content.length > 1 && getPropiertyByLocale(content[4].names, "titulo")}</h3>
              {content.length > 1 && <p dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(content[4].names, "descripcion") }}></p>}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            {content.length > 0 && <img className="w-100 my-4" src={images_products_route + content[4].image + '?format=webp'} alt={"SHIDO"} />}
          </div>

          <div className="col-12 col-lg-6">
            {
              content.length > 0 && (
                <>
                  <img className="w-100 d-none d-md-block my-4" src={images_products_route + content[5].image + '?format=webp'} alt={"SHIDO"} />
                  <img className='w-100 d-block d-md-none my-4' src={images_products_route + bannersMobile['image4_about_mobile'] + '?format=webp'} />
                </>
              )
            }
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="p-4 text-center">
              <h3 className="quaternary-color mb-4 text-uppercase">{content.length > 1 && getPropiertyByLocale(content[5].names, "titulo")}</h3>
              {content.length > 1 && <p dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(content[5].names, "descripcion") }}></p>}
            </div>
          </div>

          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="p-4 text-center">
              <h3 className="quaternary-color mb-4 text-uppercase">{content.length > 1 && getPropiertyByLocale(content[6].names, "titulo")}</h3>
              {content.length > 1 && <p dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(content[6].names, "descripcion") }}></p>}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            {content.length > 0 && <img className="w-100 my-4" src={images_products_route + content[6].image + '?format=webp'} alt={"SHIDO"} />}
          </div>

          <div className="col-12 col-lg-6">
            {content.length > 0 && <img className="w-100 my-4" src={images_products_route + content[7].image + '?format=webp'} alt={"SHIDO"} />}
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="p-4 text-center">
              <h3 className="quaternary-color mb-4 text-uppercase">{content.length > 1 && getPropiertyByLocale(content[7].names, "titulo")}</h3>
              {content.length > 1 && <p dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(content[7].names, "descripcion") }}></p>}
            </div>
          </div>

        </div>
      </div>


      {/* <div className="collage py-2 py-lg-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-2 d-flex align-items-end order-2 order-lg-1">
                <div className="item p-2">
                  <img className="w-100" src="/img/collage-1.jpg"/>
                </div>
              </div>
              <div className="col-lg-2 order-3 order-lg-2">
                <div className="item p-2">
                  <img className="w-100" src="/img/collage-2.jpg"/>
                </div>
                <div className="item p-2">
                  <img className="w-100" src="/img/collage-3.jpg"/>
                </div>
              </div>
              <div className="col-lg-4 d-flex flex-wrap align-items-end order-1 order-lg-3">
                <div className="p-2 px-4">
                  <h2 className="text-center text-uppercase">Lorem Ipsum is simply dummy text of the printing and type.</h2>
                </div>
                <div className="item p-2">
                  <img className="w-100" src="/img/collage-4.jpg"/>
                </div>
              </div>
              <div className="col-lg-4 d-flex flex-wrap align-items-end order-4 order-lg-4">
                <div className="item w-50 p-2">
                  <img className="w-100" src="/img/collage-5.jpg"/>
                </div>
                <div className="item w-50 p-2">
                  <img className="w-100" src="/img/collage-7.jpg"/>
                </div>
                <div className="item p-2">
                  <img className="w-100" src="/img/collage-6.jpg"/>
                </div>
              </div>
            </div>
          </div>
        </div> */}

    </Fragment>
  )
}
