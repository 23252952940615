import React, { useState, useEffect, useContext, createContext } from 'react';
import TagManager from 'react-gtm-module';
import sellyApi from "../api/sellyApi";
import { useAuth } from '../authContext';
import { manageCurrentCart } from '../helpers/currentCartHelper';
const CartContext = createContext();

export function useCart() {
    return useContext(CartContext);
}

export function CartProvider({ children }) {

    const [currentCart, setCurrentCart] = useState([]);
    const [cartIsShowing, setCartIsShowing] = useState(false);
    const [total, setTotal] = useState(0);
    const [envio, setEnvio] = useState(0);
    const [descuento, setDescuento] = useState(0);
    const [direccion, setDireccion] = useState(null);
    const [coupon, setCoupon] = useState(0);
    const [creditBenefitType, setCreditBenefitType] = useState({
        type: "general",
        code: ""
    });
    const { currentUser } = useAuth();

    const showCart = () => {
        setCartIsShowing(true);
        const body = document.getElementsByTagName('body')[0];
        body.classList.toggle('over-hidden');
        console.log("Actual Cart",currentCart);
        var iPhone = ( navigator.userAgent.match(/(iPhone|iPod)/i) ? true : false );
        if (iPhone && window.scrollY === 0) {
            body.classList.add('carrito-height-iphone')
        }
    }

    const hideCart = () => {
        setCartIsShowing(false);
        const body = document.getElementsByTagName('body')[0];
        if( body.classList.contains('over-hidden') ) {
            body.classList.remove('over-hidden');
            console.log(body.classList);
        }
        var iPhone = ( navigator.userAgent.match(/(iPhone|iPod)/i) ? true : false );
        if (iPhone) {
            body.classList.remove('carrito-height-iphone')
        }
    }

    const addToCart = (product, quantity) => {
        
        const newCart = [...currentCart];
        const index = newCart.findIndex((item) => (item.cartPlu) === (product.cartPlu));
        if (index === -1) {
            product.quantity = parseInt(quantity);
            newCart.push(product);
        } else {
            if(product.type === 'giftcard'){
                product.quantity = parseInt(quantity);
                newCart.push(product);
            } else if (product.type === 'combination') {
                product.quantity = 1;
                newCart.push(product);
            } else {
                newCart[index].quantity += parseInt(quantity);
            }
        }
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        //Tag manager
        TagManager.dataLayer({
            dataLayer: {
              event: "add_to_cart",
                ecommerce: null
              }
        });
        TagManager.dataLayer({
            dataLayer: {
                event: "add_to_cart",
                ecommerce: {
                    items: [{
                        item_name: product.name,
                        item_id:  product.cartPlu,
                        price:  product.price,
                        quantity: quantity,
                        product_type: product.type
                    }]
                }
            }
        });
        setCurrentCart(newCart);
    };

    const removeToCart = (index) => {
        const newCart = [...currentCart];
        newCart.splice(index, 1);
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        setCurrentCart(newCart);
    }

    const updateCart = (index, product) => {
        const newCart = [...currentCart];
        newCart[index] = product;
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        setCurrentCart(newCart);
    }

    const manageUserCart = (cartData) => {
        localStorage.setItem('cart_shido_data', JSON.stringify(cartData));
        setCurrentCart(cartData);
    }

    const calculateTotal = async () => {
        let total = 0;
        const newCart = [...currentCart];
        newCart.forEach((item) => {
            if(item.type === "giftcard"){
                total += item.price * item.quantity;
            } else {
                total += item.price.precio * item.quantity;
           }         
        });
        setTotal(total);
    }

    const calculateDescuento = async () => {
        let descuento = 0;
        const newCart = [...currentCart];
        newCart.forEach((item) => {
            if(item.price.sindescuento){
                descuento += (item.price.sindescuento - item.price.precio) * item.quantity;
            }
        });
        setDescuento(descuento);
    }

    const checkIfExistInCart = (id) => {
        const newCart = [...currentCart];
        const index = newCart.findIndex((item) => item.plu === id);
        if (index === -1) {
            return false;
        } else {
            return true;
        }
    }

    const getNumberProducts = () => {
        return currentCart.length;
    }

    const clearCart = () => {
        localStorage.removeItem('cart_shido_data');
        localStorage.removeItem('cart_shido_direccion');
        setCurrentCart([]);
    }

    const updateDireccion = (direccion) => {
        localStorage.setItem('cart_shido_direccion', direccion);
        setDireccion(direccion);
    }

    const updateCoupon = (coupon) => {
        setCoupon(coupon);
    }

    const getEnvioPrice = async () => {
        try {
            const { data } = await sellyApi.get(`/store/get-setting?llave=costo_envio&formato=JSON`);
            return data;
        } catch (error) {
            return error;
        }
    };

    const updateBenefitType = ({type, code}) => {
        setCreditBenefitType({type, code});
    };

    useEffect(() => {
        localStorage.getItem('cart_shido_data') && setCurrentCart(JSON.parse(localStorage.getItem('cart_shido_data')));
        localStorage.getItem('cart_shido_direccion') && setDireccion(localStorage.getItem('cart_shido_direccion'));
        getEnvioPrice().then((data) => {
            currentCart.map(({type}) => {
                if(type === 'product' || type === 'combination'){
                    setEnvio(data);
                }
            })
          });
    }, [])

    useEffect(() => {
        currentCart.map(({type}) => {
            if(type === 'product' || type === 'combination'){
                getEnvioPrice().then((data) => {
                    setEnvio(data);
                });
            } else {
                setEnvio(0);
            }
        })

        if(currentUser && !currentUser.isAnonymous){
            let cartData = {
                cartData: currentCart,
                uid: currentUser.uid
            }
            manageCurrentCart(cartData)
        }

     }, [currentCart]);

    const value = {
        currentCart,
        manageUserCart,
        addToCart,
        removeToCart,
        updateCart,
        calculateTotal,
        getNumberProducts,
        clearCart,
        cartIsShowing,
        showCart,
        hideCart,
        total,
        envio,
        descuento,
        direccion,
        updateDireccion,
        coupon,
        updateCoupon,
        checkIfExistInCart,
        calculateDescuento,
        updateBenefitType,
        creditBenefitType
    }

    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    )
}