import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from "../../../context/apiContext";
import { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

export default function CheckDropdownComponent(props) {

    const { getTextByKey, currentCategories, currentSizes, currentColors, currentLines, currentActivities } = useApi();
    const [filters, setFilters] = React.useState([]);
    const params = useParams();

    const location = useLocation();

    var filtersorder = [
        {
            id: 1,
            nameFilter: 'ordenar',
            list: [
                // {'option': 'Más vendidos'},
                // {'option': 'Nuevos'},
                { 'option': 'Precio: menor a mayor', key: 'price_asc' },
                { 'option': 'Precio: mayor a menor', key: 'price_desc' },
            ]
        }
    ]

    const onChangeRadio = (e) => { }

    const generateQueryLink = (id, key) => {
        let query = "";
        switch (key) {
            case "categoria":
                query = `/${id}/${params.actividad | 0}/${params.colores | 0}/${params.talla | 0}/${params.linea | 0}/normal`;
                break;
            case "actividad":
                query = `/${params.categoria | 0}/${id}/${params.colores | 0}/${params.talla | 0}/${params.linea | 0}/normal`;
                break;
            case "colores":
                query = `/${params.categoria | 0}/${params.actividad | 0}/${id}/${params.talla | 0}/${params.linea | 0}/normal`;
                break;
            case "talla":
                query = `/${params.categoria | 0}/${params.actividad | 0}/${params.colores | 0}/${id}/${params.linea | 0}/normal`;
                break;
            case "linea":
                query = `/${params.categoria | 0}/${params.actividad | 0}/${params.colores | 0}/${params.talla | 0}/${id}/normal`;
                break;
            case "price_asc":
                query = `/${params.categoria | 0}/${params.actividad | 0}/${params.colores | 0}/${params.talla | 0}/${params.linea | 0}/price_asc`;
                break;
            case "price_desc":
                query = `/${params.categoria | 0}/${params.actividad | 0}/${params.colores | 0}/${params.talla | 0}/${params.linea | 0}/price_desc`;
                break;
            default:
                break;
        }
        return query;
    }

    useEffect(() => {
        console.log("Parameters", currentColors);
        let getFilters = [];
        if (currentCategories) {
            let newFilters = {
                id: 1,
                key: "categoria",
                nameFilter: "Categoría",
                list: []
            };
            currentCategories.forEach((category) => {
                newFilters.list.push({
                    id: category.id,
                    name: category.names[0].nombre,
                });
            });
            getFilters.push(newFilters);
        }
        // if (currentActivities) {
        //     let newFilters = {
        //         id: 2,
        //         key: "actividad",
        //         nameFilter: "Actividad",
        //         list: []
        //     };
        //     currentActivities.forEach((actividad) => {
        //         newFilters.list.push({
        //             id: actividad.id,
        //             name: actividad.name[0].nombre,
        //         });
        //     });
        //     getFilters.push(newFilters);
        // }
        if (currentColors) {
            let newFilters = {
                id: 3,
                key: "colores",
                nameFilter: "Color",
                list: []
            };
            currentColors.forEach((color) => {
                newFilters.list.push({
                    id: color.id,
                    name: color.nombre,
                });
            });
            getFilters.push(newFilters);
        }
        if (currentSizes) {
            let newFilters = {
                id: 4,
                key: "talla",
                nameFilter: "Talla",
                list: []
            };
            currentSizes.forEach((size) => {
                newFilters.list.push({
                    id: size.id,
                    name: size.nombre,
                });
            });
            getFilters.push(newFilters);
        }
        if (currentLines) {
            let newFilters = {
                id: 5,
                key: "linea",
                nameFilter: "Linea",
                list: []
            };
            currentLines.forEach((line) => {
                newFilters.list.push({
                    id: line.id,
                    name: line.name[0].nombre,
                });
            });
            getFilters.push(newFilters);
        }
        setFilters(getFilters);
    }, [currentCategories, currentSizes, currentColors, currentLines, currentActivities]);

    return (
        <Fragment>
            <div className='col-xl-6 px-0 d-xl-flex justify-content-start align-items-center'>
                <div className="form-group d-xl-flex align-items-center mb-3 mb-xl-0 me-xl-2">
                    {location.pathname != "/offers" && (
                        <Link to='/collection' className='item-filtro-link text-uppercase'>
                            {getTextByKey("ofertas")}
                        </Link>
                    )}
                </div>
                {
                    filters.map((filter, index) => {
                        return (
                            <div className='form-group mb-3 mb-xl-0 me-xl-2' key={index}>
                                <div className="dropdown item-filtro" key={filter.id}>
                                    <a className="dropdown-toggle text-uppercase" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>{filter.nameFilter}</span>
                                        <Icon icon="bi:chevron-down" />
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        {filter.list.map((data, indexfilter) => {
                                            return (
                                                <Link to={`/shop${generateQueryLink(data.id, filter.key)}`} key={indexfilter}>
                                                    <li>
                                                        <label className="container-check-s">
                                                            <small>{data.name}</small>
                                                            <input type="radio" name={filter.nameFilter} checked={params[filter.key] == data.id} onChange={onChangeRadio} />
                                                            <span className="checkmark-s"><img src="/img/SVG/check.svg" /></span>
                                                        </label>
                                                    </li>
                                                </Link>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className='col-xl-6 px-0 d-xl-flex justify-content-end align-items-center'>
                {
                    filtersorder && filtersorder.map((filter, index) => {
                        return (
                            <div className='form-group mb-3 mb-xl-0 me-xl-2' key={index}>
                                <div className="dropdown item-filtro" key={filter.id}>
                                    <a className="dropdown-toggle text-uppercase" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>{filter.nameFilter}</span>
                                        <Icon icon="bi:chevron-down" />
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        {filter.list.map((data, indexfilter) => {
                                            return (
                                                <li key={indexfilter}>
                                                    <label>
                                                        <Link to={`/shop${generateQueryLink(false, data.key)}`}>
                                                            {data.option}
                                                        </Link>
                                                    </label>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </Fragment>
    )
}
