import React, { Fragment } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useApi } from '../../../context/apiContext'
import ColorComponent from '../../ProductPage/components/ColorComponent'
import TallaComponent from '../../ProductPage/components/TallaComponent'

export default function ProductocombinacionComponent({ producto, url, addProductHandle, selected, imagenDeCombinacion }) {

    const { getPropiertyByLocale } = useApi();

    const [productData, setProductData] = React.useState(false);

    const [info, setInfo] = React.useState(false);

    const [talla, setTalla] = React.useState(false);

    const handleTalla = (talla) => {
        setProductData({
            ...productData,
            talla: talla,
            size: parseInt(talla.id)
        });
        setTalla(talla);
        if(!selected) setInfo("Selecciona un color para continuar");
    }

    const handleColor = (color) => {
        setProductData({
            ...productData,
            color: parseInt(color)
        });
        addProduct();
    }

    const addProduct = () => {
        console.log("Product to add",productData);
        setInfo(false);
        addProductHandle(productData);
    }

    useEffect(() => {
        setProductData(producto);
        if(!selected) setInfo("Selecciona una talla para continuar"); setTalla(false); 
        if(selected) setInfo(false); setTalla(selected.talla);
    }, [producto, selected]);

    return (
        <Fragment>
            <div className='combinacion-item position-relative'>
                <div className={selected ? 'mb-2 selected' : 'mb-2'}>
                    <picture className='position-relative'>
                        <Link to={ `/product/${producto.plu}` }>
                            {producto.images.length > 0 && <img className='w-100' src={url + ( imagenDeCombinacion != null ? imagenDeCombinacion.image : producto.images[0].image )} />}
                        </Link>
                    </picture>
                </div>
                <h4 className='gotham-Bold text-uppercase mb-3'>{getPropiertyByLocale(producto.name, "nombre")}</h4>
                <h4 className='text-uppercase'>Talla</h4>
                <div className='py-2 mb-3'>
                    <form>
                        <TallaComponent producto={producto} handleTalla={handleTalla} />
                    </form>
                </div>
                {talla && (
                    <>
                        <h4 className='text-uppercase'>Color</h4>
                        <div className='py-2'>
                            <form>
                                <ColorComponent producto={producto} handleColor={handleColor} />
                            </form>
                        </div>
                    </>
                )}
            </div>
        </Fragment>
    )
}
