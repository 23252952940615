import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getGallery } from "../../api/galleryApi";
import { images_products_route } from "../../api/productApi";
import '../../scss/tarjeta_regalo.scss'

export default function TarjetaaPage() {

    const [gallery, setGallery] = useState([]);

    useEffect(() => {
        getGallery('banner-tarjeta-regalo')
            .then((response) => {
                setGallery(response);
            })
    }, [])
    

  return (
    <Fragment>
        <div className="banner tarjeta-regalo position-relative">
            {gallery.length > 0 && <img className="w-100 d-none d-md-block" src={images_products_route+gallery[0].image+'?format=webp'} alt={"Banner"} />}
            {gallery.length > 0 && <img className="w-100 d-block d-md-none" src={images_products_route+gallery[1].image+'?format=webp'} alt={"Banner"} />}
            <div className="content-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="pb-2 pb-lg-5">
                                <h2 className="text-uppercase fw-bold">TARJETA DE REGALO</h2>
                            </div>
                            <div className="pb-2 pb-lg-4 mb-1 mb-md-0">
                                <Link to="/tarjeta-b" className="btn-g bb py-2 px-4 text-uppercase border-0">compra una tarjeta de regalo virtual</Link>
                            </div>
                            <div className="pb-lg-4 mb-3 mb-md-0">
                                <Link to="/tarjeta-c" className="btn-g bb py-2 px-4 text-uppercase border-0">revisa tu saldo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </Fragment>
  )
}
