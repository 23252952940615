import React, { Component } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { validateCoupon } from '../api/productApi';
import { useApi } from "../context/apiContext";
import { useCart } from '../context/cartContext';
import { useForm } from '../hooks/useForm';

const couponFieldsForm = {
  code: ""
}

export function BolsaComponent() {
  const { getTextByKey, convertCurrency } = useApi();
  const { calculateDescuento, total, envio, descuento, coupon, updateCoupon, updateBenefitType } = useCart();
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [couponMsg, setCouponMsg] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const {
    onInputChange: onCouponInputChange,
    formState,
    resetForm
  } = useForm(couponFieldsForm);
  const [appliedCoupon, setAppliedCoupon] = useState(false)

  useEffect(() => {
    calculateDescuento();
  }, []);

  const { code } = formState;

  const deleteCodeCoupon = (event) => {
    event.preventDefault();
    setAppliedCoupon(false)
    setDisableButton(false);
    updateCoupon(0);
    updateBenefitType({ type: 'general', code: '' })
    resetForm();
  }

  const onApplyCouponCode = (event) => {
    event.preventDefault();
    setLoadingCoupon(true);
    setCouponMsg("");
    setDisableButton(true);

    validateCoupon(formState.code).then((res) => {
      setLoadingCoupon(false);
      setAppliedCoupon(true)

      if (res.response?.status === 404 || res.descuento <= 0) {
        setCouponMsg(getTextByKey('beneficio-no-valido'));
        setAppliedCoupon(false)
        setDisableButton(false);
      }

      if (res.creditBenefitType === 'couponCode' || res.creditBenefitType === 'giftCard' ) {
        updateBenefitType({ type: res.creditBenefitType, code: formState.code })
        updateCoupon({ coupon: formState.code, descuento: res.descuento });
      }

    });
  }

  return (
    <div>
      <div className='secundary-bg-color py-3'>
        <h2 className='text-center text-uppercase'>{getTextByKey('bag-title')}</h2>
      </div>
      <div className="bolsa-checkout p-4 mb-3 mb-md-5">

        <div className="row">
          <table className='w-100'>
            <tbody>
              <tr>
                <td className='text-uppercase'>{getTextByKey('price')}</td>
                <td className='text-end'>{convertCurrency(total + descuento)}</td>
              </tr>
              {
              descuento > 0 && 
              <tr>
                <td className='text-uppercase'>{getTextByKey('sale')}</td>
                <td className='text-end'>-{convertCurrency(descuento)}</td>
              </tr>
              }
              <tr>
                <td className='text-uppercase'>{getTextByKey('shipping')}</td>
                <td className='text-end'>{convertCurrency(envio)}</td>
              </tr>
              {appliedCoupon && (
                <tr>
                  <td className='text-uppercase'>{getTextByKey('cupon-text')}</td>
                  <td className='text-end'>-{convertCurrency(coupon.descuento)}</td>
                </tr>
              )}
              <tr>
                <td><h4 className='py-2 fw-bold text-uppercase'>TOTAL</h4></td>
                <td className='text-end'><h4 className='py-2'>{convertCurrency((total + envio) - (coupon ? coupon.descuento : 0))}</h4></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <form onSubmit={onApplyCouponCode} className="mb-5 mb-md-0">
        <div className="form-group mb-2 position-relative">
          <input
            type="text"
            className="p-2 input-coupon"
            name="code"
            placeholder={getTextByKey('enter-cupon')}
            onChange={onCouponInputChange}
            disabled={disableButton}
            value={code}
          />
          {loadingCoupon ? (
            <div className="btn-g bb py-2 px-3 text-uppercase button-input-float" role="button">
              <div className='spinner-border spinner-border-sm' role='status'></div>
            </div>
          ) : (
            <>
              {appliedCoupon ? (
                <button className="btn-g bb py-2 px-3 text-uppercase button-input-float input-coupon" role="button" onClick={deleteCodeCoupon}>eliminar</button>
              ) : (
                <button className="btn-g bb py-2 px-3 text-uppercase button-input-float input-coupon" role="button" >aplicar</button>
              )}
            </>
          )}
        </div>
        {couponMsg != "" && (
          <div className='error-msg text-grey text-start bg-transparent'>*{couponMsg}</div>
        )}
      </form>

    </div>
  )
}