import { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { useApi } from "../../context/apiContext";
import { useAuth } from "../../authContext";
import { getGallery } from "../../api/galleryApi";
import { images_products_route } from "../../api/productApi";
import { getAnswersByQuestion, getQuestions } from "../../helpers/surveyHelper";

export default function FeedbackaPage() {
  const { getTextByKey, getTextBigByKey, currentLocale } = useApi();
  //Check the Auth Session
  const { currentUser } = useAuth();
  const [gallery, setGallery] = useState([])

  const [formularioEncuesta, setFormularioEncuesta] = useState([])
  const [listAnsewrs, setListAnsewrs] = useState([])

  useEffect(() => {
      const formularioEncuestaAux = formularioEncuesta;

      // Obtenemos todas las preguntas
      async function getQuestionsComponent() {
          const responseQuestions = await getQuestions(currentLocale);
          responseQuestions.forEach(async (question) => {
              if (formularioEncuesta.find((questionAux) => questionAux.questionId == question.id) == undefined) {
                if( question.visible == true ) {
                  const surveyData = {
                      questionId: question.id,
                      title: question.name[0].nombre
                  }
                  formularioEncuestaAux.push(surveyData);
                  await getAnswersByQuestionComponent(surveyData)
                }
              }
          })
          setFormularioEncuesta(formularioEncuestaAux);
          localStorage.setItem('questionsList', JSON.stringify(formularioEncuesta));
      }

      // Obtenemos las respuestas
      async function getAnswersByQuestionComponent(question) {
          const listAnsewrsAux = listAnsewrs;
          const responseAnswersByQuestion = await getAnswersByQuestion(question.questionId, currentLocale);
          responseAnswersByQuestion.forEach(answer => {
              if (listAnsewrsAux.find((answerAux) => answerAux.Id == answer.id) == undefined) {
                  const answerIdQuestion = {
                      questionId: question.questionId,
                      ...answer
                  }
                  listAnsewrsAux.push(answerIdQuestion);
              }
          })
          listAnsewrsAux.sort((a, b) => a.id - b.id)
          setListAnsewrs(listAnsewrsAux);
          localStorage.setItem('answersList', JSON.stringify(listAnsewrs));
      }
      
      getQuestionsComponent();
      console.log(listAnsewrs);

  }, [currentLocale]) 








  useEffect(() => {
    if (!currentUser || currentUser.isAnonymous) {
      //User guest redirect to login
      location.href = "/login";
    }
    getGallery('banner-feedback').then((data) => {
      setGallery( data );
    })
  }, []);
  return (
    <Fragment>
      <div className="banner screen_h_100 feedback-back position-relative w-100">
        {/* <img className="w-100 fondo" src={ gallery.length > 0 && images_products_route+gallery[0].image } /> */}
        <div className="content-banner">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 offset-lg-2">
                <div className="text-center transparent-bg-color color_imp_primary text-feedback-a" dangerouslySetInnerHTML={{ __html: getTextBigByKey("credit-page-title") }}></div>
                {/* <h2 className="text-uppercase text-center secundary-color mb-4">  
                    RECIBIRÁS UN <span className="quinary-bg-color">CUPÓN DE CRÉDITO</span> EL CUAL podrás usar 
                    en <span className="border-bottom quinary-border-color">compras en nuestra tienda.</span></h2> */}
                <div className="text-center py-4">
                  <NavLink to="/feedback-slider" className="btn-g py-2 px-4 text-uppercase border-0">{getTextByKey('btn-empezar-encuesta')}</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
