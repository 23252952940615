import React, { Fragment } from 'react'
import { Icon } from '@iconify/react'

export default function CollapseComponent() {

    var collapseItems = [
        {
            id: 'collapse'+1,
            href: '#collapse'+1,
            title: 'TÉRMINOS Y CONDICIONES',
            text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
        },
        {
            id: 'collapse'+2,
            href: '#collapse'+2,
            title: 'POLÍTICA DE TRATAMIENTO DE DATOS',
            text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
        },
        {
            id: 'collapse'+3,
            href: '#collapse'+3,
            title: 'NEED HEELP? CONTACT FOR COLOMBIA',
            text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
        },
        {
            id: 'collapse'+4,
            href: '#collapse'+4,
            title: 'POLÍTICA DE CAMBIOS Y DEVOLUCIONES',
            text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
        }
    ]

    return (
        <Fragment>
            {
                collapseItems && collapseItems.map( item =>{
                    return(
                        <div className="item-collapse border-bottom primary-border-color mb-2" key={ item.id }>
                            <a className="d-flex justify-content-between align-items-center py-3 gotham-Bold" data-bs-toggle="collapse" href={ item.href } role="button" aria-expanded="false" aria-controls="collapseExample">
                                <span className='ps-2 ps-md-3 ps-lg-5'>{ item.title }</span>
                                <Icon className='primary-color' icon="akar-icons:chevron-down" />
                            </a>
                            {/* <button className="d-flex justify-content-between align-items-center py-3 gotham-Bold border-0" type="button" data-bs-toggle="collapse" data-bs-target={ item.href } aria-expanded="false" aria-controls="collapseExample">
                                <span className='ps-2 ps-md-3 ps-lg-5'>{ item.title }</span>
                                <Icon className='primary-color' icon="akar-icons:chevron-down" />
                            </button> */}
                            <div className="collapse" id={ item.id }>
                                <div className="ps-2 ps-md-3 ps-lg-5 py-3">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software</p>
                                </div>
                            </div>
                        </div>
                    )
                } )
            }
        </Fragment>
    )
}
