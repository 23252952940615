import React, { Fragment } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { getOrderPSE } from '../../api/productApi'
import RecomendadosSliderComponent from '../../components/RecomendadosSliderComponent'
import { useApi } from '../../context/apiContext'

export default function PagoExitosoPage() {

  const [order, setOrder] = React.useState(0)
  const [msgOrder, setMsgOrder] = React.useState('Pago Pendiente');
  const [loading, setLoading] = React.useState(false);
  const { getTextByKey, currentLocale } = useApi();
  const [approved, setApproved] = React.useState(false);

  const location = useLocation();

  React.useEffect(() => {
    // if (location.state) {
    //   setOrder(location.state.order);
    //   const textoMsgOrder = location.state.msg.toLowerCase();
    //   setMsgOrder( textoMsgOrder.indexOf('approved') >= 0 ? 'Compra Aprobada' : 'Compra Pendiente');
    // }
    console.log("location",location);
    if(location.state){
      setLoading(true);
      const id = location.state.order;
      console.log("id-order",id);
      if(id == 0){
        setOrder(getTextByKey('compra-rechazada'));
        setMsgOrder( getTextByKey('compra-rechazada') );
        setApproved(false);
      } else {
        getOrderPSE(id).then((res) => {
          if(res.id){
            setOrder(res.statusName);
            if(res.statusName == "APROBADO"){
              setMsgOrder( getTextByKey('compra-aprobada') );
              setApproved(true);
            } else if(res.statusName == "RECHAZADA"){
              setMsgOrder( getTextByKey('compra-rechazada') );
              setApproved(false);
            } else {
              setMsgOrder( getTextByKey('compra-pendiente') );
              setApproved(true);
            }
          }
          setLoading(false);
        }).catch((err) => {
          setApproved(false);
          setOrder(getTextByKey('compra-rechazada'));
          setMsgOrder( getTextByKey('compra-rechazada') );
          setLoading(false);
        });
      }
    } else {
      setOrder(getTextByKey('compra-rechazada'));
      setMsgOrder( getTextByKey('compra-rechazada') );
      setApproved(false);
    }
  }, [location])


  const convertDateAndHourFromIso = (date) => {
    const dateObj = new Date(date);
    const dateStr = dateObj.toLocaleDateString();
    const hourStr = dateObj.toLocaleTimeString();
    return `${dateStr} ${hourStr}`;
  }

  return (
    <Fragment>
      {!loading ? (
        <div className='pt-3'>
          <div className='p-2 py-4 p-md-3 p-lg-5 secundary-bg-color mb-2 mb-lg-5 ficha-pago-exitoso text-center'>
            <h2 className='text-uppercase mb-4 mb-md-4 mb-lg-5'>{msgOrder}</h2>
            <p className='text-uppercase mb-4 mb-md-0'>{getTextByKey('fecha-compra')}: {convertDateAndHourFromIso(new Date())}</p>
            <p className='text-uppercase mb-4 mb-md-0'>{getTextByKey('numero-pedido')} {order}</p>
            {approved && <p className='text-uppercase mb-4 mb-md-4'>{getTextByKey('recibo-compra')}</p>}
            <NavLink to="/shop" className='btn-g bb px-3 py-2 text-uppercase my-3'>{getTextByKey('back-to-shop')}</NavLink>
          </div>
          <div className='container text-center py-2 py-md-3 py-xl-5'>
            <h2 className='text-transform-none text-center text-md-start my-4 my-md-4 my-lg-5 recomendados-titulo'>{getTextByKey('recomendados-titulo')}</h2>
            <RecomendadosSliderComponent/>
        </div>
        </div>
      ) : (
        <div className='container text-center py-2 py-md-3 py-xl-5'>
          <h2 className='text-uppercase mb-2 mb-md-4 mb-lg-5'>
            <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
          </h2>
        </div>
      )}
    </Fragment>
  )
}
