import { Fragment } from "react";
import { ProductComponent } from "../../components/productComponent";
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";

export default function BasePage() {
    const ZoomInScrollOut = batch(Sticky(), Fade(), ZoomOut(1,2));
    const FadeUp = batch(Fade(), Move(), Sticky());
    return (
        <Fragment>
            <ScrollContainer>

                <ScrollPage page={0}>
                    <div className="banner position-relative mb-2 mb-md-5">
                        <img className="w-100" src="img/20_08_A_pip_sport_collab_1-1.jpg"/>
                        <Animator animation={batch(Fade(), Sticky(), MoveOut(-300, -500))}>
                            <h2 className="secundary-color mb-0 text-center text-uppercase" style={{ width: "90vw" }}>Lorem Ipsum is simply dummy text of the printing and type.</h2>
                        </Animator>
                    </div>
                </ScrollPage>
                
                <ScrollPage page={1}>
                    <div className="banner position-relative mb-2 mb-md-5">
                        <img className="w-100" src="img/20_08_A_pip_sport_collab_1-1.jpg"/>
                        <Animator animation={ZoomInScrollOut}>
                            <h2 className="secundary-color mb-0 text-center text-uppercase" style={{ width: "90vw" }}>Lorem Ipsum is simply dummy text of the printing and type.</h2>
                        </Animator>
                    </div>
                </ScrollPage>
                
                <ScrollPage page={2}>
                    <div className="banner position-relative mb-2 mb-md-5">
                        <img className="w-100" src="img/20_08_A_pip_sport_collab_1-1.jpg"/>
                        <Animator animation={FadeUp}>
                            <h2 className="secundary-color mb-0 text-center text-uppercase" style={{ width: "90vw" }}>Lorem Ipsum is simply dummy text of the printing and type.</h2>
                        </Animator>
                    </div>
                </ScrollPage>
            
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            {/* <h1>Titulo 1</h1> */}
                            <h2>Titulo 2</h2>
                            <h3>Titulo 3</h3>
                            <h4>Titulo 4</h4>
                            {/* <h5>Titulo 5</h5>
                            <h6>Titulo 6</h6> */}
                            <a className="py-2 px-4 alert-color"><span>Prueba svg</span><img className="" src="img/SVG/blackboard.svg"/></a>
                            <p className="big-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum elit, lacinia a enim ut, hendrerit aliquet risus. Fusce vestibulum sapien vitae ante fringilla, sed tristique erat tempor. Suspendisse vehicula congue augue, eu fringilla neque pellentesque vitae. Maecenas elementum felis ac erat ultricies facilisis. Nulla tellus purus, interdum non laoreet ut, gravida eget turpis. Nulla ac dapibus orci. Nulla dictum nunc eu augue blandit facilisis. Donec suscipit velit mi, eget faucibus nulla finibus quis. In eu finibus ligula, vitae pellentesque arcu. Nullam vitae odio non risus aliquam convallis ac consectetur quam. Curabitur cursus nisi ut mi sollicitudin auctor. Ut suscipit metus ut accumsan finibus. Duis non leo eu nisl malesuada malesuada. Nulla nec vulputate lacus, at facilisis urna. Praesent fermentum interdum justo non imperdiet.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum elit, lacinia a enim ut, hendrerit aliquet risus. Fusce vestibulum sapien vitae ante fringilla, sed tristique erat tempor. Suspendisse vehicula congue augue, eu fringilla neque pellentesque vitae. Maecenas elementum felis ac erat ultricies facilisis. Nulla tellus purus, interdum non laoreet ut, gravida eget turpis. Nulla ac dapibus orci. Nulla dictum nunc eu augue blandit facilisis. Donec suscipit velit mi, eget faucibus nulla finibus quis. In eu finibus ligula, vitae pellentesque arcu. Nullam vitae odio non risus aliquam convallis ac consectetur quam. Curabitur cursus nisi ut mi sollicitudin auctor. Ut suscipit metus ut accumsan finibus. Duis non leo eu nisl malesuada malesuada. Nulla nec vulputate lacus, at facilisis urna. Praesent fermentum interdum justo non imperdiet.</p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="text-center py-4">
                                <a className="btn-g py-2 px-3">Comprar ahora</a>
                            </div>
                            <div className="text-center py-4">
                                <a className="btn-g py-2 px-3">Comprar ahora <img className='ico' src='/img/SVG/more.svg'/></a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <ProductComponent/>
                        </div>
                        <div className="col-12 col-lg-4">
                            <ProductComponent/>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="p-2 primary-bg-color">
                                <p className="white-color">primary-bg-color</p>
                            </div>
                            <div className="p-2 secundary-bg-color">
                                <p>secundary-bg-color</p>
                            </div>
                            <div className="p-2 tertiary-bg-color">
                                <p className="white-color">tertiary-bg-color</p>
                            </div>
                            <div className="p-2 quaternary-bg-color">
                                <p className="white-color">quaternary-bg-color</p>
                            </div>
                            <div className="p-2 quinary-bg-color">
                                <p className="white-color">quinary-bg-color</p>
                            </div>
                            <div className="p-2 alert-bg-color">
                                <p className="white-color">alert-bg-color</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <form>
                            <div className="form-group mb-2">
                                    <label className="mb-1">Nombre</label>
                                    <input type="text" className="p-2"/>
                                </div>
                                <div className="form-group mb-2">
                                    <label className="mb-1">Telefono</label>
                                    <input type="number" className="p-2"/>
                                </div>
                                <div className="form-group mb-2">
                                    <label className="mb-1">Password</label>
                                    <input type="password" className="p-2"/>
                                </div>
                                <div className="form-group mb-2">
                                    <label className="mb-1">E-mail</label>
                                    <input type="email" className="p-2"/>
                                </div>
                                <div className="form-group mb-2">
                                    <label className="mb-1">Lorem ipsum</label>
                                    <select className="p-2">
                                        <option>option 1</option>
                                        <option>option 1</option>
                                        <option>option 1</option>
                                        <option>option 1</option>
                                    </select>
                                </div>
                                
                            </form>
                        </div>
                        <div className="col-12 col-lg-4">
                            <form>
                                <div className="form-group mb-2">
                                    <label className="mb-1">Lorem ipsum</label>
                                    <textarea className="p-2"></textarea>
                                </div>
                                <div className="form-group mb-2">
                                    <label className="mb-1">Fecha</label>
                                    <input className="p-2" type="date"/>
                                </div>
                                <div className="form-group mb-2">
                                    <label className="mb-1">Telefono</label>
                                    <input className="p-2" type="tel"/>
                                </div>
                                <div className="form-group mb-2">
                                    <label className="mb-1">SUSCRIBIRME AL NEWSLETTER <input className="p-2" type="checkbox"/></label>
                                </div>
                                <div className="form-group text-center">
                                    <button className="btn-g py-2 px-4">Enviar</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-lg-4">
                            <form>
                                <div className="form-group mb-2">
                                    <label className="mb-1">Cantidad</label>
                                </div>
                                <div className="form-group mb-2">
                                    <label className="mb-1">Hora</label>
                                    <input className="p-2" type="time"/>
                                </div>
                                <div className="form-group text-center">
                                    <button className="btn-g py-2 px-4">Guardar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
            </ScrollContainer>
        </Fragment>
    )
}
