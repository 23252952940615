import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { useApi } from '../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export default function ModalLogUserComponent({ showModal, setShowModal }) {
    /* useEffect(() => {
        const timer = setTimeout(() => {
            console.log('This will run after 1 second!')
        }, 1000);
        return () => clearTimeout(timer);
    }, []); */

    const { getTextByKey } = useApi();

    const gotoTop = event => {
        window.scroll(0, 0);
    };

    const handleClose = () => {
        setShowModal(false);
        gotoTop();
        sessionStorage.setItem('path-to-favorite', location.pathname)
    }

    return (
        // <Fragment>
        // </Fragment>
        <Modal show={showModal} onHide={handleClose} className='ModalObligatorio'>
            <Modal.Body>
                <div className='content mb-5 d-flex flex-column justify-content-center align-items-center'>
                    <p className='text-uppercase text-center my-5'>{getTextByKey('required-user')}</p>
                    <div className="d-flex justify-content-center align-items-center py-2">
                        <Link to="/login" className="btn-g py-2 px-3 text-uppercase me-2" onClick={handleClose}>{getTextByKey('register')}</Link>
                        <a className="btn-g py-2 px-3 text-uppercase" onClick={handleClose}>{getTextByKey("btn-close-modal")}</a>
                    </div>
                </div>
            </Modal.Body>
        </Modal>      
    )
}
