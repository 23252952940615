import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../../scss/compra.scss'
import { useApi } from '../../context/apiContext'
import { getGallery } from '../../api/galleryApi'
import { images_products_route } from '../../api/productApi';

export default function CompraPage() {

  const [gallery, setGallery] = useState([])
  const [discountCode, setDiscountCode] = useState('');
  const [imagenMobile, setImagenMobile] = useState()
  

  useEffect(() => {
    getGallery('banner-final-encuesta').then((data) => {
      setGallery( data );
      data.forEach(element => {
        if( element.link == 'encuesta-image-banner-final-mobile' ) {
          setImagenMobile( element.image )
        } 
      });
    })

    if( localStorage.getItem('discountCode') ) {
      setDiscountCode( '' );
      // setDiscountCode( localStorage.getItem('discountCode') );
    }


  }, []);

  const { getTextByKey, getTextBigByKey } = useApi();
  return (
    <Fragment>
        <div className="compra position-relative compra-page">
            { gallery.length > 0 && <img className='w-100 d-none d-md-block' src={images_products_route+gallery[0].image+'?format=webp'}/> }
            { imagenMobile && <img className='w-100 d-block d-md-none' src={images_products_route+imagenMobile+'?format=webp'}/>}
            <div className='content-compra text-center'>
                { discountCode.length > 0 ? (<div className="text-center text-uppercase primary-color mb-5" dangerouslySetInnerHTML={{ __html: getTextBigByKey("message-bebefit-credit").replace('$cupon', "<b>"+discountCode+"</b>") }}></div>) : (<div className="text-center text-uppercase primary-color mb-5" dangerouslySetInnerHTML={{ __html: getTextByKey("message-bebefit-no-credit")}}></div>) }

                <Link to="/shop" className='btn-g py-2 px-4 border-0 text-uppercase'>{getTextByKey("comprar-ahora-encuesta")}</Link>
            </div>
        </div>
    </Fragment>
  )
}
