

import React, { Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from "../../authContext";
import { useEffect } from 'react';
import { useForm } from '../../hooks/useForm';
import { loginUser } from '../../helpers/userHelper';
import { useApi } from '../../context/apiContext';
import { Icon } from '@iconify/react';
import { useCart } from '../../context/cartContext';
import { getGallery } from '../../api/galleryApi';
import { images_products_route, misFavoriteUser } from '../../api/productApi';
import TagManager from 'react-gtm-module';


const loginFormFields = {
    email: "",
    password: "",
}

export default function LoginPage() {
    const { getTextByKey } = useApi();
    const { clearCart, manageUserCart } = useCart();
    const navigate = useNavigate();
    //Check the Auth Session
    const { currentUser } = useAuth();

    const [imageLogin, setImageLogin] = useState('')
    useEffect(() => {
        if (currentUser && !currentUser.isAnonymous) {
            //User is logged in redirect to home
            location.href = "/";
        }
    }, []);


    useEffect(() => {
      async function getGalleryInterna() {
        const response = await getGallery('login-background');
        setImageLogin( response[0].image );
      }

      getGalleryInterna();
    }, [])
    

    //State the error message and the loading state
    const [errors, setErrors] = useState('')
    const [loading, setloading] = useState(false)
    const [typePassword, settypePassword] = useState('password')
    const {
        onInputChange: onLoginInputChange,
        formState,
    } = useForm(loginFormFields);

    const { email, password } = formState;


    //Function to handle the login
    const onLoginSubmit = async e => {
        e.preventDefault();
        setloading(true);

        const validate = await validateForm(formState)

        //If empty errors, should login through firebase
        if (!validate) {
            setloading(true)
            setErrors()
            const login = await loginUser(formState, setErrors, setloading, manageUserCart).then(async (response) => {
                // GTM
                TagManager.dataLayer({
                    dataLayer: {
                        event: "login",
                        email: formState.email,
                    }
                });
                // GTM
                const favorites = await misFavoriteUser(currentUser.uid);
                localStorage.setItem( JSON.stringify(favorites) )
                clearCart();
            });
        }

    };

    const validateForm = async (formData) => {
        let errorFound = false;

        //Validate the email format
        if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
            errorFound = true;
        setErrors("El email no tiene un formato válido");

        //validate empty values
        for (const [key, value] of Object.entries(formData))
            //TODO make whitespaces validation
            if (value === "")
                errorFound = true;
        setErrors("Todos los campos son obligatorios");

        setloading(false)
        return errorFound;
    }

    const showPass = () => {
        if (typePassword === 'text') {
            settypePassword('password')
        } else {
            settypePassword('text')
        }
    }

    const [remember, setRemember] = useState(false);

    const onRememberInputChange = (e) => {
        setRemember(e.target.checked);
        if(e.target.checked){
            localStorage.setItem('remember_shido', email);
        }else{
            localStorage.removeItem('remember_shido');
        }
    }

    useEffect(() => {
        if(localStorage.getItem('remember_shido') != null){
            formState.email = localStorage.getItem('remember_shido');
            setRemember(true);
        } else {
            formState.email = "";
            setRemember(false);
        }
    }, [])

        

    return (
        <Fragment>
            <div className='content-user' style={{ backgroundImage: `url("${images_products_route+imageLogin+'?format=webp'}")` }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-7 col-lg-5 mx-auto p-0'>
                            <form className='user my-0 my-md-5 p-3 p-md-5' onSubmit={onLoginSubmit}>
                                <div className='form-group mb-3 mb-md-5'>
                                    <h2 className='text-uppercase text-center mb-0'>{getTextByKey('iniciar-sesion')}</h2>
                                </div>
                                {errors ? <div className='error-msg'>{errors}</div> : null}
                                <div className='form-group mb-2'>
                                    <label className='text-uppercase'>{getTextByKey('email-form')}*</label>
                                    <input type="email" name="email" className='p-1 p-md-2' style={{opacity: '0', position: "absolute", "pointerEvents": "none", left: 0, top: 0}} />
                                    <input
                                        type="email"
                                        name="email"
                                        className='p-1 p-md-2'
                                        value={email}
                                        onChange={onLoginInputChange}
                                    />
                                </div>
                                <div className='form-group mb-2 position-relative'>
                                    <label className='text-uppercase'>{getTextByKey('password-form')}*</label>
                                    <div className="w-100 position-relative">
                                        <input type="password" name="password" className='p-1 p-md-2' style={{opacity: '0', position: "absolute", "pointerEvents": "none", left: 0, top: 0}} />
                                        <input
                                            type={typePassword}
                                            name="password"
                                            className='p-1 p-md-2'
                                            value={password}
                                            onChange={onLoginInputChange}
                                        />
                                        <a className="btn-show-pass" onClick={showPass}>
                                            <Icon icon="ic:outline-remove-red-eye" />
                                        </a>
                                    </div>
                                    <div className='form-group my-2 d-flex flex-row justify-content-between align-items-start'>
                                        <label className="container-check-s">
                                            <small>{getTextByKey("recordarme-form")}</small>
                                            <input
                                                type="checkbox"
                                                name="recordarme"
                                                checked={remember}
                                                onChange={onRememberInputChange}
                                            />
                                            <span className="checkmark-s">
                                                <img src="/img/SVG/check.svg" />
                                            </span>
                                        </label>
                                        <small className="link-reset-password w-100 text-end">
                                            <Link to="/reset-password" className='simple-link text-uppercase'>{getTextByKey('forgot-password')}</Link>
                                        </small>
                                    </div>
                                </div>
                                
                                <div className='mt-3 mt-md-0'>
                                    <div className='form-group text-center mb-1 mb-md-4'>
                                        <button type="submit" className='btn-g bb py-2 px-3 text-uppercase' style={{border: 'none'}} disabled={loading}>{loading ? `${getTextByKey('autenticando')}` : `${getTextByKey('login-button')}`}</button>
                                    </div>
                                    <div className="W-100 mt-4 mb-2 my-md-3 text-center">
                                        <label className='text-uppercase link-reset-password'>{getTextByKey('help-create-account')}</label>
                                    </div>
                                    <div className='form-group text-center mb-1 mb-md-5'>
                                        <Link to="/register" className='btn-g py-2 px-3 text-uppercase'>{getTextByKey('create-account')}</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>

        </Fragment>
    )
}
