import React, { useEffect, useState } from 'react'
import { getGallery } from '../../api/galleryApi';
import { images_products_route } from '../../api/productApi';
import { useApi } from '../../context/apiContext'

export const EstrategiaPage = () => {

    const [gallery, setGallery] = useState([]);
  const [imagenMobile, setImagenMobile] = useState()


    const { getTextBigByKey, getTextByKey } = useApi();

    useEffect(() => {
        getGallery('estrategia-page').then((data) => {
            setGallery(data);

            data.forEach(element => {
                if( element.link == 'estrategia-page-banner-mobile' ) {
                  setImagenMobile( element.image )
                } 
              });
        })
    }, [])
    

    return (
        <>
            <div className="container pb-5 estrategia-page">
                <div className="row">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="py-4 py-md-5 px-md-2">
                                <h2 className="text-center text-gray text-uppercase d-none d-md-block">{getTextByKey("titulo-estrategia-page")}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mx-auto d-flex flex-column flex-md-row justify-content-between align-items-center px-0 px-md-3">
                        <div className="order-1 order-lg-0 col-12 col-lg-6 pe-0 pe-md-5 mt-4">
                            <h2 className="text-start text-gray text-uppercase d-block d-md-none mb-4 ps-3">{getTextByKey("titulo-estrategia-page")}</h2>
                            <p className='pe-0 pe-md-5 contenido-estrategia-page' dangerouslySetInnerHTML={{ __html: getTextBigByKey("contenido-estrategia-page") }}></p>
                        </div>
                        <div className="col-12 col-lg-6 order-0 order-lg-1 px-0">
                            { gallery.length > 0 && <img className='img-estrategia d-none d-md-block' src={images_products_route+gallery[0].image+'?format=webp'}/> }
                            { imagenMobile && <img className='w-100 d-block d-md-none' src={images_products_route+imagenMobile+'?format=webp'}/>}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
