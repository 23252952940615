import React, { Fragment } from 'react';
import { Icon } from '@iconify/react';
import { BolsaComponent } from '../../components/Bolsa';
import { FormularioDireccion } from '../../components/FormularioDireccion';
import StepbyStepComponent from './components/StepbyStepComponent';
import ProductsCarComponent from './components/ProductsCarComponent';
import { useApi } from "../../context/apiContext";

import '../../scss/checkout.scss'
import SelectDireccionComponent from './components/SelectDireccionComponent';
import MasvendidosSliderComponent from '../ShopPage/components/MasvendidosSliderComponent';
import { useCart } from '../../context/cartContext';
import ItemCarrito from '../../components/ItemCarrito';
import { useAuth } from '../../authContext';
import { MapComponent } from '../../components/MapComponent';
import { useState } from 'react';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const CheckoutbPage = () => {
  const { getTextByKey, getPropiertyByLocale } = useApi();
  const { currentCart } = useCart();
  const { currentUser } = useAuth();
  const [locationMarker, setLocationMarker] = useState({});
  const [addingAddress, setAddingAddress] = useState(false);
  const [ alertMsg, setAlertMsg ] = useState(false);
  const [update, setUpdate] = useState(false);
  const [addresSaveAlert, setAddresSaveAlert] = useState('')

  const updateAddress = async (added) => {
    setAddingAddress(false);
    goToScroll('address-position');
    setUpdate(added);
    setAddresSaveAlert('Tu dirección ha sido guardada.')
    if(added) setAlertMsg(true); setTimeout(() => { setAlertMsg(false); setAddresSaveAlert('') }, 4000);
  }


  const goToScroll = (id) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 1000);
  }

  useEffect (() => {
    if(currentCart){
      // GTM
      TagManager.dataLayer({
        ecommerce: null
      });
      TagManager.dataLayer({
        dataLayer: {
          event: "begin_checkout",
          ecommerce: {
            items: currentCart.map((x, i) => {
              return {
                item_name: getPropiertyByLocale(x.names, "nombre"),
                item_id: x.plu,
                price: x.price,
                color: x.color,
                talla: x.talla
              }
            })
          }
        }
      });
      // GTM
    }
  }, [currentCart])

  return (
    <Fragment>
      <StepbyStepComponent step={2}/>
      <div className="container py-5 checkout">
        <div className="row justify-content-md-center">

          <div className="col-md-8 col-lg-5">
            <h2 className='text-uppercase mb-4'>{getTextByKey('resumen-carrito-titulo')}</h2>
              <div className='content-carrito'>
                {currentCart.map((item, index) => (
                    <ItemCarrito key={index} index={index} item={item} />
                ))}
                {currentCart.length === 0 && <p className='text-center-c'>{getTextByKey('notice-cart-without-products')}</p>}
            </div>
            <div className="container d-block d-md-none px-0">
              <BolsaComponent  />
            </div>
                  
            <h2 className='text-uppercase my-4' id="address-position">{getTextByKey('direccion-envio-titulo')}</h2>

            <form className='mb-4' id="address-send">
              <SelectDireccionComponent update={update} addresSaveAlert={addresSaveAlert}/>
            </form>

            <button className='btn-g bb px-3 py-2 text-uppercase my-3 btn-new-address' onClick={()=>setAddingAddress(true)}>{getTextByKey('agregar-direccion-nueva')}</button>
            {addingAddress &&
              <div className="new-address-checkout" id="collapseForm">
                <div className="row">
                  <div className="col-12">
                    <FormularioDireccion user={currentUser} updateAddress={updateAddress} />
                  </div>
                  {/* <div className="col-12">
                    <div className="mapUbicacion">
                      <MapComponent />
                    </div>
                  </div> */}
                </div>
              </div>
            }
          </div>

          <div className="col-md-4 col-lg-5 offset-lg-1">
            <div className="container">
              <div className="w-100 d-none d-md-block">
                <BolsaComponent  />
              </div>

              <div className='py-5 text-center'>
                <h3>{getTextByKey('metodo-pago')}</h3>
                <div className="d-flex justify-content-center align-items-center flex-wrap">
                  <a className='m-md-2 m-1 icono-metodo cursor-none'><img src='/img/SVG/american.svg' /></a>
                  <a className='m-md-2 m-1 icono-metodo cursor-none'><img className='visa' src="img/visa.png" alt="logo" /></a>
                  <a className='m-md-2 m-1 icono-metodo cursor-none'><img src="img/mastercard.png" alt="logo" /></a>
                  {/* <a href="" className='m-md-2 m-1 icono-metodo cursor-none'><img src="img/nequi.png" alt="logo" /></a> */}
                  <a className='m-md-2 m-1 icono-metodo cursor-none'><img src="img/pse.png" alt="logo" /></a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default CheckoutbPage