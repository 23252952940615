import { sellyApi } from '../api'
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword
} from "firebase/auth";
import { getCurrentCart } from './currentCartHelper';


export const createUser = async (formData, setErrors, setloading) => {
  try {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, formData.email, formData.password)
      .then((data) => {
        const user = auth.currentUser;
        updateProfile(user, {
          displayName: formData.nombre + " " + formData.apellido,
          phoneNumber: formData.celular,
        }).then(async () => {

          formData.uid = data.user.uid;
          formData.providerData = JSON.stringify(data.user.providerData);

          const newUser = await sellyApi.post(`/user/create`, formData)
          setErrors()
          if (newUser?.status === 200) {
            location.href = "/";
          }
          return newUser
        }).catch((error) => {
          setloading(false)
          setErrors('Ha ocurrido un error, contacte al administrador')
        });
      }).catch((error) => {
        switch (error.code) {
          case 'auth/email-already-in-use':
            setloading(false)
            setErrors('Este correo se encuentra registrado')
            break;
        }
      })

  } catch (error) {
    setloading(false)
    setErrors('Ha ocurrido un error, contacte al administrador')
  }
}

export const createAnonymousUser = async (formData) => {
  try {
    sellyApi.post(`/user/create`, formData).then((res) => {
      //console.log('created user anonymous', res)
    })
  } catch (error) {

  }
}

export const loginUser = async (formData, setErrors, setloading, manageUserCart) => {
  
  try {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, formData.email, formData.password)
      .then(({ user }) => {

        getCurrentCart(user.uid).then(({cartData}) => {

          manageUserCart(cartData.length > 0 ? cartData : [])

          //Login success redirect to home
          setTimeout(() => {
            setloading(false);
            if( sessionStorage.getItem('something-to-tell') ) {
              const routeFeedback = sessionStorage.getItem('something-to-tell');
              sessionStorage.removeItem('something-to-tell');
              location.href = `/${routeFeedback}`;
              return;
            } else if( sessionStorage.getItem('to-checkout') ) {
              const routeFeedback = sessionStorage.getItem('to-checkout');
              sessionStorage.removeItem('to-checkout');
              location.href = `/${routeFeedback}`;
              return;
            } else if( sessionStorage.getItem('path-to-favorite') ) {
              const routePathToFavorite = sessionStorage.getItem('path-to-favorite');
              sessionStorage.removeItem('path-to-favorite');
              location.href = `${routePathToFavorite}`;  
            } else {
              location.href = "/";
            }
          }, 1000);
        })
      })
      .catch((error) => {
        //Login error show the error message
        setloading(false)
        switch (error.code) {
          case 'auth/wrong-password':
            setErrors('La contraseña es incorrecta.')
            break;
          case 'auth/user-not-found':
            setErrors('El correo no se encuentra registrado.')
            break;
          case 'auth/too-many-requests':
            setErrors('Lleva demasiados intentos para acceder, intente más tarde.')
            break;
        }
      });
  } catch (error) {
    setloading(false)
    setErrors('Ha ocurrido un error, contacte al administrador')
  }
}

export const getUser = async (uid) => {
  try {

    const { data } = await sellyApi.get(`/user?usuario=${uid}`)
    const { data: newsletter } = await sellyApi.get(`/suscribe/${data.email}`)

    return {
      user: data,
      newsletterStatus: newsletter.status
    };
  } catch (error) {
    return error;
  }
}

export const updateUser = async (inputData) => {
  try {

    console.log('data to update', inputData);
    const { data } = await sellyApi.put(`/user/update`, inputData)

    return data;
  } catch (error) {
    return error;
  }
}

export const deleteUser = async (inputData) => {
  try {

    console.log('data to delete', inputData);
    const { data } = await sellyApi.delete(`/user/${inputData.uid}`)

    return data;
  } catch (error) {
    return error;
  }
}

export default {
  createUser,
  getUser,
  updateUser,
  deleteUser,
  loginUser
}
