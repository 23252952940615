import React, { Component, useEffect, useState } from 'react';

import { createAddress } from '../api/addressApi'
import PropsTypes from 'prop-types';
import { useApi } from '../context/apiContext';
import { getPaises } from '../api/productApi';
import { getInfoMap } from '../api/mapApi';
import Select from 'react-select';
import { getUser, updateUser } from '../helpers/userHelper';
import { useForm } from '../hooks/useForm';
import { useAuth } from "../authContext";
import { getGallery } from '../api/galleryApi';

const addressFormFields = {
  pais: "",
  country_code: "",
  city: "",
  addressInput: "",
  tipo_direccion: "",
  complement: "",
  zipcode: "",
  celular: "",
  email: "",
  nombre: "",
  apellido: "",
  uid: "unset",
  desc_dir_1: "",
  desc_dir_2: "",
  desc_dir_3: "",
  colombianAddress: false,
  documentType: "unset",
  documentNumber: "unset",
  password: "unset",
  tratamiento: ""
}

export const FormularioDireccion = ({ user, updateAddress, locationMarker, addressFromMap }) => {
  const [paises, setPaises] = useState([]);
  const [disabledDescDir, setDisabledDescDir] = useState(true);
  const [errors, setErrors] = useState('')
  const [succesfull, setsuccesfull] = useState('');
  const { currentUser } = useAuth();
  const [listTratamiento, setListTratamiento] = useState([])
  const [loading, setloading] = useState(false)
  const { getTextByKey, currentLocale } = useApi();
  const [addressSearch, setAddressSearch] = useState({
    tipo_direccion: '',
    desc_dir_1: '',
    desc_dir_2: '',
    desc_dir_3: '',
    direccionLarga: '',
    city: 'Bogota',
    country: ''
  })
  const {
    onInputChange: onAddressInputChange,
    formState,
    setFormState,
    emailRegex,
    phoneRegex
  } = useForm(addressFormFields);

  const {
    nombre,
    apellido,
    pais,
    city,
    addressInput,
    celular,
    tipo_direccion,
    complement,
    email,
    zipcode,
    desc_dir_1,
    desc_dir_2,
    desc_dir_3,
    colombianAddress,
    tratamiento,
    country_code
  } = formState;

  const tipoDeDireccion = [
    {
      id: 1,
      name: 'Calle'
    },
    {
      id: 2,
      name: 'Transversal'
    },
    {
      id: 3,
      name: 'Carrera'
    },
    {
      id: 4,
      name: 'Avenida'
    },
    {
      id: 5,
      name: 'Circunvalar'
    },
    {
      id: 6,
      name: 'Diagonal'
    }
  ]

  useEffect(() => {
    setPaises([]);
    getPaises(currentLocale).then((data) => {
      if (data) {
        data?.sort((a, b) => a.nombre.localeCompare(b.nombre));
        setPaises(data);
      }
    });
  }, []);

  useEffect(() => {
    getGallery("title-form-register").then((data) => {
      const listadoAux = [];
      data.forEach(element => {
        const item = {
          id: element.id,
          name: ''
        }
        element.names.forEach(content => {
          if (content.locale === currentLocale) {
            item.name = content.titulo;
            listadoAux.push(item);
          }
        })
      });
      setListTratamiento(listadoAux);
    });
  }, [currentLocale])

  useEffect(() => {
    let addressToSearch = [];
    Object.values(addressSearch).forEach(value => {
      if (value != '') {
        addressToSearch.push(value);
      }
    })
    async function infoMap() {
      const { data: { results } } = await getInfoMap(addressToSearch.join('+'))
      addressToSearch = [];
      if (results.length > 0) {
        if (locationMarker) {
          locationMarker(results[0].geometry.location)
        }
      }
    }
    infoMap();
  }, [addressSearch])


  const handleChangeCountry = ({ id: countryId }) => {

    if (countryId == 48) {
      setFormState({
        ...formState,
        colombianAddress: true,
        pais: countryId,
        tipo_direccion: '',
        addressInput: 'unset',
        desc_dir_1: '',
        desc_dir_2: '',
        desc_dir_3: ''
      })
    } else {
      setFormState({
        ...formState,
        colombianAddress: false,
        pais: countryId,
        tipo_direccion: 'unset',
        addressInput: '',
        desc_dir_1: 'unset',
        desc_dir_2: 'unset',
        desc_dir_3: 'unset'
      })
    }

    paises.forEach(pais => {
      if (pais.id == countryId) {
        setAddressSearch({
          ...addressSearch,
          country: pais.city,
          country_code: pais.phonecode
        });
      }
    })
  }

  const handleChangeTipoDireccion = (event) => {
    setFormState({ ...formState, tipo_direccion: event.name })
    setDisabledDescDir(false);
    setAddressSearch({
      ...addressSearch,
      tipo_direccion: tipo_direccion
    });
  }

  const handleChangeDireccion = ({ target }) => {
    let { name: fieldName, value: direccionInput } = target;
    if (fieldName === "desc_dir_1" && direccionInput.length >= 0) {
      setFormState({ ...formState, desc_dir_1: direccionInput })
      setAddressSearch({
        ...addressSearch,
        desc_dir_1: ` ${direccionInput}`
      });
    }
    if (fieldName === "desc_dir_2" && direccionInput.length >= 0) {
      setFormState({ ...formState, desc_dir_2: direccionInput })
      setAddressSearch({
        ...addressSearch,
        desc_dir_2: `${direccionInput}`
      });
    }
    if (fieldName === "desc_dir_3" && direccionInput.length >= 0) {
      setFormState({ ...formState, desc_dir_3: direccionInput })
      setAddressSearch({
        ...addressSearch,
        desc_dir_3: `-${direccionInput}`
      });
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'black' : 'white',
    }),
    control: (provided) => ({
      ...provided,
    })
  };

  const onRegisterAddress = async (event) => {
    event.preventDefault();
    setloading(true)

    let { uid } = currentUser
    formState.uid = uid
    formState.password = uid
    formState.autoriza = true

    const validate = await validateForm(formState)
    //console.log('before validation form', formState)
    //If empty errors, should create an address
    if (!validate) {
      if (colombianAddress) {
        formState.addressInput = `${tipo_direccion} ${desc_dir_1} #${desc_dir_2} - ${desc_dir_3}`;
      }
      let addressFields = {
        user: uid,
        country: formState.pais,
        city: formState.city,
        address: formState.addressInput,
        complement: formState.complement,
        zipcode: formState.zipcode,
        phone: country_code+formState.celular
      }

      setloading(true)
      setErrors()
      //console.log('after validation form', formState)
      if (currentUser?.isAnonymous) {
        updateUser(formState).then(() => {
          createAddress(addressFields).then(() => {
            setsuccesfull(getTextByKey('succesfull-create-direction'))
            updateAddress();
          })
        })
      } else {
        createAddress(addressFields).then((data) => {
          setsuccesfull(getTextByKey('succesfull-create-direction'))
          updateAddress(data.id);
        })
      }

    }

  }

  const validateForm = async (formState) => {
    let errorFound = false;

    //Validate the email format
    if (
      !email?.match(emailRegex) && !currentUser
    ) {
      errorFound = true;
      setErrors("El email no tiene un formato válido");
    }

    //Validate the celular format
    if (!celular?.match(phoneRegex)) {
      errorFound = true;
      setErrors("El teléfono no tiene un formato válido");
    }

    //Validate the pais no empty
    if (pais === "0") {
      errorFound = true;
      setErrors("Debe seleccionar un país");
    }

    if( pais !== '48' ) {
      //validate empty values
      for await (const [key, value] of Object.entries(formState)) {
        // console.log('key: ', key, ' value: ', value)
        let clearValue = typeof value === 'string' && value.trim();
        if( currentUser && ( key === 'tratamiento' || key === 'email' || key === 'country_code')) {
          clearValue = 'n/a';
        }
        if (clearValue === "") {
          errorFound = true;
          setErrors(getTextByKey('error-all-fields-form'));
          setTimeout(() => {
            errorFound = false;
            setErrors();
          }, 4000);
        }
      }
    }

    setloading(false)
    return errorFound;
  }

  const guestFieldEmail = () => {
    return (<>
      <div className="form-group mb-3">
        <label className="text-uppercase">{getTextByKey("email-form")}*</label>
        <input
          type="text"
          className="p-2"
          name="email"
          value={email}
          onChange={onAddressInputChange}
        />
      </div>
    </>)
  }

  const handleChangeTratamiento = (event) => {
    setFormState({
      ...formState,
      tratamiento: event.name
    })
  }

  const guestFieldTreatment = () => {
    return (<>
      <div className="form-group mb-2">
        <label className='text-uppercase'>{getTextByKey("tratamiento-form")}*</label>
        <Select
            name='tratamiento'
            styles={customStyles}
            className='react-select-container py-2 mb-3'
            classNamePrefix="react-select"
            options={listTratamiento}
            getOptionLabel={(option) => option.name}
            getOptionValue={(value) => value}
            onChange={handleChangeTratamiento}
            placeholder={currentLocale === 'es' ? 'Seleccionar' : 'Select'}
          />
      </div>
    </>)
  }

  return (
    <div className='py-5'>
      <form action="" onSubmit={onRegisterAddress}>
        {succesfull ? <div className='succesfull-msg'>{succesfull}</div> : null}
        {currentUser?.isAnonymous && guestFieldTreatment()}
        <div className="form-group mb-3">
          <label className="text-uppercase">{getTextByKey("names-form-address")}*</label>
          <input
            type="text"
            className="p-2"
            name="nombre"
            value={nombre}
            onChange={onAddressInputChange}
          />
        </div>
        <div className="form-group mb-3">
          <label className="text-uppercase">{getTextByKey("surnames-form-address")}*</label>
          <input
            type="text"
            className="p-2"
            name="apellido"
            value={apellido}
            onChange={onAddressInputChange}
          />
        </div>
        {currentUser?.isAnonymous && guestFieldEmail()}
        <div className="form-group mb-3">
          <label className="text-uppercase">{getTextByKey("country-form-address")}*</label>
          <Select
            name='pais'
            styles={customStyles}
            className='react-select-container'
            classNamePrefix="react-select"
            options={paises}
            getOptionLabel={(option) => option.nombre}
            getOptionValue={(value) => value.id}
            onChange={handleChangeCountry}
            placeholder={currentLocale === 'es' ? 'Seleccionar' : 'Select'}
          />
        </div>
        <div className="form-group mb-3">
          <label className="text-uppercase">{getTextByKey("city-form-address")}*</label>
          <input
            type="text"
            className="p-2"
            name="city"
            placeholder='Ciudad'
            onChange={onAddressInputChange}
            value={city}
          />
        </div>
        {
          colombianAddress ?
            <><div className="form-group mb-4">
              <label className="mb-1 text-uppercase">{getTextByKey("address-type-form-address")}*</label>
                <Select
                name='tipo_direccion'
                styles={customStyles}
                className='react-select-container py-2 mb-3'
                classNamePrefix="react-select"
                options={tipoDeDireccion}
                getOptionLabel={(option) => option.name}
                getOptionValue={(value) => value}
                onChange={handleChangeTipoDireccion}
                placeholder={currentLocale === 'es' ? 'Seleccionar' : 'Select'}
              />
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="p-2"
                  name='desc_dir_1'
                  onChange={handleChangeDireccion}
                  disabled={disabledDescDir}
                  value={desc_dir_1}
                />
                <span className='mx-3'>#</span>
                <input
                  type="text"
                  className="p-2"
                  name='desc_dir_2'
                  onChange={handleChangeDireccion}
                  disabled={disabledDescDir}
                  value={desc_dir_2}
                />
                <span className='mx-3'>-</span>
                <input
                  type="text"
                  className="p-2"
                  name='desc_dir_3'
                  onChange={handleChangeDireccion}
                  disabled={disabledDescDir}
                  value={desc_dir_3}
                />
              </div>
            </div></>

            : <div className="form-group mb-4">
              <label className="mb-1 text-uppercase">{getTextByKey("address-input")}</label>
              <input
                type="text"
                className="p-2"
                name="addressInput"
                onChange={onAddressInputChange}
                value={addressInput}
              />
            </div>
        }

        <div className="form-group mb-4">
          <label className="mb-1 text-uppercase">{getTextByKey("complement-form-address")}*</label>
          <input
            type="text"
            className="p-2"
            name="complement"
            placeholder='Barrio, Apto#, Color de casa, Piso#'
            onChange={onAddressInputChange}
            value={complement}
          />
        </div>
        <div className="form-group mb-3">
          <label className="text-uppercase">{getTextByKey("zipcode-form-address")}*</label>
          <input
            type="number"
            className="p-2"
            name="zipcode"
            onChange={onAddressInputChange}
            value={zipcode}
          />
        </div>
        <div className="form-group mb-3">
          <label className="text-uppercase">{getTextByKey("phone-number-form-address")}*</label>
          <div className="d-flex overflow-hidden border-top-0 border-start-0 border-bottom-0 border">
            <select className='p-2 col-3' name="country_code" onChange={onAddressInputChange} value={pais} disabled >
              {paises.map((pais, index) => (
                <option key={index} value={pais.id}>{pais.phonecode} - {pais.iso3}</option>
              ))}
            </select>
            <input
              type="number"
              className="border-start-0 p-2 w-75"
              name="celular"
              onChange={onAddressInputChange}
              value={celular}
            />
          </div>
        </div>
        {errors && <div className='error-msg'>{errors}</div>}
        <div className="text-center py-3">
          <button className='btn-g bb px-3 py-2 text-uppercase'>{loading ? getTextByKey("btn-saving-form-address") : getTextByKey("btn-save-form-address")}</button>
        </div>
      </form>
    </div>
  )
}
// FormularioDireccion.propTypes = {
//   uidUser: PropsTypes.string.isRequired
// }