import React, { Fragment, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import { useAuth } from "../authContext";
import { useCart } from "../context/cartContext";
import { useApi } from '../context/apiContext';


import '../scss/options_header.scss';
import { useEffect } from 'react';
import ModalLogUserComponent from './ModalLogUserComponent';
import { Idiomas } from './IdiomasComponent';

export default function OpctionsHeaderComponent() {
    const { showCart, getNumberProducts, hideCart, clearCart } = useCart();
    const [logged, setLogged] = React.useState(false);
    const { getTextByKey, getIp } = useApi();
    const [isColombia, setIsColombia] = React.useState(false);
    const [activeOptUser, setActiveOptUser] = useState(false)

    useEffect(() => {
        if( localStorage.getItem('actual_ip_shido') ) {
            const { country } = JSON.parse(localStorage.getItem('actual_ip_shido'))
            if(country) country.toLowerCase() === 'colombia' ? setIsColombia( true ) : setIsColombia( false );
        }
    }, [])
    


    //Check the Auth Session
    const { currentUser, logout } = useAuth();
    React.useEffect(() => {
        if (currentUser) {
            setLogged(true);
        } else {
            setLogged(false);
        }
    }, [currentUser]);

    //Logout Event
    const handleLogout = async () => {
        try {
            await logout();
            clearCart();
            location.href = "/";
        } catch {
        }
    };

    const openSearch = event => {
        const filter = document.getElementById('buscador');
        filter.classList.toggle('show');
    };

    const openCar = event => {
        showCart();
    };

    const [numberProducts, setNumberProducts] = React.useState(0);

    useEffect(() => {
        setNumberProducts(getNumberProducts());
    }, [getNumberProducts, numberProducts]);


    const showMenu = event => {
        const menu = document.getElementById('menu-mobile');
        const body = document.getElementsByTagName('body')[0];
        body.classList.toggle('over-hidden');
        menu.classList.toggle('show');
    }

    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    const handleShowModal = (currentUser) => {
        if (currentUser) {
          /* sessionStorage.setItem('something-to-tell', 'feedback-a') */
          /* navigate('/login'); */
          setShowModal(true);
        }else{
          console.log('no user');
        }
    }

    const closeDrop = event => {
        window.scroll(0, 0);
        const drop = document.getElementById('drop-menu');
        drop.classList.remove('show');
        const body = document.getElementsByTagName('body')[0];
        body.classList.toggle('over-hidden');
        window.scroll(0, 0);
        hideCart();
    };

    const optionSelected = () => {
        setActiveOptUser( !activeOptUser)
        window.scroll(0, 0)
    }

    return (
        <Fragment>
            <div className="options d-flex d-lg-block justify-content-around justify-content-md-end justify-content-xl-around align-items-center">
                <ul className='d-flex align-items-center'>
                    <li>
                        <a className="p-2 primary-color" onClick={openSearch} >
                            <Icon icon="bx:bx-search" />
                        </a>
                    </li>

                    {
                        logged && !currentUser.isAnonymous ?
                            <li className="logged">
                                <a className="p-2 primary-color position-relative link-active" onClick={ () => { setActiveOptUser( !activeOptUser) } }>
                                    <Icon icon="majesticons:user" />
                                    {logged && <span className="name-user text-uppercase text-center">{currentUser.displayName && currentUser.displayName.split(' ')[0]}</span>}
                                </a>

                                {logged && <ul className={`opt-user ${activeOptUser ? 'show' : ''}`}>
                                    <li className='title gotham-black mb-3 mb-md-0'>{getTextByKey('cuenta')}</li>
                                    <li><NavLink to="/profile-details" onClick={() => { optionSelected( ) }} className="text-uppercase text-gray">{getTextByKey('perfil')}</NavLink></li>
                                    <li><NavLink to="/profile-orders" onClick={() => { optionSelected( ) }} className="text-uppercase text-gray">{getTextByKey('pedidos')}</NavLink></li>
                                    <li><NavLink to="/profile-favs" onClick={() => { optionSelected( ) }} className="text-uppercase text-gray">{getTextByKey('favoritos')}</NavLink></li>
                                    <li><NavLink to="/profile-address" onClick={() => { optionSelected( ) }} className="text-uppercase text-gray">{getTextByKey('settings')}</NavLink></li>
                                    <li onClick={handleLogout}><NavLink to="#" className="text-uppercase">{getTextByKey('cerrar-sesion')}</NavLink></li>
                                </ul>
                                }
                            </li>
                            :
                            <li>
                                <NavLink to="/login" className="text-uppercase primary-color p-2" onClick={closeDrop}>
                                    <Icon icon="ant-design:user-outlined" />
                                </NavLink>
                                {!logged && <ul className='opt-user'>
                                    <li><NavLink to="/login" className="text-uppercase">{getTextByKey('iniciar-sesion')}</NavLink></li>
                                    <li><NavLink to="/register" className="text-uppercase">{getTextByKey('register')}</NavLink></li>
                                </ul>}
                            </li>
                    }

                    {logged ? <li>
                        <NavLink to="/profile-favs" className="p-2 primary-color" onClick={closeDrop}>
                            <Icon icon="akar-icons:heart" />
                        </NavLink>
                    </li>
                    :
                    <li>
                        <a className="p-2 primary-color" onClick={handleShowModal}>
                            <Icon icon="akar-icons:heart" />
                        </a>
                    </li>
                    }
                    
                    <li>
                        <a className="p-2 primary-color" onClick={openCar}>
                            {numberProducts > 0 && <div className="badge">{numberProducts}</div>}
                            <Icon icon="grommet-icons:shop" />
                        </a>
                    </li>
                    <li className='wp-call-menu'>
                        {/* <a className="p-2 primary-color call-menu" data-bs-toggle="collapse" href="#collapseMenu" role="button" aria-expanded="false" aria-controls="collapseMenu">
                        <Icon icon="dashicons:menu" />
                    </a> */}
                        <a className="p-2 primary-color call-menu" onClick={showMenu}>
                            <Icon icon="dashicons:menu" />
                        </a>
                    </li>
                    {
                        !isColombia && (
                            <li className='d-block d-md-none wp-idiomas-mobile' id="list-idiomas">
                                <a className="p-2 primary-color">
                                    <Idiomas />
                                </a>
                            </li>
                        )
                    }
                    {/* <li>
                    <a className="p-2 primary-color text-uppercase" style={{ textDecoration: "none" }}>
                        {logged && <div className="name-user">{currentUser.email}</div>}
                    </a>
                </li> */}
                </ul>
            </div>
            <ModalLogUserComponent showModal={showModal} setShowModal={setShowModal}/>
        </Fragment>
    )
}
