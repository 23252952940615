import { Fragment, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { ContactComponent } from "../ContactoPage/components/ContactoComponent";
import { useApi } from "./../../context/apiContext";
import { getGallery } from "../../api/galleryApi";
import { Collapse } from './Collapse';

export default function PreguntasPage() {
  const [elementsFAQState, setelementsFAQState] = useState([]);
  const { getTextByKey, getTextBigByKey, getTexts, currentLocale } = useApi();
  const [galleryFaq, setgalleryFaq] = useState([]);

  useEffect(() => {
    getGallery("faq_gallery").then((data) => {
      setgalleryFaq(data);
    });
  }, []);

  return (
    <Fragment>
      {/* <div className='banner pregunstas position-relative'>
        <img className='w-100 fondo' src='img/group-140.jpg' />
        <div className='content-banner'>
          <div className='container'>
            <h2
              className='text-uppercase text-center secundary-color' dangerouslySetInnerHTML={{ __html: getTextBigByKey("faq_banner_title") }}>
            </h2>
          </div>
        </div>
      </div> */}

      <div className='container mb-5'>
        <div className='text-center py-5'>
          <h2 className='text-uppercase primary-color'>{getTextByKey("frequent-questions")} </h2>
        </div>

        {/* Galeria de collapse */}
        {galleryFaq.map((gallery, index) =>
          gallery.names.map((element, key) => {
            if (element.locale === currentLocale) {
              return <Collapse key={key} index={index} ask={element} />;
            }
          })
        )}
      </div>
      <ContactComponent />
    </Fragment>
  );
}
