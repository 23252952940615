import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../authContext";
import ModalLogUserComponent from "../../../components/ModalLogUserComponent";
import { useApi } from "../../../context/apiContext";
import { ModalEstadoOrden } from "../../PerfilPage/components/ModalEstadoOrden";

export function ContactComponent(){
  const [showModal, setShowModal] = useState(false)
  const [showModalEstado, setShowModalEstado] = useState(false)

  const { getTextByKey, getTextBigByKey } = useApi();
  const { currentUser } = useAuth();


    const handleShowModal = () => {
      if( currentUser ) {
        // location.href = '/profile-orders';
        setShowModalEstado(true)
      } else {
        setShowModal(true)
      }
    }

    return(
        <div className="banner banner-contacto position-relative" style={{ backgroundImage: "url(img/group-139.jpg)" }}>
            {/* <img className="w-100" src="img/group-139.jpg"/> */}
            
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2 className="text-center text-uppercase mb-5">{getTextByKey("title-contact-page")}</h2>
                  </div>
                  <div className="col-12 col-lg-4 text-center d-flex justify-content-center align-items-center">
                      
                      <div className='item-ayuda-contacto mb-4 mb-lg-0'>
                        <h3 className="text-uppercase gotham primary-color mb-5">{getTextByKey("order-status-contact-page")}</h3>
                        <div className='content mb-5 d-flex justify-content-center align-items-center'>
                          <div className='wp'>  
                            <p className="text-uppercase mb-0">{getTextByKey("desc-order-status-contact-page")}</p>
                          </div>
                        </div>
                        <a className="btn-g bb py-2 px-4 text-uppercase" onClick={handleShowModal}>{getTextByKey("btn-my-order-status-contact-page")}</a>
                        
                        <ModalEstadoOrden showModal={showModalEstado} setShowModal={setShowModalEstado} />
                        <ModalLogUserComponent showModal={showModal} setShowModal={setShowModal} />
                      </div>

                  </div>
                  
                  <div className="col-12 col-lg-4 text-center d-flex justify-content-center align-items-center">

                    <div className='item-ayuda-contacto mb-4 mb-lg-0'>
                      
                      <h3 className="text-uppercase gotham primary-color mb-5">CHAT</h3>
                      
                      <div className='content mb-5 d-flex justify-content-center align-items-center'>
                        <div className='wp'>  
                          <p className="text-uppercase mb-0"dangerouslySetInnerHTML={{ __html: getTextBigByKey("schedule-chat-contact-page") }}></p>
                        </div>
                      </div>

                      <a className="btn-g bb py-2 px-4 text-uppercase">{getTextByKey("btn-go-chat-contact-page")}</a>

                    </div>

                  </div>

                  <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center">
                    
                    <div className='item-ayuda-contacto mb-4 mb-lg-0'>

                      <h3 className="text-center text-uppercase gotham primary-color mb-5">{getTextByKey("email-contact-page")}</h3>

                      <div className='content mb-5 d-flex justify-content-center align-items-center'>
                        <div className='wp'>  
                          <p className="text-uppercase mb-0">{getTextByKey("send-message-email-contact-page")}</p>
                        </div>
                      </div>

                      <Link  to="/contactanos" className="btn-g bb py-2 px-4 text-uppercase" onClick={() => { window.scroll(0, 0) }}>{getTextByKey("btn-send-message-email-contact-page")}</Link>
                    </div>
                  </div>
                </div>
              </div>
            
        </div> 
    );
}