import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

export default function ModalComponent({email}) {

    const hideModal = () => {
        document.getElementById('ModalConfirmationBtn').click();
    }

  return (
    <Fragment>
        <div className="modal fade" id="ModalConfirmation" tabIndex="-1" aria-labelledby="ModalConfirmationLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content px-5">
                    <div className="modal-header justify-content-center">
                        <h3 className="w-100 modal-title text-center mb-0" id="ModalConfirmationLabel">CONTRASEÑA RESTABLECIDA</h3>
                        <button id="ModalConfirmationBtn" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-5">
                        <form>
                            <div className="form-group mb-3 text-center">
                                <label>RECIBIRÁS UN ENLACE EN POCOS INSTANTES PARA RESTABLECER TU CONTRASEÑA.</label>
                            </div>
                            <div className="form-group mb-3 text-center">
                                <label>{email}</label>
                            </div>
                            <div className="form-group mb-2 text-center">
                                <Link to="/login" className="btn-g py-2 px-4 text-uppercase" onClick={() => { hideModal() }}>VOLVER AL INICIO DE SESIÓN</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}
