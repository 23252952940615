import React, { Fragment } from 'react'
import '../../../scss/step_by_step.scss'
import { useApi } from "../../../context/apiContext";

export default function StepbyStepComponent({ step = 1 }) {
    const { getTextByKey } = useApi();
  return (
    <Fragment>
        <div className='secundary-bg-color step-content'>
            <div className='step-by-step d-flex justify-content-start justify-content-md-center align-items-start align-items-md-center'>
                <div className={ step > 1 ? 'step text-center p-2 active' : 'step text-center p-2' } >
                    <div className='step-num'>
                        <h5 className='mb-2'>1</h5>
                    </div>
                    <p className='text-uppercase mb-0'>{getTextByKey('cart-title')}</p>
                </div>
                <div className={ step >= 2 ? 'step text-center p-2 active' : 'step text-center p-2' }>
                    <div className='step-num'>
                        <h5 className='mb-2'>2</h5>
                    </div>
                    <p className='text-uppercase mb-0'>{getTextByKey('delivery')}</p>
                </div>
                <div className={ step >= 3 ? 'step text-center p-2 active' : 'step text-center p-2' }>
                    <div className='step-num'>
                        <h5 className='mb-2'>3</h5>
                    </div>
                    <p className='text-uppercase mb-0'>{getTextByKey('payment')}</p>
                </div>
                <div className={ step >= 4 ? 'step text-center p-2 active' : 'step text-center p-2' }>
                    <div className='step-num'>
                        <h5 className='mb-2'>4</h5>
                    </div>
                    <p className='text-uppercase mb-0'>{getTextByKey('completed-order')}</p>
                </div>
            </div>
        </div>
    </Fragment>
  )
}
