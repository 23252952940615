import sellyApi from './sellyApi'

export const createSuscribe = async (inputData, setMessages) => {
  try {
    const data = await sellyApi.post(`/suscribe`, inputData)
    if (data?.status === 200) {
      setMessages('subscribe-success')
    }
  } catch (error) {
    setMessages('subscribe-already-registered')
  }
}

export default { createSuscribe }
