import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import { useState } from 'react';
import { useApi } from '../../../context/apiContext';
import { useEffect } from 'react';
import { useForm } from '../../../hooks/useForm';
import { surveyUser } from '../../../helpers/surveyHelper';
import { useAuth } from '../../../authContext';
import SliderBComponent from './SliderBComponent';

const encuestaFormFields = {
    question1: "",
    question2: "",
    question3: "",
}

const SliderComponent = () => {

    const [otherOption, setOtherOption] = useState(false);
    const [answerTalk, setAnswerTalk] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const { getTextByKey, currentLocale } = useApi();
    const { currentUser } = useAuth();
    const [swiper, setSwiper] = useState(null);
    const [sendAnswersFinal, setSendAnswersFinal] = useState(false);
    const {
        onInputChange: onLoginInputChange,
        formState,
    } = useForm(encuestaFormFields);



    const [formularioEncuesta, setFormularioEncuesta] = useState([])
    const [listAnsewrs, setListAnsewrs] = useState([])
    const [loading, setLoading] = useState(false)
    const [sendForm, setSendForm] = useState({
        uid: currentUser.uid,
        surveyData: []
    });


    const [slideChange, setSlideChange] = useState(0)

    useEffect(() => {
        setFormularioEncuesta(JSON.parse(localStorage.getItem('questionsList')));
        setListAnsewrs(JSON.parse(localStorage.getItem('answersList')));
        console.log(listAnsewrs);
    }, [currentLocale]) 


    const handleChange = (optionAnswer) => {
        // debugger;
        if (optionAnswer.questionId === 2) {
            setAnswerTalk(true);
        }
        if (optionAnswer == 'other') {
            setOtherOption(true)
            optionAnswer = {
                questionId: 1,
                id: '00',
                customAnswerText: ''
            }
        } else {
            setOtherOption(false)
        }

        const sendFormAux = {
            ...sendForm
        }
        const surveyDataItem = {
            questionId: optionAnswer.questionId,
            answerId: '',
            customAnswerText: ''
        }

        if (sendFormAux.surveyData.length > 0) {
            let existeItem = false;
            sendFormAux.surveyData.forEach((question) => {
                if (question.questionId === optionAnswer.questionId) {
                    question.answerId = optionAnswer.id;
                    existeItem = true;
                    question.customAnswerText = optionAnswer.id !== '00' ? optionAnswer.name[0].nombre : '';
                }

            })
            if (!existeItem) {
                surveyDataItem.answerId = optionAnswer.id,
                    surveyDataItem.customAnswerText = optionAnswer.name[0].nombre
            }
        } else {
            if (optionAnswer.id === '00') {
                surveyDataItem.answerId = 7
                surveyDataItem.customAnswerText = ''
            } else {
                surveyDataItem.answerId = optionAnswer.id,
                    surveyDataItem.customAnswerText = optionAnswer.name[0].nombre
                setOtherOption(false)
            }
        }

        if (surveyDataItem.answerId !== '') {
            sendFormAux.surveyData.push(surveyDataItem);
            setSendForm(sendFormAux);
        }

        console.log(sendFormAux.surveyData);

    }

    const handleClick = async (e, ultimaPregunta = false) => {
        e.preventDefault();
        window.scroll(0,0)
        if (ultimaPregunta) {
            const response = await surveyUser(JSON.stringify(sendForm));
            localStorage.setItem( 'discountCode', response.discountCode );
            location.href = '/compra-news'
        } else {
            swiper.slideNext();
        }
    }
    const handleChangeAnswerTalk = (e) => {
        setSendAnswersFinal( e.target.value.length > 0 ? true : false );

        const sendFormAux = {
            ...sendForm
        }

        sendFormAux.surveyData.forEach(question => {
            if (question.questionId == 2) {
                question.customAnswerText = e.target.value
            }
        })

        setSendForm(sendFormAux);

    }

    const handleChangeOtherAnswer = (e) => {
        const sendFormAux = {
            ...sendForm
        }

        sendFormAux.surveyData.forEach(question => {
            if (question.questionId == 1) {
                question.customAnswerText = e.target.value
            }
        })

        setSendForm(sendFormAux);

    }

    const handleChangeLastAnswer = (e) => {
        const sendFormAux = {
            ...sendForm
        }
        let existeOption3 = false;
        sendFormAux.surveyData.forEach(question => {
            if (question.questionId == 3) {
                question.customAnswerText = e.target.value
                existeOption3 = true;
            }
        })

        if (!existeOption3) {
            const surveyDataItem = {
                questionId: 3,
                answerId: 13,
                customAnswerText: e.target.value
            }
            sendFormAux.surveyData.push(surveyDataItem)
        }

        setSendForm(sendFormAux);

    }

    return (
        <div className='row'>
            <div className="col-12 col-lg-6">
                <form>
                    <Swiper
                        modules={[]}
                        spaceBetween={30}
                        slidesPerView={1}
                        onSwiper={(swiper) => setSwiper(swiper)}
                        onSlideChange={(e) => { setSlideChange(e.activeIndex) }}>
                        <SwiperSlide>
                            <div className='py-2'>
                                <div className='form-group mb-4'>
                                    <div className=' py-1 px-3 d-inline-block'>
                                        { formularioEncuesta.length > 0  && <h2 className='mb-0'>{formularioEncuesta.find((questionAux) => questionAux.questionId == 1).title}</h2> }
                                    </div>
                                </div>
                                {errorMsg ? <div className='error-msg text-start bg-transparent'>*{errorMsg}</div> : null}

                                {
                                    loading ? <div className='error-msg text-start bg-transparent'>Cargando...</div> : (

                                        <>
                                            {
                                                listAnsewrs.map((option) => (
                                                    option.questionId === 1 && (
                                                        <div className='form-group mb-2' key={option.id}>
                                                            <label className="container-check-s mb-0">
                                                                <p className='mb-0'>{option.name[0].nombre}</p>
                                                                <input type="radio" name="como-llegar" onChange={e => handleChange(option)} />
                                                                <span className="checkmark-s border"><img src="/img/SVG/check.svg" /></span>
                                                            </label>
                                                        </div>
                                                    )
                                                ))
                                            }
                                            <div className='form-group mb-4'>
                                                <label className="container-check-s mb-0">
                                                    <p className='mb-0'>{currentLocale === 'es' ? 'Otro, ¿cuál?' : 'Other, which one?'}</p>
                                                    <input type="radio" name="como-llegar" id="other_option" checked={otherOption} onChange={e => handleChange('other')} />
                                                    <span className="checkmark-s border"><img src="/img/SVG/check.svg" /></span>
                                                </label>
                                            </div>
                                        
                                        </>
                                    )
                                }

                                <div className={otherOption ? 'd-block' : 'd-none'}>
                                    <div className='form-group mb-5' id="answer_other_option">
                                        <input type="text" className='p-2' placeholder='¿Cuál?' onChange={e => handleChangeOtherAnswer(e)} />
                                    </div>
                                </div>

                                {
                                    (sendForm.surveyData.length === 1 && sendForm.surveyData[0].customAnswerText.length > 0) && ( <div className='form-group mb-2 text-center'>
                                        <button type="button" className='btn-g py-2 px-4 border-0 text-uppercase' onClick={e => handleClick(e)}>{getTextByKey('btn-continuar')}</button>
                                    </div>
                                    )
                                }
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='py-2'>
                                <div className='form-group mb-4'>
                                    <div className=' py-1 px-3 d-inline-block'>
                                    { formularioEncuesta.length > 0  && <h2 className='mb-0'>{formularioEncuesta.find((questionAux) => questionAux.questionId == 2).title}</h2> }
                                    </div>
                                </div>
                                {
                                    listAnsewrs.map((option) => (
                                        option.questionId === 2 && (
                                            <div className='form-group mb-2' key={option.id}>
                                                <label className="container-check-s mb-0">
                                                    <p className='mb-0'>{option.name[0].nombre}</p>
                                                    <input type="radio" name="hablar" id="answer_talk" onChange={e => handleChange(option)} />
                                                    <span className="checkmark-s border"><img src="/img/SVG/check.svg" /></span>
                                                </label>
                                            </div>
                                        )
                                    ))
                                }
                                <div className={answerTalk ? 'd-block' : 'd-none'}>
                                    <div className='form-group '>
                                        <textarea placeholder='Aquí puedes ampliar tu respuesta' className='p-2' name="answer_talk_desc" onChange={e => handleChangeAnswerTalk(e)}></textarea>
                                    </div>
                                </div>

                                {
                                    (sendForm.surveyData.length === 2 && sendAnswersFinal) && (
                                        <div className='form-group mb-2 text-center'>
                                            <button className='btn-g py-2 px-4 border-0 text-uppercase' onClick={e => handleClick(e, true)}>{getTextByKey('enviar-respuestas-btn')}</button>
                                        </div>
                                    )
                                }
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </form>
            </div>
            <div className="col-12 col-lg-6">
                <SliderBComponent  slideProgress={slideChange} />
            </div>
        </div>
    )

}

export default SliderComponent;