import React from 'react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom';
import { useApi } from '../../../context/apiContext'


export default function AyudaComponent() {

  const { getTextBigByKey, getTextByKey } = useApi();

  return (
    <Fragment>
        <div className="banner position-relative" style={{ backgroundImage: "url(img/group-139.jpg)" }}>

            <div className="container py-2 py-md-3 py-xl-5">

              <div className="row">
                
                <div className="col-12">
                  <h2 className="text-center text-uppercase mb-5">¿NECESITAS AYUDA ADICIONAL?</h2>
                </div>
                
                <div className="col-12 col-md-4 offset-md-2 text-center alig-items-center">
                    <div className='item-ayuda-contacto mb-4 mb-lg-0'>
                      <h3 className="text-uppercase primary-color mb-5 align-top">CHAT</h3>
                      <div className='content mb-5 d-flex justify-content-center align-items-center'>
                        <div className='wp'>  
                          <p className="text-uppercase mb-0"dangerouslySetInnerHTML={{ __html: getTextBigByKey("schedule-chat-contact-page") }}></p>
                        </div>
                      </div>
                      <a className="btn-g bb py-2 px-4 text-uppercase">{getTextByKey("btn-go-chat-contact-page")}</a>
                    </div>
                </div>

                <div className="col-12 col-md-4 text-center alig-items-center">
                    <div className='item-ayuda-contacto mb-4 mb-lg-0'>
                      <h3 className="text-center text-uppercase primary-color mb-5 align-top">EMAIL</h3>
                      <div className='content mb-5 d-flex justify-content-center align-items-center'>
                        <div className='wp'>
                          <p className="text-uppercase mb-0">Evianos un mensaje</p>
                        </div>
                      </div>
                      <Link  to="/contactanos" className="btn-g bb py-2 px-4 text-uppercase" onClick={() => { window.scroll(0, 0) }}>{getTextByKey("btn-send-message-email-contact-page")}</Link>
                    </div>
                </div>
              </div>
            </div>
        </div> 
    </Fragment>
  )
}
