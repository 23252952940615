import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useApi } from '../context/apiContext';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Icon } from '@iconify/react';
import { images_products_route } from '../api/productApi';


export const ModalImagenes = ({ imagen, imagenesProducto, index }) => {
    imagenesProducto.sort( (a, b) => a.order - b.orden)

    const [indexSlide, setIndexSlide] = useState(0);
    const [swiperModal, setSwiperModal] = useState(null)
    useEffect(() => {
      if( swiperModal ) {
          swiperModal.slideTo(index)
      }
    }, [imagen])
    


    return (
        <div className='content d-flex flex-column justify-content-center align-items-center w-100'>
            <div className="w-100 mx-auto">
                <Swiper
                    modules={[Navigation]}
                    navigation={{
                        nextEl: '.review-swiper-button-next',
                        prevEl: '.review-swiper-button-prev',
                    }}
                    onSwiper={(swiper) => { setSwiperModal(swiper) }}
                    onSlideChange={() => console.log('slide change')}
                >
                    <button className='btn border-0 btn-outline-dark btn-swiper-navigation review-swiper-button-prev' >
                        <Icon icon="material-symbols:arrow-back-ios-rounded" />
                    </button>
                    {imagenesProducto.map((imagen) => (
                        <SwiperSlide key={imagen.orden}>
                            <img src={images_products_route + imagen.image+'?format=webp'} />
                        </SwiperSlide>
                    ))}
                    <button className='btn border-0 btn-outline-dark btn-swiper-navigation review-swiper-button-next'>
                        <Icon icon="material-symbols:arrow-forward-ios-rounded" />
                    </button>
                </Swiper>
            </div>
        </div>
    )
}
