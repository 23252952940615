import React from 'react'
import { useApi } from '../../context/apiContext'

export const ManifiestoPage = () => {

    const { getTextBigByKey, getTextByKey } = useApi();

    return (
        <>
            <div className="container py-4">
                <div className="row pt-4 pt-md-0">
                    {/* <div className="row">
                        <div className="col-12 col-lg-8 ms-auto">
                            <div className="py-5 px-2">
                                <h2 className="primary-color fw-bold text-start text-uppercase">{getTextByKey("titulo-manifiesto-page")}</h2>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-12 col-lg-8 mx-auto manifiesto-content" dangerouslySetInnerHTML={{ __html: getTextBigByKey("contenido-manifiesto-page") }}>
                        {/* <p ></p> */}
                    </div>
                </div>
            </div>

        </>
    )
}
