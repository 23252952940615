import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import ModalComponent from './components/ModalComponent'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from 'react';
import { useApi } from '../../context/apiContext';
import TagManager from 'react-gtm-module';

export default function ResetPassPage() {

    const [error, seterror] = useState('')
    const [email, setemail] = useState('')
    const { getTextByKey } = useApi();


    const resetPass = async e => {
        e.preventDefault();
        const { email } = e.target.elements;
        const auth = getAuth();
        setemail(email.value);

        sendPasswordResetEmail(auth, email.value)
            .then((data) => {
                console.log(data);
                // GTM
                TagManager.dataLayer({
                    dataLayer: {
                    event: "resetPass",
                    userId: email.value,
                    }
                });
                // GTM
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                seterror(errorMessage);
            });
    };


  return (
    <Fragment>
        <div className='content-user bg-white'>
            <div className='container'>
                <div className='row'>
                    <div className='col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>
                        <form className='user my-5 p-5' onSubmit={resetPass}>
                            <div className='form-group mb-5'>
                                <h2 className='text-uppercase text-center mb-0'>RESTABLECER CONTRASEÑA</h2>
                            </div>
                            {error ? <div className='error-msg'>{error}</div> : null}
                            <div className='form-group mb-4'>
                                <label className='text-uppercase mb-2 text-center'>Escribe tu dirección de correo electrónico y te enviaremos instrucciones para restablecer la contraseña.</label>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='text-uppercase mb-2'>{getTextByKey("email-form")}*</label>
                                <input type="email" name="email" className='p-1 p-md-2'/>
                            </div>
                            <div className='form-group text-center mb-4'>
                                <button type="submit" className='btn-g bb py-3 px-4 text-uppercase' data-bs-toggle="modal" data-bs-target="#ModalConfirmation">Restablecer</button>
                            </div>
                            <div className='form-group text-center mb-5'>
                                <Link to="/login" className='link-g text-uppercase'>O VUELVE A Iniciar sesión</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <ModalComponent email={email}/>
    </Fragment>
    
  )
}
