import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';

import { images_products_route } from "../api/productApi";
import { useApi } from "../context/apiContext";
import { createSuscribe } from "../api/suscribeApi";
import { useCart } from "../context/cartContext";
import ModalLogUserComponent from "./ModalLogUserComponent";
import { updateCurrentUser } from "firebase/auth";
import { useAuth } from "../authContext";
import { useForm } from "../hooks/useForm";

const suscribeFormFields = {
  email: "",
  lan: "es"
}

export function Footer() {
  const { getTextByKey, extractNameFromLocate, getTextBigByKey, currentLocale } = useApi();
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const [messages, setMessages] = useState()
  const {
    onInputChange: onSuscribeInputChange,
    formState,
    emailRegex
  } = useForm(suscribeFormFields);

  const { email, conditionTerms } = formState;

  const { hideCart } = useCart();

  const gotoTop = () => {
    window.scroll(0, 0);
    hideCart();
    console.log('HOLA!')

  };

  const [showModal, setShowModal] = useState(false);

  const { currentUser, logout } = useAuth();

  const handleShowModal = (currentUser) => {
    window.scroll(0, 0)
    if (currentUser) {
      sessionStorage.removeItem('to-checkout');
      sessionStorage.setItem('something-to-tell', 'feedback-a')
      navigate('/login');
    } else {
      console.log('no user');
    }
  }

  const onEmailSuscribeSubmit = async (event) => {
    event.preventDefault();

    const validate = await validateForm(formState)
    if (!validate) {
      setMessages()
      formState.lan = currentLocale;
      const newSuscribe = await createSuscribe(formState, setMessages)
    }
  }

  const handleCheckTermsCondition = () => {
    setIsChecked(!isChecked ? true : false);
  }

  const validateForm = async (formData) => {
    let errorFound = false;
    //Validate the email format
    if (!email.match(emailRegex)) {
      errorFound = true;
      setMessages("email-incorrect-format");
    }

    if (!isChecked) {
      errorFound = true;
      setMessages("term-condition-required");
    }
    return errorFound;
  }


  return (
    <footer className='primary-bg-color pt-5 py-3 py-md-4'>
      <div className='container pt-5'>
        <div className='row'>

          <div className='col-8 offset-2 col-md-6 offset-md-0 col-xl-2 text-start text-xl-start'>
            <h4 className='gotham-Bold white-color mb-2 mb-md-4'>{getTextByKey("customer-service")}</h4>
            <ul className='mb-5 mb-xl-4'>
              <li>
                <Link to='/tarjeta-a' onClick={gotoTop} className='white-color mb-1'>
                  {getTextByKey("card-gift")}
                </Link>
              </li>
              <li>
                <Link to='/find-my-size' onClick={gotoTop} className='white-color mb-1'>
                  {getTextByKey("find-my-size-footer")}
                </Link>
              </li>
              <li>
                <Link to='/envios' onClick={gotoTop} className='white-color mb-1'>
                  {getTextByKey("shipments")}
                </Link>
              </li>
              <li>
                <Link to='/shido-society' onClick={gotoTop} className='white-color mb-1'>
                  {getTextByKey("shido-women")}
                </Link>
              </li>
              <li className={currentUser ? 'd-block' : 'd-none'}>
                <Link to='/feedback-a' onClick={gotoTop} className='white-color mb-1'>
                  {getTextByKey("something-to-tell")}
                </Link>
              </li>
              <li className={currentUser ? 'd-none' : 'd-block'}>
                <button onClick={handleShowModal} className='white-color mb-1'>
                  {getTextByKey("something-to-tell")}
                </button>
              </li>
              <li>
                <Link to='/preguntas-frecuentes' onClick={gotoTop} className='white-color mb-1'>
                  {getTextByKey("frequent-questions")}
                </Link>
              </li>
              <li>
                <Link to='/contacto' onClick={gotoTop} className='white-color mb-1'>
                  {getTextByKey("contact")}
                </Link>
              </li>
            </ul>
          </div>
          <ModalLogUserComponent showModal={showModal} setShowModal={setShowModal} />
          <div className='col-8 offset-2 col-md-6 offset-md-0 col-xl-5 offset-xl-0 text-start text-xl-start'>
            <h4 className='gotham-Bold white-color mb-2 mb-md-4'>{getTextByKey("about-title")}</h4>
            <ul className='mb-5 mb-xl-0'>
              <li>
                <Link to='/about-shido' onClick={gotoTop} className='white-color mb-1'>
                  {getTextByKey("about-shido")}
                </Link>
              </li>
              <li>
                <Link to='/politicas-de-compras' onClick={gotoTop} className='white-color mb-1'>
                  {getTextByKey("purchase-policy")}
                </Link>
              </li>
              <li>
                <Link to='/aviso-de-privacidad' onClick={gotoTop} className='white-color mb-1'>
                  {getTextByKey("privacy-notice")}
                </Link>
              </li>
              <li>
                <Link to='/politica-de-datos' onClick={gotoTop} className='white-color mb-1'>
                  {getTextByKey("data-policy")}
                </Link>
              </li>
              <li>
                <Link to='/politica-de-cambios-devoluciones' onClick={gotoTop} className='white-color mb-1'>
                  {getTextByKey("exchange-policy")}
                </Link>
              </li>
              <li>
                <Link to='/nft' onClick={gotoTop} className='white-color mb-1'>
                  NFTs shido
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className='col-xl-2 d-none d-md-flex p-lg-0 justify-content-center align-items-center'>
            <p className='px-0 mb-xl-0 py-5 py-md-4 p-xl-0'>
              <img className='w-100' src='/img/logo_blanco.png' />
            </p>
          </div> */}

          <div className='col-10 d-none d-md-block offset-1 col-md-8 offset-md-2 col-xl-4 offset-xl-1 ps-xl-0'>
            <h4 className='gotham-Bold white-color mb-2 mb-md-4'>{getTextByKey("title-newsletter")}</h4>
            <p className='white-color' dangerouslySetInnerHTML={{ __html: getTextBigByKey("desc-newsletter") }}></p>
            <form className='mb-5 mb-xl-0' onSubmit={onEmailSuscribeSubmit}>
              <label className='container-check-s'>
                <small>{getTextByKey("text-terminos")}</small>
                <input
                  name="conditionTerms"
                  type='checkbox'
                  value={isChecked}
                  onChange={handleCheckTermsCondition}
                  checked={isChecked ? true : false}
                />
                <span className='checkmark-s'>
                  <img src='/img/SVG/check.svg' />
                </span>
              </label>

              <div className='d-flex justify-content-between align-items-strech mb-2'>
                <input
                  name="email"
                  type='email'
                  value={email}
                  className='p-2'
                  placeholder='mail@mail.com'
                  disabled={!isChecked ? true : false}
                  onChange={onSuscribeInputChange}
                />
                <button
                  type='submit'
                  className='btn-g px-2 px-md-4'
                  disabled={!isChecked ? true : false}
                >
                  {getTextByKey("text-btn-newsletter")}
                </button>
              </div>
              <div>
                {messages && <small className='note'>*{getTextByKey(messages)}</small>}
              </div>
            </form>
          </div>

        </div>
        <div className='row'>
          <div className='col-xl-5 text-center text-xl-start'>
            <h4 className='gotham-Bold white-color mb-2 mb-md-2'>{getTextByKey("get-inspired")}</h4>
            <div className='social mb-5 mb-xl-0'>
              <a className='mx-3 ms-xl-0 me-xl-2' href={"https://instagram.com/" + getTextByKey("instagram-user")} target='_blank'>
                <Icon icon='akar-icons:instagram-fill' />
              </a>
              <a className='mx-3 me-xl-2' href={getTextByKey("spotify-user")} target='_blank'>
                <Icon icon='akar-icons:spotify-fill' />
              </a>
              {/* <a className='mx-3 me-xl-2' href={"https://youtube.com/channel/" + getTextByKey("youtube-user")} target='_blank'>
                  <Icon icon='akar-icons:youtube-fill' />
                </a> */}
            </div>
          </div>
          <div className='col-xl-2 d-flex justify-content-center align-items-center'>
            <a className='iridian' target="_blank" href="https://iridian.co/">
              <img className='w-100' src='/img/2x/iridian_1@2x.png'/>
            </a>
          </div>
          <div className='col-xl-4 offset-xl-1 ps-xl-0 d-flex justify-content-center justify-content-xl-start align-items-center'>
            <div className='franquicias pt-2 pt-xl-0'>
              <p className='p-2 pb-0 ps-xl-0'>
                <img src='/img/SVG/american.svg' />
              </p>
              <p className='p-2 pb-0 ps-xl-0'>
                <img src='/img/SVG/visa.svg' />
              </p>
              <p className='p-2 pb-0 ps-xl-0'>
                <img src='/img/SVG/master.svg' />
              </p>
              <p className='p-2 pb-0 ps-xl-0'>
                <img src='/img/SVG/pse.svg' />
              </p>
              <p className='p-2 pb-0 ps-xl-0'>
                <img src='/img/ssl-b.png' />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
