import React, { useEffect, Fragment } from 'react'
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from 'axios';
import { useCart } from '../../../context/cartContext';
import { useApi } from '../../../context/apiContext';

export default function FormPagoComponent({ handleResponse }) {

    const { total, envio, coupon, currentCart } = useCart();
    const { convertCurrency, convertCurrencyNoFormat } = useApi();

    const convertCopToUsd = (amount) => {
        return convertCurrency(amount);
    }

    var data = {
        amount: {
            currency_code: "USD",
            value: convertCurrencyNoFormat(total + envio - (coupon ? coupon.descuento : 0)).toFixed(2),
            breakdown: {
                item_total: {
                    currency_code: "USD",
                    value: convertCurrencyNoFormat(total - (coupon ? coupon.descuento : 0)).toFixed(2),
                },
                shipping: {
                    currency_code: "USD",
                    value: convertCurrencyNoFormat(envio).toFixed(2),
                }
            }
        },
        items: currentCart.map((item) => {
            return {
                name: item.names[0].nombre,
                description: "",
                sku: item.plu,
                unit_amount: {
                    currency_code: "USD",
                    value: convertCurrencyNoFormat(item.price.precio).toFixed(2),
                },
                quantity: item.quantity,
            };
        }),
    };
    
    return (
        <>
            {/* <PayPalScriptProvider options={{ "client-id": "AZUHoGV2hMZGYWJHs6PRZg---q0FRexAbYg5tGfZfzXljmmQEJnV2rVtUNpUxSB2_1xwJrV4RbC6YTUB" }} > */}
            <PayPalScriptProvider options={{ "client-id": "Ack4CK-RrgBlmYgYTrPkqVupvcSLBUuYRNX6rLExKlkklzm2Wci9ZGO_6bvVLGNTJF1_Ll_MC0Jhmk0W" }}>
                <PayPalButtons
                    style={{ layout: "horizontal" }}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: "USD",
                                        value: convertCurrencyNoFormat(total + envio - (coupon ? coupon.descuento : 0)).toFixed(2),
                                        breakdown: {
                                            item_total: {
                                                currency_code: "USD",
                                                value: convertCurrencyNoFormat(total - (coupon ? coupon.descuento : 0)).toFixed(2),
                                            },
                                            shipping: {
                                                currency_code: "USD",
                                                value: convertCurrencyNoFormat(envio).toFixed(2),
                                            }
                                        }
                                    },
                                    items: currentCart.map((item) => {
                                        return {
                                            name: item.names[0].nombre,
                                            description: "",
                                            sku: item.plu,
                                            unit_amount: {
                                                currency_code: "USD",
                                                value: convertCurrencyNoFormat(item.price.precio).toFixed(2),
                                            },
                                            quantity: item.quantity,
                                        };
                                    }),
                                },
                            ],
                        });
                    }}
                    onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                            console.log(details);
                            handleResponse(details);
                        });
                    }}
                    onCancel={(data, actions) => {
                        return actions.order.capture().then((details) => {
                            console.log(details);
                            handleResponse(details);
                        });
                    }}
                    onShippingChange={(data, actions) => {

                    }}
                    onError={(error) => {
                        console.log(error);
                        handleResponse(error);
                    }}
                />
            </PayPalScriptProvider>
        </>
    )
}
