import sellyApi from "./sellyApi"


export const listOrdersUser = async (uidUser, currentLocale) => {
    try {
        const { data } = await sellyApi.get(`user/pedidos/historial?usuario=${uidUser}&lang=${currentLocale}`);
        console.log(data);
        return data;
    } catch (error) {
        console.log('Error obteniendo historial de pedidos')
    }
}