import React, { Fragment } from 'react'
import '../../scss/newsletter.scss';

export default function NewsletterPage() {
  return (
    <Fragment>
      <div className='newsletter position-reltive'>
        <img className='back' src='/img/back-news.png'/>
        <div className='content-form'>
        
          <div className='col'>
            <div className='img'>
              <img className='w-100' src="/img/news.jpg"/>
            </div>
          </div>
          <div className='col'>
            <div className='form-news'>
              <form>
                <div className='form-group'>
                  <h2 className='text-center mb-lg-4'>OBTÉN BENEFICIOS Y CONOCE NUESTRAS OFERTAS</h2>
                  <p>SÉ LA PRIMERA EN CONOCER NUESTRO CONTENIDO
                  EXCLUSIVO.</p>
                </div>
                <div className='form-group'>
                  <label className='text-uppercase'>Nombre</label>
                  <input type="text"/>
                </div>
                <div className='form-group'>
                  <label className='text-uppercase'>Apellidos</label>
                  <input type="text"/>
                </div>
                <div className='form-group'>
                  <label className='text-uppercase'>Correo Electrónico</label>
                  <input type="email"/>
                </div>
                <div className='form-group'>
                  <label className='text-uppercase'>Teléfono de contacto</label>
                  <input type="text"/>
                </div>
                <div className='form-group'>
                  <label className='text-uppercase'>Fecha de Nacimiento</label>
                  <input type="date"/>
                </div>
                <div className='form-group py-4 text-center'>
                  <a>Al registrarte, aceptas la Política de Datos y los Términos de uso de sHIDO.</a>
                </div>
                <div className='form-group text-center'>
                    <a className='btn-g bb py-2 px-4'>REGISTRARSE</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
