import React, { Fragment } from 'react'
import { Icon } from '@iconify/react';
import '../scss/buscador.scss'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { getProductsSearch, getRecommendedHome, images_products_route } from '../api/productApi';
import { getGallery } from '../api/galleryApi';
import { useApi } from '../context/apiContext';
import { BtnClose } from './BtnClose';

export default function BuscadorComponent() {

    const [loading, setLoading] = useState(false);
    const [popular, setPopular] = useState(true);
    const [products, setProducts] = useState([]);

    const { currentLocale } = useApi();

    
    const closeSearch = event => {
        const filter = document.getElementById('buscador');
        filter.classList.remove('show');  
    };

    useEffect (() => {
        setLoading(true);
        getRecommendedHome().then((data) => {
            setProducts(data);
            setLoading(false);
        });
    }, []);

    const searchProducts = async (e) => {
        setLoading(true);
        const search = e.target.value;

        if( search.length > 2 ) {
            const resultsSearch = await getProductsSearch( search );
            if( resultsSearch ) {
                setProducts(resultsSearch);
            } else {
                setProducts( [] );
            }
            setPopular(false);
            setLoading(false);
        } else {
            const resultHome = await getRecommendedHome();
            setProducts(resultHome);
            setPopular(true);
            setLoading(false);
        }
    }

    const [logo, setLogo] =  useState([]);

    useEffect(() => {
        getGallery("logo-img").then((data) => {
            setLogo(data);
        });
    }, []);

    return (
        <Fragment>
            <div className='buscador' id="buscador">
                <div className='cols d-none d-md-block'>
                    {logo.length > 0 && <img className="logo" src={images_products_route+logo[0].image+'?format=webp'} alt={"Logo"} />}
                </div>
                <div className='cols'>
                    <form>
                        <button><Icon icon="bx:bx-search" /></button>
                        <input type="search" placeholder='SEARCH' name='query' id="query" onKeyUp={searchProducts}></input>
                    </form>
                    {loading ? (
                        <div className='container-load-search'>
                            <div className="text-center-c">
                                <div className="loader-box">
                                    <img src="/img/SVG/loader.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {popular ? (
                                <div className='p-4' >
                                    <h3 className='fw-bold text-uppercase'>POPULARES</h3>
                                    <ul>
                                        {products.map((product, index) => (
                                            <li key={index} onClick={closeSearch}>
                                                <Link to={`/product/${product.plu}`}>
                                                    {product.name.nombre}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <div className='p-4'>
                                    <h3 className='fw-bold text-uppercase'>RESULTADOS</h3>
                                    <ul>
                                        { products.length > 0 ? products.map((product, index) => (
                                            <li key={index} onClick={closeSearch}>
                                                <Link to={`/product/${product.plu}`}>
                                                    {product.name.nombre}
                                                </Link>
                                            </li>))
                                            : <li>
                                                <a>{currentLocale === 'es' ? 'Sin resultados' : 'Not results'}</a>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            )}
                        </>

                    )}
                    <a className='close' onClick={closeSearch}><BtnClose /></a>
                </div>
            </div>
        </Fragment>
    )
}
