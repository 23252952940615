import { Fragment } from "react";
import { ContactComponent } from "./components/ContactoComponent";


export default function ContactoPage() {
  return (
    <Fragment>
        <ContactComponent/>
    </Fragment>
  )
}
