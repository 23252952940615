import { Fragment, useEffect, useState } from "react";
import { ModaleliminarcuentaComponent } from "./components/ModaleliminarcuentaComponent";
import { NavComponent } from "./components/NavComponent";
import { NavsupComponent } from "./components/NavsupComponent";
import '../../scss/perfilPage.scss'
import { NavLink } from "react-router-dom";

import { useApi } from "../../context/apiContext";
import { getUser, updateUser, deleteUser } from '../../helpers/userHelper';
import { useAuth } from "../../authContext";

export default function DetallescuentaPage() {

    const { currentUser } = useAuth();
    const { getTextByKey } = useApi();
    const [profileInfo, setProfileInfo] = useState({});

    const fetchProfile = () => {
        try {
            getUser(currentUser?.uid).then(({ user, newsletterStatus }) => {
                console.log(user)
                const { nombre, apellido, celular, email, password } = user;
                setProfileInfo({
                    nombre: nombre,
                    apellido: apellido,
                    celular: celular,
                    email: email,
                    password: password,
                    newsletterStatus: newsletterStatus,
                    language: 'es',
                    uid: currentUser?.uid
                })
            })

        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        fetchProfile()
    }, [])

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setProfileInfo({
            ...profileInfo,
            [name]: value
        });
    }

    const onSubmit = (event) => {
        event.preventDefault();
        updateUser(profileInfo).then((response) => {
            console.log('after update', response)
        })
    }

    return (
        <Fragment>
            <div className="quaternary-bg-color head-perfil">
                <div className="container" style={{padding: "10pt 0"}}>
                    <div className="text-center">
                        <h1 className="text-uppercase active">{getTextByKey("account-title")}</h1>
                        <NavsupComponent />
                    </div>
                </div>
            </div>
            <div className="container-fluid px-4 px-lg-0 py-5 position-relative">
                <NavLink to="/feedback-a" className="algo-para-contar">
                    <img src="img/algo-para-contar.png" />
                </NavLink>
                <div className="d-lg-flex">
                    <div className="col-nav mb-4 mb-lg-0">
                        <NavComponent />
                    </div>
                    <div className="col-content-perfil col-lg-4">
                        <h2 className="text-uppercase">{getTextByKey("account-edit")}</h2>
                        <form onSubmit={onSubmit}>
                            <div className="form-group mb-3">
                                <label>{getTextByKey("names-form")}*</label>
                                <input
                                    type="text"
                                    className="p-2"
                                    name="nombre"
                                    value={profileInfo.nombre}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>{getTextByKey("last-names-form")}*</label>
                                <input
                                    type="text"
                                    className="p-2"
                                    name="apellido"
                                    value={profileInfo.apellido}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>{getTextByKey("email-form")}*</label>
                                <input
                                    type="email"
                                    className="p-2"
                                    name="email"
                                    value={profileInfo.email}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>{getTextByKey("password-form")}*</label>
                                <input
                                    type="password"
                                    className="p-2"
                                    name="password"
                                    value={profileInfo.password}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>{getTextByKey("phone-form")}*</label>
                                <input
                                    type="tel"
                                    className="p-2"
                                    name="celular"
                                    value={profileInfo.celular}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label className="mb-1 d-flex align-items-center newsletterStatus">{getTextByKey("newsletter-form")}
                                    <input
                                        className="p-2 ms-2"
                                        type="checkbox"
                                        name="newsletterStatus"
                                        checked={profileInfo.newsletterStatus}
                                        onChange={onInputChange}
                                    />
                                </label>
                            </div>
                            <div className="form-group mb-5">
                                <a className="text-uppercase btn-delete-account" data-bs-toggle="modal" data-bs-target="#eliminarModal">{getTextByKey("delete-account")}</a>
                            </div>
                            <div className="form-group mb-2 text-center">
                                <button className="btn-g bb py-2 px-4 text-uppercase">{getTextByKey("save-button")}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ModaleliminarcuentaComponent profileInfo={profileInfo} deleteUser={deleteUser} />
        </Fragment>
    )
}