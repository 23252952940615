import React, { Fragment, useState, useEffect } from "react";
import { BolsaComponent } from "../../components/Bolsa";
import SelectDireccionComponent from "./components/SelectDireccionComponent";
import StepbyStepComponent from "./components/StepbyStepComponent";
import MetodoPagoComponent from "../PerfilPage/components/MetodoPagoComponent";
import { useApi } from "../../context/apiContext";
import "../../scss/checkout.scss";
import FormPagoComponent from "./components/FormPagoComponent";
import PaypalComponent from "./components/PaypalComponent";
import {
  createOrder,
  getBanks,
  getTarjetas,
  pagarOrderPSE,
  pagarOrderWompi,
  paypalApproved,
} from "../../api/productApi";
import { useAuth } from "../../authContext";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../context/cartContext";
import Modalmetodo from "../PerfilPage/components/Modalmetodo";
import Select from "react-select";
import TagManager from "react-gtm-module";

const CheckoutcPage = () => {
  //TODO revisar flujos de pagos

  const [tarjetas, setTarjetas] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectedCard, setSelectedCard] = useState(0);
  const [order, setOrder] = useState({});
  const { getTextByKey, currentCurrency, currentLocale, getPropiertyByLocale } =
    useApi();
  const { currentUser } = useAuth();
  const {
    total,
    envio,
    coupon,
    direccion,
    currentCart,
    clearCart,
    creditBenefitType,
    calculateTotal
  } = useCart();
  const [method, setMethod] = useState("select");
  const [loading, setLoading] = useState(false);
  const [showModalMetodo, setShowModalMetodo] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [cuotas, setCuotas] = useState(1);
  const [optionsCuotas, setOptionsCuotas] = useState(Array(36));

  const handleTarjeta = (card) => {
    setSelectedCard(card);
  };

  const changeTarjeta = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedCard(0);
  };

  useEffect(() => {
    setTarjetas([]);
    getTarjetas(currentUser.uid).then((response) => {
      setTarjetas(response);
    });
    getBanks().then((response) => {
      setBanks(response);
    });
    // goToCenter();

    const cuotasAux = [];
    while (cuotasAux.length < 36) {
      const cuotaN = { value: cuotasAux.length+1, label: cuotasAux.length+1} 
      cuotasAux.push(cuotaN);
    }
    setOptionsCuotas(cuotasAux);
  }, []);

  const createCheckoutOrder = async () => {
    setLoading(true);
    // window.scroll(0, 0);
    order.uid = currentUser.uid;
    order.delivery_cost = envio;
    order.subtotal = total - (coupon ? coupon.descuento : 0);
    order.total = total + envio - (coupon ? coupon.descuento : 0);
    order.address = parseInt(direccion);
    order.billing = parseInt(direccion);
    order.card = selectedCard;
    order.method = method;
    order.buyItems = [];
    order.comments = "";
    order.currency = currentCurrency;
    order.creditBenefitType = creditBenefitType;
    order.discountMount = coupon.descuento || 0;
    console.log(currentCart);
    currentCart.forEach((item) => {
      if (item.type === "giftcard") {
        order.buyItems.push({
          productId: item.cartPlu,
          type: item.type,
          price: item.price,
          quantity: item.quantity,
          comments: "",
          discount: 0,
          toppings: "",
          promotionId: "",
        });
      } else {
        order.buyItems.push({
          productId: item.cartPlu ? item.cartPlu : item.plu,
          type: item.type,
          price: item.price.precio,
          quantity: item.quantity,
          comments: "",
          discount: 0,
          toppings: "",
          promotionId: "",
          products: item.products ? item.products : [],
        });
      }
    });

    createOrder(order)
      .then((response) => {
        if (!response.id) {
          setLoading(false);
          setError("Error al crear la orden");
        } else {
          let jsonWompi = {
            tarjeta: selectedCard,
            user: currentUser.uid,
            compra: response.id,
            cuota: cuotas,
          };
          pagarOrderWompi(jsonWompi).then(({ estado, msg }) => {
            if (estado === "APPROVED" || estado === "PENDING") {
              checkoutTagManagerListener(
                response.id,
                "Wompi",
                total + envio - (coupon ? coupon.descuento : 0),
                envio,
                currentCurrency,
                coupon
              );
              clearCart();
              setLoading(false);
              navigate("/payment-success", {
                replace: true,
                state: { order: response.id, msg },
              });
              window.scroll(0, 0);
            } else {
              setError(`Error al pagar la orden : ${estado} ${msg}`);
              console.log("Error payment ", estado, msg);
              navigate("/payment-success", {
                replace: true,
                state: { order: 0, msg },
              });
              setLoading(false);
            }
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  const createCheckoutOrderPaypal = async (responsep) => {
    setLoading(true);
    order.method = method;
    order.uid = currentUser.uid;
    order.delivery_cost = envio;
    order.subtotal = total - (coupon ? coupon.descuento : 0);
    order.total = total + envio - (coupon ? coupon.descuento : 0);
    order.address = parseInt(direccion);
    order.billing = parseInt(direccion);
    order.card = 0;
    order.comments = "Order Paypal No. " + responsep.id;
    order.buyItems = [];
    order.currency = currentCurrency;
    order.creditBenefitType = creditBenefitType;
    order.discountMount = coupon.discount || 0;
    currentCart.forEach((item) => {
      if (item.type === "giftcard") {
        order.buyItems.push({
          productId: item.cartPlu,
          type: item.type,
          price: item.price,
          quantity: item.quantity,
          comments: "",
          discount: 0,
          toppings: "",
          promotionId: "",
        });
      } else {
        order.buyItems.push({
          productId: item.cartPlu ? item.cartPlu : item.plu,
          type: item.type,
          price: item.price.precio,
          quantity: item.quantity,
          comments: "",
          discount: 0,
          toppings: "",
          promotionId: "",
          products: item.products ? item.products : [],
        });
      }
    });

    createOrder(order)
      .then((response) => {
        console.log("order1",response);

        setLoading(false);

        if (!response.id) {
          setError("Error al crear la orden");
        } else {
          console.log("PAYPAL");
          paypalApproved({
            "compra":response.id,
            "idPaypal":responsep.id,
            }).then((response) => {
            console.log("PAYPAL",response);
          });
          checkoutTagManagerListener(
            response.id,
            "Paypal",
            total + envio - (coupon ? coupon.descuento : 0),
            envio,
            currentCurrency,
            coupon
          );
          clearCart();
          navigate("/payment-success", {
            replace: true,
            state: { order: response.id, msg: "PAGO EXITOSO" },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  const createCheckoutOrderPSE = async (e) => {
    setError("");

    e.preventDefault();
    e.stopPropagation();
    
    calculateTotal();

    const { banco, persona, nombres, tipo_documento, documento, email } =
      e.target.elements;
      console.log(e.target.elements);

    setLoading(true);
    order.uid = currentUser.uid;
    order.delivery_cost = envio;
    order.subtotal = total - (coupon ? coupon.descuento : 0);
    order.total = total + envio - (coupon ? coupon.descuento : 0);
    order.address = parseInt(direccion);
    order.billing = parseInt(direccion);
    order.method = method;
    order.comments =
      "Order PSE User: " +
      documento.value +
      " - " +
      nombres.value +
      " - " +
      email.value;
    order.buyItems = [];
    order.currency = currentCurrency;
    order.creditBenefitType = creditBenefitType;
    order.discountMount = coupon.discount || 0;
    currentCart.forEach((item) => {
      if (item.type === "giftcard") {
        order.buyItems.push({
          productId: item.cartPlu,
          type: item.type,
          price: item.price,
          quantity: item.quantity,
          comments: "",
          discount: 0,
          toppings: "",
          promotionId: "",
        });
      } else {
        order.buyItems.push({
          productId: item.cartPlu ? item.cartPlu : item.plu,
          type: item.type,
          price: item.price.precio,
          quantity: item.quantity,
          comments: "",
          discount: 0,
          toppings: "",
          promotionId: "",
          products: item.products ? item.products : [],
        });
      }
    });

    createOrder(order)
      .then((response) => {
        if (!response.id) {
          setError("Error al crear la orden");
          setLoading(false);
        } else {
          var jsonPSE = {
            user: currentUser.uid,
            compra: response.id,
            banco: banco.value,
            tipopersona: persona.value == "Persona natural" ? 1 : 0,
            titular: nombres.value,
            tipodocumento: tipo_documento.value == "Cédula de ciudadanía" ? "CC" : "NIT",
            documento: documento.value,
            email: email.value,
            user_legal_id_type: tipo_documento.value == "Cédula de ciudadanía" ? "CC" : "NIT",
            user_type: persona.value == "Persona natural" ? 1 : 0,
            respuestaurl: 'https://'+window.location.host+"/payment-success"
          };
          pagarOrderPSE(jsonPSE).then((responsePSE) => {
            setLoading(false);
            if (responsePSE.link) {
              checkoutTagManagerListener(
                response.id,
                "PSE",
                total + envio - (coupon ? coupon.descuento : 0),
                envio,
                currentCurrency,
                coupon
              );
              location.href = responsePSE.link;
              clearCart();
            } else {
              setError("Error al pagar la orden por PSE, intente nuevamente");
              navigate("/payment-success", {
                replace: true,
                state: { order: 0, msg },
              });
            }
          });
          
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  const handleResponse = (response) => {
    if (response.status == "COMPLETED") {
      createCheckoutOrderPaypal(response);
    } else {
      setError("Error al pagar la orden por Paypal, intenta nuevamente");
    }
  };

  const updateTarjetas = async () => {
    try {
      const listTarjetas = await getTarjetas(currentUser.uid);
      setTarjetas(listTarjetas);
    } catch (error) {
      setError("Error al actualizar tarjeta de crédito.");
    }
  };

  const selectCuotas = (e) => {
    if (e.id) setCuotas(e.id);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "black" : "white",
    }),
    control: (provided) => ({
      ...provided,
    }),
  };

  const checkoutTagManagerListener = (
    id,
    type,
    total,
    envio,
    currency,
    coupon
  ) => {
    if (currentCart) {
      // GTM
      TagManager.dataLayer({
        ecommerce: null,
      });
      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
          ecommerce: {
            transaction_id: id,
            method: type,
            value: total,
            shipping: envio,
            currency: currency,
            coupon: coupon,
            items: currentCart.map((x, i) => {
              return {
                item_name: getPropiertyByLocale(x.names, "nombre"),
                item_id: x.plu,
                price: x.price,
                color: x.color,
                talla: x.talla,
              };
            }),
          },
        },
      });
      // GTM
    }
  };

  const [listaTipoPersona, setListaTipoPersona] = useState([])
  const [listaDocuemento, setListaDocumento] = useState([])
  useEffect(() => {
    setListaTipoPersona([
      {
        id: 1,
        name: getTextByKey("tipo-persona-opt-1")
      },
      {
        id: 2,
        name: getTextByKey("tipo-persona-opt-2")
      }
    ])
    setListaDocumento([
      {
        id: 'CC',
        name: currentLocale === "es" ? "Cédula de ciudadanía" : "identity card"
      },
      {
        id: 'NIT',
        name: 'NIT'
      },
    ])
  }, [currentLocale])

  const goToCenter = () => {
    const filter = document.querySelector('#address-send')
    const { x: posX, y: posY } = filter.getBoundingClientRect()
    console.log(posX, posY)
    window.scroll(0, (posY / 2 * 1.4))
  }


  return (
    <Fragment>
      <StepbyStepComponent step={3} />
      <div className="container py-5 checkout">
        <div className="row justify-content-md-center">
          <div className="col-md-8 col-lg-5">
            <div className="container d-block d-md-none px-0">
              <BolsaComponent />
            </div>
            <h2 className="text-uppercase mb-4" id="address-position">
              {getTextByKey("direccion-envio-titulo")}
            </h2>
            <form className="mb-4" id="address-send">
              <SelectDireccionComponent step={3} />
            </form>

            <h2 className="text-uppercase my-4 pt-4">
              {getTextByKey("metodo-pago")}
            </h2>
            {method == "select" && (
              <div className="error-msg text-grey text-start bg-transparent">
                *{getTextByKey("ayuda-metodos-pago")}
              </div>
            )}
            <div className="container">
              {method == "select" && (
                <div className="buttons-methods">
                  <div
                    className="btn-method"
                    onClick={() => setMethod("paypal")}
                  >
                    <Icon icon="bi:paypal" /> PAYPAL
                  </div>
                  <div
                    className="btn-method"
                    onClick={() => setMethod("tarjeta")}
                  >
                    <Icon icon="ant-design:credit-card-filled" />{" "}
                    {currentLocale === "es"
                      ? "TARJETA DÉBITO/CRÉDITO"
                      : "CARD DEBIT/CREDIT"}
                  </div>
                  <div className="btn-method" onClick={() => setMethod("pse")}>
                    <Icon icon="ant-design:bank-filled" /> PSE
                  </div>
                </div>
              )}
              {method == "paypal" && (
                <div className="content-paypal">
                  <PaypalComponent handleResponse={handleResponse} />
                </div>
              )}
              {method == "tarjeta" && (
                <>
                  {tarjetas.length > 0 ? (
                    tarjetas.map((tarjeta, index) => (
                      <MetodoPagoComponent
                        tarjeta={tarjeta}
                        key={index}
                        selected={selectedCard}
                        handleTarjeta={handleTarjeta}
                        updateTarjetas={updateTarjetas}
                      />
                    ))
                  ) : (
                    <h2 className="text-uppercase">
                      {getTextByKey("no-payment-methods")}
                    </h2>
                  )}
                  {selectedCard > 0 && (
                    <>
                      <div className="w-100 d-block d-md-none mb-2">
                        <h3 className="text-uppercase mb-2 pt-4">
                          {getTextByKey("cuotas-pago")}
                        </h3>
                        <div className="py-6">
                          {/* <select name="cuotas" id="cuotas" className="form-control" onChange={(e) => setCuotas(e.target.value)}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((cuota, index) => (
                          <option value={cuota} key={index}>{cuota}</option>
                        ))}
                      </select> */}
                          <Select
                            styles={customStyles}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={optionsCuotas}
                            onChange={selectCuotas}
                            placeholder={
                              currentLocale === "es" ? "Seleccionar" : "Select"
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {
                    selectedCard == 0 && (
                      <button
                        className="btn-g bb px-3 py-2 text-uppercase my-3"
                        onClick={() => setShowModalMetodo(true)}
                      >
                        {getTextByKey("add-card-button")}
                      </button>
                    )
                  }
                  {selectedCard > 0 && (
                    <>
                      <button
                        className="btn-g bb px-3 py-2 text-uppercase mb-1 mt-3 my-md-3"
                        onClick={changeTarjeta}
                      >
                        {getTextByKey("change-card")}
                      </button>
                    </>
                  )}
                </>
              )}
              {method == "pse" && (
                <>
                  <form onSubmit={createCheckoutOrderPSE}>
                    <div className="form-group mb-3">
                      {getTextByKey("debit-bank")} PSE
                    </div>
                    <div className="form-group mb-2">
                      <label>
                        {currentLocale === "es" ? "Banco" : "Bank"}*
                      </label>
                      <Select
                        name='banco'
                        id="banco"
                        styles={customStyles}
                        className='react-select-container py-2 mb-3'
                        classNamePrefix="react-select"
                        options={banks}
                        getOptionLabel={(option) => option.financial_institution_name}
                        getOptionValue={(value) => value.financial_institution_code}
                        placeholder={currentLocale === 'es' ? 'Selecciona' : 'Select'}
                      />
                      {/* <select name="banco" id="banco" className="p-2">
                        <option value="0" selected disabled>
                          {currentLocale === "es" ? "Selecciona" : "Select"}
                        </option>
                        {banks.map((banco, index) => (
                          <option
                            value={banco.financial_institution_code}
                            key={index}
                          >
                            {banco.financial_institution_name}
                          </option>
                        ))}
                      </select> */}
                    </div>
                    <div className="form-group mb-2">
                      <label>{getTextByKey("tipo-persona")}*</label>
                      <Select
                        name='persona'
                        id="persona"
                        styles={customStyles}
                        className='react-select-container py-2 mb-3'
                        classNamePrefix="react-select"
                        options={listaTipoPersona}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(value) => value.name}
                        placeholder={currentLocale === 'es' ? 'Selecciona' : 'Select'}
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label>
                        {getTextByKey("names-form-address")}{" "}
                        {getTextByKey("surnames-form-address")}*
                      </label>
                      <input
                        type="text"
                        className="p-2"
                        name="nombres"
                        id="nombres"
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label>{getTextByKey("tipo-documento")}*</label>
                      <Select
                        name='tipo_documento'
                        id="tipo_documento"
                        styles={customStyles}
                        className='react-select-container py-2 mb-3'
                        classNamePrefix="react-select"
                        options={listaDocuemento}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(value) => value.name}
                        placeholder={currentLocale === 'es' ? 'Selecciona' : 'Select'}
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label>{getTextByKey("numero-documento")}*</label>
                      <input
                        type="text"
                        className="p-2"
                        name="documento"
                        id="documento"
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label>{getTextByKey("email-form")}*</label>
                      <input
                        type="email"
                        className="p-2"
                        name="email"
                        id="email"
                      />
                    </div>
                    {error && <div className='error-msg text-grey text-start bg-transparent mt-2'>*{getTextByKey('error-metodo-pago')}</div>}
                    <div className="form-group mb-2 text-center">
                      <button
                        className="btn-g py-2 px-4 text-uppercase"
                        type="submit"
                      >
                        {!loading ? (
                          "Proceder a pagar"
                        ) : (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          ></div>
                        )}
                      </button>
                    </div>
                  </form>
                </>
              )}
              {method != "select" && (
                <>
                  <span> &#32; </span>
                  <button
                    className="btn-g bb px-3 py-2 text-uppercase my-3 mb-5 mb-md-3"
                    onClick={() => { setMethod("select"); setError('');  }}
                  >
                    {getTextByKey("cambiar-metodo-pago")}
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="col-md-4 col-lg-5 offset-lg-1">
            <div className="container">
              <div className="w-100 d-none d-md-block px-0">
                <BolsaComponent />
              </div>
              {selectedCard > 0 && (
                <>
                  <div className="w-100 d-none d-md-block">
                    <h3 className="text-uppercase my-4 pt-4">
                      {getTextByKey("cuotas-pago")}
                    </h3>
                    <div className="py-6">
                      {/* <select name="cuotas" id="cuotas" className="form-control" onChange={(e) => setCuotas(e.target.value)}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((cuota, index) => (
                          <option value={cuota} key={index}>{cuota}</option>
                        ))}
                      </select> */}
                      <Select
                        styles={customStyles}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={optionsCuotas}
                        onChange={selectCuotas}
                        placeholder={
                          currentLocale === "es" ? "Seleccionar" : "Select"
                        }
                      />
                    </div>
                  </div>
                  {["tarjeta"].includes(method) && (
                      <div className="py-2 text-center">
                        {error && <div className='error-msg text-grey text-center bg-transparent mt-4'>*{error}</div>}
                        <button
                          className="btn-g bb px-3 py-2 text-uppercase mb-3"
                          onClick={createCheckoutOrder}
                        >
                          {!loading ? (
                            getTextByKey("btn-buy-now")
                          ) : (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                            ></div>
                          )}
                        </button>

                      </div>
                    )}
                </>
              )}
              <br /> <br />
              <div className="py-5 text-center">
                <h3>{getTextByKey("metodo-pago")}</h3>
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                  <a className="m-md-2 m-1 icono-metodo">
                    <img src="/img/SVG/american.svg" />
                  </a>
                  <a className="m-md-2 m-1 icono-metodo">
                    <img className="visa" src="img/visa.png" alt="logo" />
                  </a>
                  <a className="m-md-2 m-1 icono-metodo">
                    <img src="img/mastercard.png" alt="logo" />
                  </a>
                  {/* <a href="" className='m-md-2 m-1 icono-metodo'><img src="img/nequi.png" alt="logo" /></a> */}
                  <a className="m-md-2 m-1 icono-metodo">
                    <img src="img/pse.png" alt="logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modalmetodo
        showModal={showModalMetodo}
        setShowModal={setShowModalMetodo}
        updateTarjetas={updateTarjetas}
      />

      {/* <div className="collapse secundary-bg-color" id="formDireccion">
        <div className='container checkout py-2 py-xl-5'>
          <div className='row'>
            <div className='col-lg-4 offset-lg-1'>
            <Modalmetodo />
            </div>
          </div>
        </div>
      </div> */}
    </Fragment>
  );
};

export default CheckoutcPage;
