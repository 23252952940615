import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { setDefaultAddress, deleteAddress } from "../../../api/addressApi";
import { useApi } from "../../../context/apiContext";

import PropsTypes from "prop-types";

export default function DireccionComponent({ currentUser, direccion, updateDirs }) {
    const { getTextByKey } = useApi();
    const [loading, setLoading] = useState(false);
    const [loadingPrederminada, setLoadingPredeterminada] = useState(false);

    const handleDeleteAddress = (event) => {
        event.preventDefault();
        setLoading(true);
        deleteAddress(direccion.id).then(() => {
            setLoading(false);
            updateDirs();
        });
    };

    const handleSetDefaultAddress = (event) => {
        event.preventDefault();
        setLoadingPredeterminada(true);
        setDefaultAddress(currentUser.uid, direccion.id).then(() => {
            setLoadingPredeterminada(false);
            updateDirs();
        });
    };

    return (
        <div className="ficha-direccion p-2 p-lg-5 secundary-bg-color mb-4">
            <form className="d-flex align-items-center">
                <div className="datos">
                    <div className="form-group mb-2 mb-md-3">
                        <h4 className="gotham-Bold text-uppercase">{currentUser.displayName}
                            {direccion.predeterminada ? <p className="mb-0 text-uppercase">(predeterminada)</p>
                                : null}
                        </h4>
                    </div>
                    <div className="form-group mb-2 mb-md-3">
                        <p className="mb-0 text-uppercase">ciudad: {direccion.city}</p>
                        <p className="mb-0 text-uppercase">
                            dirección: {direccion.address}
                        </p>
                        <p className="mb-0 text-uppercase">{direccion.detalle}</p>
                    </div>
                    <div className="form-group">
                        {direccion.predeterminada ? null :
                            <>
                                {loadingPrederminada ?
                                    <div className='spinner-border spinner-border-sm' role='status'></div>
                                    :
                                    <button className="btn-g bb py-1 px-2 py-md-2 px-md-3 text-uppercase" onClick={handleSetDefaultAddress}>
                                        {getTextByKey("btn-send-direccion")}
                                    </button>
                                }
                            </>
                        }
                    </div>
                </div>
                <div className="opciones">
                    {loading ?
                        <div className='spinner-border spinner-border-sm' role='status'></div>
                        :
                        <button className="border-0 bg-transparent icon-gray" onClick={handleDeleteAddress}> <Icon icon="akar-icons:trash-can" /> </button>
                    }
                </div>
            </form>
        </div>
    );
}

DireccionComponent.propTypes = {
    direccion: PropsTypes.object.isRequired,
    nameUser: PropsTypes.string.isRequired,
};
