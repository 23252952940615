import { sellyApi } from "../api";

export const manageCurrentCart = async (cartData) => {
  try {
    const { data } = await sellyApi.post(`/cart/user/manage`, cartData);

    return data;
  } catch (error) {
    return error;
  }
};

export const getCurrentCart = async (uid) => {
  try {
    const {data} = await sellyApi.get(`/cart/user?uid=${uid}`);
    return data;
  } catch (error) {
    return error;
  }
};

export default {
  manageCurrentCart,
  getCurrentCart,
};
