import React from 'react'
import { Icon } from '@iconify/react'
import '../scss/carrito.scss'
import { useCart } from "../context/cartContext";
import { images_products_route } from "../api/productApi";
import { useApi } from '../context/apiContext';


export default function ItemCarrito({ item, index, min }) {

    const { removeToCart } = useCart();
    const { getTextByKey, getPropiertyByLocale, currentSizes, currentColors, convertCurrency } = useApi();

    const removeItem = () => {
        removeToCart(index);
    }

    const getNameTalla = (talla) => {
        talla = parseInt(talla);
        let size = currentSizes.find((size) => size.id == talla);
        if (size) {
            return size.nombre;
        }
        return talla;
    }

    const getNameColor = (color) => {
        color = parseInt(color);
        let colorItem = currentColors.find((colorItem) => colorItem.id == color);
        if (colorItem) {
            return colorItem.nombre;
        }
        return color;
    }

    const renderCardGiftItem = () => {
        return (
            <div className='item-carrito d-flex justify-content-between alig-items-center p-2 mb-2 animate__animated animate__fadeIn animate__delay-1s'>
                <div className='img'>
                    <img
                        className='w-100'
                        src={images_products_route + item.image}
                    />
                </div>
                <div className='desc ps-3 d-flex justify-content-between'>
                    <div className='op'>
                        <p className='text-uppercase mb-0 fw-bold'>{getTextByKey('giftcard-label')}</p>
                        <p className='text-uppercase mb-2'>{convertCurrency(item.price)}</p>
                        <p className='text-uppercase mb-0'>{getTextByKey('quantity-label')}: {item.quantity}</p>
                    </div>
                    <div className='op d-flex justify-content-center align-items-center'>
                        <a className='borrar' onClick={removeItem}><Icon icon="akar-icons:trash-can" /></a>
                    </div>
                </div>
            </div>
        )
    }

    const renderProductItem = () => {
        return (
            <div className={min ? 'item-carrito-min item-carrito d-flex justify-content-between alig-items-center p-2 mb-2 animate__animated animate__fadeIn animate__delay-1s' : 'item-carrito d-flex justify-content-between alig-items-center p-2 mb-2 animate__animated animate__fadeIn animate__delay-1s'}>
            <div className='img transition'>
                {item.images.length > 0 && <img className='w-100' src={images_products_route + item.images[0].image} />}
            </div>
            <div className='desc ps-3 d-flex justify-content-between'>
                <div className='op'>
                    {item.type === "combination" && <p className='text-uppercase mb-0 fw-bold'>Combinación</p>}
                    <h2 className='text-uppercase mb-0 fw-bold'>{getPropiertyByLocale(item.names, "nombre")}</h2>
                    <p className='text-uppercase mb-2'>{convertCurrency(item.price.precio)}</p>
                    {!min ? (
                        <>
                            {item.type !== "combination" ? (
                                <div className='text-gray'>
                                    <p className='text-uppercase mb-0'>CANTIDAD: {item.quantity}</p>
                                    <p className='text-uppercase mb-0'>COLOR: {getNameColor(item.color)}</p>
                                    <p className='text-uppercase mb-2'>TALLA: {getNameTalla(item.size)}</p>
                                </div>
                            ):(
                                <>
                                    <p className='text-uppercase mb-0 text-gray'>Productos:</p>
                                    {item.products.map((producto, index) => (
                                        <p className='text-uppercase mb-0 text-gray' key={index}>{producto.quantity} - {getPropiertyByLocale(producto.names, "nombre")} - {getNameColor(producto.color)} - {getNameTalla(producto.size)}</p>
                                    ))}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {item.type !== "combination" &&  <p className='text-uppercase mb-0'>CANTIDAD: {item.quantity}</p>}
                        </>
                    )}
                </div>
                <div className='op d-flex justify-content-center align-items-center'>
                    <a className='borrar' onClick={removeItem}><Icon icon="akar-icons:trash-can" /></a>
                </div>
            </div>
        </div>
        )
    }

    return (
        <>
            {
                item.type === "giftcard" ? renderCardGiftItem() : renderProductItem()
            }
        </>
    )
}
