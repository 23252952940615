import React from 'react';
import { NavLink, Router } from 'react-router-dom';
import { useApi } from "../../context/apiContext";
import { getAuth, signInAnonymously } from "firebase/auth";
import { createAnonymousUser } from '../../helpers/userHelper';

export const CheckoutaPage = () => {

  const { getTextByKey } = useApi();

  const saveRoute = () => {
    sessionStorage.removeItem('something-to-tell');
    sessionStorage.setItem('to-checkout', 'checkout-b')
  }

  const onGuestContinueClick = () => {
    const auth = getAuth();

    signInAnonymously(auth)
      .then(async ({ user }) => {

        const { uid } = user;
        const registerFormFields = {
          email: `${uid}@anonymous.shido.com`,
          email_confirm: `${uid}@anonymous.shido.com`,
          password: `${uid}`,
          nombre: `${uid}`,
          apellido: `${uid}`,
          celular: "3111111111",
          uid: `${uid}`,
          pais: 48, //Colombia id
          phonecode: 48,
          fechaNacimiento: "1992/03/02",
          //documento: "",
          providerData: "unset",
          sexo: "M",
          autoriza: true,
          marketing: false,
          anonimo: true,
          tratamiento: "Sr.",
          newsletter: "unset",
          politicas: "unset"
        }

        const newUser = await createAnonymousUser(registerFormFields)
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // ...
      });
  }

  return (
    <div className="container content-checkout-invitado">
      <div className="row">
        <div className="col-md-6 px-0 px-md-3">
          <div className="p-5 secundary-bg-color text-center box-checkout-invitado">
            <div className="contenido-box">
              <h2 className='text-uppercase'>
                <span className='mb-3'>{getTextByKey('iniciar-sesion')}</span><br />
                <span className='mb-3'>{getTextByKey('checkout-rapido')}</span>
              </h2>
              <form action="">
                <div className="form-group mb-2">
                  <label className="my-1 text-uppercase d-flex justify-content-center align-items-center gap-2">{getTextByKey('remember')}<input className="py-2 px-2" type="checkbox" /></label>
                </div>
              </form>
            </div>
            <div className="text-center py-4">
              <NavLink to="/login" className="btn-g primary-bg-color py-2 px-3 text-uppercase" onClick={saveRoute}>{getTextByKey('iniciar-sesion')}</NavLink>
            </div>
          </div>
        </div>
        <div className="col-md-6 px-0 px-md-3">
          <div className="p-5 secundary-bg-color text-center box-checkout-invitado">
            <div className="contenido-box">
              <h2 className='text-uppercase m-0'>
                {getTextByKey('ingresar-invitado')}
              </h2>
              <p className='text-uppercase p-0 mb-2'>{getTextByKey('ingreso-registro-despues')}</p>
            </div>
            <div className="text-center py-4">
              <NavLink to="/checkout-b" className="btn-g primary-bg-color py-2 px-3 text-uppercase" onClick={onGuestContinueClick}>{getTextByKey('continuar-invitado')}</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
