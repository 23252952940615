import React from 'react'
import { useEffect } from 'react';
import { Fragment } from 'react'
import { useApi } from "../../../context/apiContext";

export default function ColorComponent({ producto, handleColor }) {

    const { currentColors } = useApi();
    const [colorEnables, setColorEnables] = React.useState([]);

    const handleChange = (e) => {
        handleColor(e.target.value);
    }

    useEffect(() => {
        let colorEnablesArray = [];
        if (currentColors) {
            currentColors.forEach((color) => {
                color.productos.forEach((item) => {
                    if (item.includes(producto.plu)) {
                        colorEnablesArray.push(color.id);
                    }
                });
            });
        }
        setColorEnables(colorEnablesArray);
    }, [currentColors, producto.plu]);

    return (
        <Fragment>
            {currentColors.length > 0 && (
                <>
                    {currentColors.map((color, index) => (
                        <b key={index}>
                            {colorEnables.includes(color.id) && (
                                <div className={color.hexadecimal == '#000000' ? 'color-radio me-2 border-w' : 'color-radio me-2'} key={index}>
                                    <input type="radio" name="color-${producto.plu}" value={color.id} onChange={handleChange} />
                                    <span className={color.hexadecimal == '#ffffff' ? 'check border' : 'check'} style={{ backgroundColor: color.hexadecimal }}></span>
                                </div>
                            )}
                        </b>
                    ))}
                </>
            )}
        </Fragment>
    )
}
