import React, { Component, Fragment } from 'react';
import '../../../scss/nav_perfil.scss'

import { Icon } from '@iconify/react';
import { NavLink } from 'react-router-dom';

import { auth } from '../../../firebaseConfig';
import { useCart } from '../../../context/cartContext';

export const NavComponent = () => {

    const {clearCart} = useCart();
    //Logout Event
    const handleLogout = async () => {
      try {
        await auth.signOut();
        clearCart();
        location.href = "/";
      } catch {
      }
    };

    const scrollTop = () => {
      window.scroll(0, 0);
    }

  return (
    <Fragment>
      <ul className='nav-perfil'>
        <li><NavLink onClick={() => scrollTop()} className="py-2 py-lg-2" to="/profile-details"><Icon icon="ant-design:user-outlined" className='me-3 icon' /><span>DETALLES DE LA CUENTA</span></NavLink></li>
        <li><NavLink onClick={() => scrollTop()} className="py-2 py-lg-2" to="/profile-methods"><Icon icon="bi:credit-card" className='me-3 icon' /><span>MÉTODOS DE PAGO</span></NavLink></li>
        <li><NavLink onClick={() => scrollTop()} className="py-2 py-lg-2" to="/profile-address"><Icon icon="bi:send" className='me-3 icon' /><span>DIRECCIÓN DE ENVÍO</span></NavLink></li>
        <li><NavLink className="py-2 py-lg-2" to="/" onClick={handleLogout}><Icon icon="bx:bx-log-out" className='me-3 icon' /><span>CERRAR SESIÓN</span></NavLink></li>
      </ul>
    </Fragment>
  )
}



// export class NavComponent extends Component {

//   render() {
    
    

//     return (
      
//     )
//   }
// }